div.BupBooking {
  &-list {
    ul li {
      align-items: center;
      border: 1px solid transparent;
      border-radius: var(--bup-rounded);
      color: var(--bup-text-secondary);
      cursor: pointer;
      display: grid;
      font-size: 14px;
      grid-gap: 12px;
      grid-template-columns: auto 1fr auto;
      min-height: 48px;
      line-height: 1.33;
      margin-left: -8px;
      padding: 6px 12px;
      width: calc(100% + 16px);

      i.BupIcon-root {
        color: var(--bup-icon-color);
        font-size: 18px;
        transition: all 0 linear;
        opacity: 0.6;
        visibility: hidden;
      }
      &:hover {
        background-color: var(--bup-common-hover);
        border-color: var(--bup-common-border);

        i.BupIcon-root {
          visibility: visible;
        }
      }
      &.Bup-active {
        background-color: var(--bup-tab-background);
        border-color: var(--bup-tab-border);

        i.BupIcon-root {
          color: inherit;
          font-weight: bold;
          visibility: visible;
        }
        div.BupBooking-item {
          border-color: var(--bup-tab-border);

          span {
            &:first-child {
              background-color: var(--bup-link-color);
              color: white;
            }
          }
        }
        div.MuiChip-root {
          background-color: var(--bup-tab-color);
          color: white;
          height: 20px;
          font-size: 11px;
          font-weight: 500;
        }
      }
      &.Bup-readOnly {
        pointer-events: none !important;
      }
      @media only screen and (max-width: $xs) {
        margin-left: 0;
        padding: 6px 12px;
        width: 100%;
      }
    }
  }
  &-item {
    border: 1px solid var(--bup-input-border);
    border-radius: 8px;
    height: 40px;
    user-select: none;
    width: 40px;

    span {
      display: block;
      text-align: center;

      &:first-child {
        background-color: var(--bup-input-border);
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        color: black;
        font-size: 9px;
        font-weight: 600;
        height: 16px;
        padding: 2px;
        text-transform: uppercase;
      }
      &:last-child {
        color: var(--bup-text-primary);
        font-size: 14px;
        font-weight: 400;
        height: 24px;
        padding: 3px;
      }
    }
  }
}
