div.BupDocument-content {
  @include responsive;
  margin-top: 20px;

  & {
    h1.MuiTypography-h1 {
      @include clamp(1);
      font-size: 28px;
      font-weight: 400;
      width: 100%;

      span.MuiSkeleton-root {
        min-width: 300px;
      }
      @media only screen and (max-width: $sm) {
        @include clamp(2);
      }
    }
    p.MuiTypography-body1 {
      font-size: 15px;
    }
    button.BupButton-icon {
      aspect-ratio: 1 !important;
      width: 36px;
    }
    span.MuiTypography-body2 {
      @include row-center;
      justify-content: flex-start;
      font-size: 13.5px;
      font-weight: 400;
      gap: 8px;
      text-transform: uppercase;

      @media only screen and (max-width: $xs) {
        justify-content: center;
      }
    }
  }
  div.MuiChip-root {
    height: 18px;

    span.MuiChip-label {
      font-size: 12px;
    }
  }
  div.BupUser-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 4px;

    div.BupUser-name {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      gap: 12px;

      h1.MuiTypography-h1 {
        width: fit-content;
      }
      i.BupIcon-verified {
        color: var(--bup-link-color);
        font-size: 20px;
      }
      div.BupUser-status {
        align-items: center;
        display: flex;
        gap: 16px;
        height: fit-content;
        user-select: none;

        svg.BupLogo-root {
          height: 18px;
          margin-top: 2px;
          width: 18px;
        }
        &:empty {
          display: none;
        }
      }
      @media only screen and (max-width: $xs) {
        justify-content: center;
      }
    }
    div.BupUser-plan {
      align-items: center;
      display: flex;
      gap: 4px;

      span {
        font-size: 14px;
      }
      i.BupIcon-root {
        font-size: 20px;
      }
    }
    @media only screen and (max-width: $sm) {
      align-items: flex-start;
      gap: 16px;
      flex-direction: column;
    }
    @media only screen and (max-width: $xs) {
      align-items: center;
      padding-left: 0;

      div.BupAvatar-group {
        margin: 8px auto 0;
      }
    }
  }
  div.BupEvent-overview {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    max-width: $md;

    div {
      i.BupIcon-root {
        @include row-center;
        border-radius: 8px;
        border: 1px solid var(--bup-input-border);
        color: var(--bup-icon-color);
        font-size: 22px;
        height: 40px;
        width: 40px;
      }
      &.BupDocument-startDate {
        border: 1px solid var(--bup-input-border);
        border-radius: 8px;
        height: 40px;
        user-select: none;
        width: 40px;

        span {
          display: block;
          text-align: center;

          &:first-child {
            background-color: var(--bup-input-border);
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            color: black;
            font-size: 9px;
            font-weight: 600;
            height: 16px;
            padding: 2px;
            text-transform: uppercase;
          }
          &:last-child {
            color: var(--bup-text-primary);
            font-size: 15px;
            font-weight: 400;
            height: 24px;
            padding: 3px;
          }
        }
      }
      &.BupDocument-info {
        span {
          @include clamp(1);
          max-width: 100%;

          &:first-child {
            font-size: 15px;
            font-weight: 500;
            min-width: 250px;
            opacity: 0.8;
          }
          &:last-child {
            font-size: 13px;
            color: var(--bup-text-secondary);
            min-width: 200px;
          }
        }
      }
    }
    @media only screen and (max-width: $sm) {
      grid-template-columns: 1fr;
    }
    @media only screen and (max-width: $xs) {
      display: none;
    }
  }
  &:last-child {
    margin-bottom: 8px;
  }
  &:has(div.BupEvent-overview) {
    p.MuiTypography-body1,
    span.MuiTypography-body2 {
      display: none;
    }
  }
  @media only screen and (max-width: $xs) {
    border-radius: 0 !important;
    border: none;
    text-align: center;

    &:has(div.BupPremium-badge) {
      position: relative;

      div.BupPremium-badge {
        position: absolute;
        right: 12px;
        margin-top: -104px;
      }
      h1.MuiTypography-h1 {
        padding-left: 12px;
      }
    }
  }
}
