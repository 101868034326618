svg.BupLogo-root {
  max-height: 128px;
  max-width: 128px;
  user-select: none;

  path.BupLogo {
    &-primary {
      fill: darken($tertiary, 2%);
    }
    &-secondary {
      fill: lighten($tertiary, 8%);
    }
  }
  &.BupLogo {
    &-premium {
      path.BupLogo {
        &-primary {
          fill: darken($golden, 2%);
        }
        &-secondary {
          fill: lighten($golden, 8%);
        }
      }
    }
    &-small {
      height: 16px;
      width: 16px;
    }
    &-medium {
      height: 32px;
      width: 32px;
    }
    &-large {
      height: 44px;
      width: 44px;
    }
    &-circle {
      background: $golden;
      border-radius: 16px;
      padding: 2px;

      * {
        fill: white !important;
      }
    }
    &-square {
      background: $golden;
      border-radius: 2px;
      padding: 2px;

      * {
        fill: white !important;
      }
    }
  }
}
div.BupLogo-oval {
  @include row-center;
  background-color: var(--bup-icon-background);
  border-radius: 16px;
  border: 1px solid var(--bup-icon-border);
  height: 32px;
  opacity: 0.8;
  transition: all 0.2s ease;
  width: 54px;

  svg.BupLogo-root {
    height: 18px;

    * {
      fill: var(--bup-icon-color);
    }
  }
}
span.BupLogo-span {
  @include font(21px, 300);
  color: $tertiary;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5;
  user-select: none;
}
.Bup-sync {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
