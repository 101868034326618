div.MuiCardActions-root {
  padding: 0;

  span.BupCard-eventRating {
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    margin-left: auto;
    width: 100%;
  }
  div.BupCard-eventBooking {
    align-items: center;
    display: flex;
    gap: 12px;
    padding-top: 8px;

    @media only screen and (max-width: $xs) {
      display: none;
    }
  }
  div.BupCard-friendProfile {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  div.BupCard-friendRequest {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  button {
    &.BupCard-actionPayment {
      border-radius: var(--bup-rounded);
      width: 160px;

      &:not(:hover) {
        background-color: transparent;
      }
    }
    &.BupCard-actionFavorite {
      background-color: var(--bup-icon-background);
      border: 1px solid var(--bup-icon-border);
      color: var(--bup-icon-color);

      &:hover {
        background-color: var(--bup-icon-background-hover);
        border: 1px solid var(--bup-icon-border-hover);
        color: var(--bup-icon-color-hover);
      }
      &.Bup-selected {
        color: var(--bup-danger-color);
      }
    }
    &.BupCard-actionRedirect {
      padding: 3px 15px;
    }
    &.MuiSkeleton-root {
      border: none;
      height: 32px;
      width: 80px;
    }
  }
}
