.BupSlider {
  &-root {
    .BupSlider {
      &-item {
        border-radius: var(--bup-border-radius);
        border: 1px solid var(--bup-navbar-border);

        & {
          h2.MuiTypography-h2 {
            color: white;
            font-size: 24px;
            font-weight: 400;
            line-height: 1.25;
            text-shadow: 1px 1px 1px rgba(black, 0.4);
            width: 235px;
          }
          span.MuiTypography-span {
            color: white;
            display: block;
            font-size: 12.5px;
            font-weight: 300;
            margin-bottom: 4px;
            text-shadow: 1px 1px 2px rgba(black, 0.4);
          }
          button.BupButton-root {
            background: var(--bup-navbar-item-background);
            border: 1px solid var(--bup-navbar-item-border);
            border-radius: 14px;
            color: var(--bup-navbar-color);
            font-size: 12.5px;
            margin-top: auto;
            height: 28px;
            opacity: 0.85;
            width: fit-content;
            transition: all 0.2s ease;

            &:hover {
              background: var(--bup-navbar-item-background-hover);
              opacity: 1;
            }
          }
          button.BupButton-icon {
            background: rgba(black, 0.56);
            border-radius: 50%;
            color: white;
            cursor: pointer;
            height: 32px;
            min-width: 32px;
            width: 32px;
            position: absolute;
            pointer-events: auto;
            top: 16px;
            right: 16px;
            z-index: 10;

            &:hover {
              background: rgba(black, 0.72);
            }
            @media only screen and (max-width: $sm) {
              right: 12px;
              top: 12px;
            }
            @media only screen and (max-width: $x1) {
              display: flex;
              right: 8px;
            }
          }
        }
      }
      &-image {
        align-items: flex-end;
        background-color: var(--bup-skeleton);
        border-radius: inherit;
        border: none;
        display: flex;
        height: 332px;
        justify-content: flex-end;
        padding: 0;
        outline: none;
      }
      &-filter {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.05) 100%);
        border-radius: inherit;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 16px;
        width: 100%;

        @media only screen and (max-width: $xs) {
          padding: 16px 12px 12px;
        }
      }
    }
  }
  &-accordion {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 8px;

    div.BupSlider-item {
      cursor: pointer;
      overflow: hidden;

      &.Bup-active {
        aspect-ratio: auto;
        grid-column: span 3;
        pointer-events: auto;
      }
      &:not(.Bup-active) {
        div.BupSlider-filter {
          display: none;
        }
      }
      @media only screen and (max-width: $md) {
        &:nth-child(n + 4) {
          display: none;
        }
      }
      @media only screen and (max-width: $sm) {
        &:nth-child(n + 2) {
          display: none;
        }
      }
    }
    @media only screen and (max-width: $md) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media only screen and (max-width: $sm) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: $xs) {
      .BupSlider-image {
        @include landscape;
      }
    }
  }
  &-carousel {
    @include slide-scroll;
    scroll-behavior: smooth;
    transition: transform 0.3s;

    div.BupSlider {
      &-item {
        &.Bup-selected {
          width: 100%;

          div.BupSlider-filter {
            aspect-ratio: auto;
            position: relative;
            justify-content: center;
            height: inherit;
            width: 100%;
            z-index: 1;

            h2 {
              font-size: 32px;
              width: 332px;
            }
            span {
              display: none;
            }
            @media only screen and (max-width: $sm) {
              background-color: rgba(black, 0.16);
              justify-content: flex-start;
              height: 323px;

              h2 {
                font-size: 24px;
                width: 235px;
              }
              span {
                display: block;
              }
            }
          }
        }
      }
      &-image {
        @include video;
        background-color: var(--bup-navbar-background);
        height: 332px !important;

        @media only screen and (max-width: $sm) {
          aspect-ratio: auto;
          background-position: right;
          height: 445px !important;
          min-width: 332px;
        }
        @media only screen and (max-width: $x1) {
          @include square;
          height: 100% !important;
          min-width: 0;

          h2.MuiTypography-h2 {
            font-size: 22px;
            width: auto;
          }
          button.BupButton-root {
            display: none;
          }
          div.BupSlider-filter {
            padding: 12px;
          }
        }
      }
    }
    @media only screen and (max-width: $xs) {
      gap: 8px !important;
    }
    @media only screen and (max-width: $x1) {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      overflow: hidden;

      &:has(.Bup-selected) {
        grid-template-columns: 1fr;
      }
    }
  }
}
