div.BupCard-discover {
  display: flex;
  flex-direction: column;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  gap: 12px;
  padding-bottom: 4px;
  height: 100%;
  width: 100%;

  div.MuiCardMedia-root {
    @include video;
    @include col-center;
    background-color: var(--bup-paper-background);
    border-radius: var(--bup-border-radius);
    border: 1px solid var(--bup-common-border);
    gap: 8px;
    padding: 12px;
    transition: all 0.2s ease;
    width: 100%;

    i.BupIcon-root {
      font-size: 28px;
      color: var(--bup-icon-color);
      opacity: 0.8;
    }
  }
  div.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    gap: 6px;

    h6.MuiTypography-h6 {
      color: var(--bup-text-primary);
      font-size: 18px;
      line-height: 1.33;
    }
    span.MuiTypography-p {
      font-size: 14px;
    }
    a.BupLink-href {
      font-size: 14px;
      color: $tertiary;
    }
    button {
      border-radius: var(--bup-rounded);
      opacity: 0.8;
    }
  }
  &:hover {
    div.MuiCardMedia-root {
      background-color: var(--bup-input-background);
      border-color: var(--bup-input-border);
    }
    div.MuiCardContent-root {
      a.BupLink-href {
        text-decoration: underline;
      }
    }
  }
}
