ul.BupOptions-list {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 0;

  li {
    align-items: center;
    border: 1px solid var(--bup-input-border);
    border-radius: 0;
    display: flex;
    min-height: 64px;
    padding: 12px 16px;
    gap: 12px;
    width: 100%;

    & {
      &:first-child {
        border-top-left-radius: var(--bup-border-radius);
        border-top-right-radius: var(--bup-border-radius);
      }
      &:last-child {
        border-bottom-left-radius: var(--bup-border-radius);
        border-bottom-right-radius: var(--bup-border-radius);
      }
      &:not(:last-child) {
        border-bottom: none;
      }
      &:hover {
        background-color: var(--bup-common-hover);
        cursor: pointer;
      }
      &:has(caption) {
        display: grid;
        grid-template-columns: auto 4fr 3fr 200px;
      }
    }
    button {
      &:last-child {
        margin-left: auto;
        margin-right: 4px;
        padding-left: 12px;
        padding-right: 6px;
      }
      &.MuiButton-textSizeSmall {
        color: var(--bup-link-color);
        font-size: 12.5px;

        i.BupIcon-root {
          font-size: 20px;
        }
      }
    }
    caption {
      color: var(--bup-text-secondary);
      font-size: 13.5px;
      text-align: left;
    }
    div {
      &.BupText-group {
        flex-direction: column;
        font-size: 14.5px;
        display: flex;
        gap: 4px;

        small {
          color: var(--bup-text-secondary);
          font-size: 12.5px;
          opacity: 0.8;
        }
      }
      &.BupIcon-group {
        margin-left: auto !important;
        margin-right: 4px;
        gap: 16px;

        i {
          font-size: 18px;
        }
        button.BupButton-icon {
          background: transparent;
          border: none;
          color: var(--bup-icon-color);
          padding: 0;
          height: 28px;
          width: 28px;

          i.BupIcon-root {
            font-size: 18px;
          }
          &:hover {
            background: var(--bup-icon-background-hover);
            border-color: var(--bup-icon-border-hover);
          }
          &.Bup-frame {
            border: 1px solid var(--bup-icon-border);
          }
        }
      }
      &.MuiChip-sizeSmall {
        background-color: var(--bup-success-color);
        height: 20px;
        color: white;

        span {
          font-size: 12.5px;
        }
      }
    }
    i:first-child {
      font-size: 20px;
    }
    label,
    span {
      font-size: 14.5px;
    }
    @media only screen and (max-width: $md) {
      &:has(caption) {
        display: grid;
        grid-template-columns: auto 1fr 50px;

        button.MuiButton-textSizeSmall {
          min-width: 32px;
          pointer-events: none;

          i.BupIcon-root {
            font-size: 24px;
          }
        }
        caption {
          display: none;
        }
      }
      *:last-child {
        padding-right: 0;
      }
    }
    @media only screen and (max-width: $sm) {
      padding: 12px;
    }
  }
}
