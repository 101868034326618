div.BupMenu-root {
  div.MuiMenu-paper {
    @include paper-box;

    ul.MuiMenu-list {
      padding: 6px 0;

      li.MuiMenuItem-root,
      button {
        align-items: center;
        border-radius: 0;
        display: flex;
        font-size: 13.5px;
        font-weight: 400;
        height: 36px;
        justify-content: flex-start;
        max-height: 36px;
        min-height: 0;
        padding: 6px 20px 6px 16px;
        gap: 12px;
        text-align: left;
        width: 100%;

        div.MuiListItemIcon-root {
          min-width: 18px;
        }
        i.BupIcon-root {
          color: var(--bup-text-tertiary);
          font-size: 18px;
          height: fit-content;
          width: 18px;
        }
        span.MuiButton-startIcon {
          margin-left: 0;
          margin-right: 0;
        }
        s {
          display: none;
        }
      }
    }
  }
}
