i.BupIcon {
	&-root {
		font-size: inherit;
		margin: 0;
		padding: 0;
		transition: all 0.2s ease;
	}
	&-circle {
		@include row-center;
		@include font(22px);
		aspect-ratio: 1;
		border-radius: 50%;
		cursor: pointer;
		height: 44px;
		width: 44px;

		&:hover {
			background: rgba($bg-4, 40%);
		}
		&:active {
			background: rgba($bg-4, 80%);
		}
		&.Bup-active {
			color: $primary;
		}
	}
	&-square {
		@include row-center;
		@include font(22px);
		aspect-ratio: 1;
		border-radius: c;
		cursor: pointer;
		height: 44px;
		width: 44px;

		&:hover {
			background: rgba($bg-4, 40%);
		}
		&:active {
			background: rgba($bg-4, 80%);
		}
		&.Bup-active {
			color: $primary;
		}
	}
	&-oval {
		@include row-center;
		font-size: 18px;
		background-color: var(--bup-icon-background);
		border: 1px solid var(--bup-icon-border);
		color: var(--bup-icon-color);
		height: 32px;
		width: 54px;
		border-radius: 16px;
		opacity: 0.8;

		&.BupIcon-small {
			font-size: 18px;
			height: 28px;
			width: 47.25px;
		}
	}
}
div.BupIcon-group {
	align-items: center;
	display: flex;
	gap: 8px;
}
