div.BupStats-root {
  div.BupStats {
    &-heatmap {
      display: grid;
      grid-template-columns: repeat(25, 1fr);
      gap: 2px;
      width: 100%;

      ul {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 2px;

        li {
          @include square;
          border-radius: var(--bup-rounded);
          width: 100%;

          &.Bup-head {
            @include row-center;
            background-color: var(--bup-icon-background);
            border: 1px solid var(--bup-icon-border);
            color: var(--bup-icon-color);
            font-size: 11px;
            font-weight: 600;
          }
          &.Bup-body {
            background-color: var(--bup-navbar-background);
            border: 1px solid var(--bup-common-border);
            font-size: 11px;

            span {
              @include center;
              height: 100%;
              width: 100%;
            }
            &:has(:nth-child(4)) {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-template-rows: repeat(2, 1fr);

              span {
                &:nth-child(1) {
                  background-color: var(--bup-success-border);
                  color: white;
                }
                &:nth-child(2) {
                  background-color: var(--bup-success-background);
                  color: var(--bup-success-color);
                }
                &:nth-child(3) {
                  background-color: var(--bup-danger-background);
                  color: var(--bup-danger-color);
                }
                &:nth-child(4) {
                  background-color: var(--bup-danger-border);
                  color: white;
                }
              }
            }
            &:hover {
              border-color: var(--bup-paper-border);
              background-color: $bg-1;
              cursor: pointer;
            }
          }
        }
      }
    }
    &-legend {
      h4.MuiTypography-h4 {
        font-size: 16px;
        font-weight: 500;
      }
      h5.MuiTypography-h5 {
        font-size: 14.5px;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;

        li {
          align-items: center;
          display: flex;
          gap: 8px;
          font-size: 13px;
          color: var(--bup-text-secondary);

          div {
            @include square;
            height: 12px;
          }
          &:nth-child(1) div {
            background-color: var(--bup-success-border);
          }
          &:nth-child(2) div {
            background-color: var(--bup-success-background);
          }
          &:nth-child(3) div {
            background-color: var(--bup-danger-background);
          }
          &:nth-child(4) div {
            background-color: var(--bup-danger-border);
          }
        }
      }
      small {
        font-size: 11.5px;
        opacity: 0.66;
      }
    }
    &-options {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 8px;

      button.Bup-active {
        background-color: black !important;
        color: white !important;
      }
    }
  }
  table.BupStats-table {
    width: 100%;

    thead {
      tr {
        th {
          background-color: var(--bup-icon-background);
          border-radius: var(--bup-rounded);
          border: 1px solid var(--bup-icon-border);
          color: var(--bup-icon-color);
          font-size: 12px;
          height: 36px;
          padding: 0 12px;
          text-align: left;

          &:not(:first-child) {
            text-align: right;
          }
        }
        &:nth-child(2) th {
          height: 24px;
        }
      }
    }
    tbody {
      tr {
        td {
          background-color: var(--bup-navbar-background);
          border-radius: var(--bup-rounded);
          border: 1px solid var(--bup-common-border);
          font-size: 13px;
          height: 40px;
          padding: 0 12px;

          &:not(:first-child) {
            text-align: right;
            width: 70px;

            &:hover {
              border-color: var(--bup-paper-border);
              background-color: $bg-1;
              cursor: pointer;
            }
          }
          &.Bup-max {
            width: 100px;
          }
        }
      }
    }
  }
}
