section.BupSubscription-root {
  div.MuiAlert-root {
    margin-bottom: 0;
  }
  div.BupItem-root {
    div.BupItem-wrapper {
      padding: 24px;
    }
  }
  footer.BupConsole-footer {
    margin-top: 24px !important;
  }

  @import "subscription/bup-features";
  @import "subscription/bup-plan";
  @import "subscription/bup-payment";
  @import "subscription/bup-selector";
  @import "subscription/bup-status";
}
