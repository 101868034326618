div.BupModal {
	&-actions {
		@include responsive;
		padding: 12px 0 16px;

		button.BupButton-icon {
			border-radius: 50%;
			height: 40px;
			width: 40px;

			i.BupIcon-root {
				font-size: 22px !important;
			}
			&.Bup-selected {
				background: rgba($primary, 0.08);
				color: rgba($primary, 0.8);
			}
			&:disabled {
				background: $bg-3;
			}
		}
		div.MuiBox-root {
			display: flex;
			gap: 8px;
		}
	}
	&-content {
		@include responsive;
		padding-top: 20px;

		& > div {
			display: flex;
			gap: 16px;

			div.BupAvatar-root {
				height: 36px;
				width: 36px;
			}
			textarea {
				border: none;
				font-size: 14px;
				padding: 10px 0;
				outline: none;
				resize: none;
				height: 180px;
				width: 100%;
			}
		}
		p.MuiTypography-p {
			font-size: 14.5px;
			line-height: 1.5;
			margin-bottom: 8px;

			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
		span.Bup-disclaimer {
			background: rgba($primary, 0.08);
			border-radius: 8px;
			font-size: 12.5px;
			line-height: 1.33;
			padding: 12px 16px;
			margin: 4px 0;

			a {
				color: darken($primary, 8%);
				font-weight: bold;
			}
		}
		form.BupModal-form {
			div.BupForm-field {
				div.BupForm-inline {
					position: relative;

					input.BupForm-input {
						border-radius: 20px;
						border: 1.5px solid var(--bup-input-border);
						height: 40px;
						width: 100%;
						outline: none;
						padding: 0 48px;

						&::placeholder {
							color: $bg-7;
						}
						&:focus {
							border-color: rgba(black, 0.33);
						}
					}
					i.BupIcon-root {
						color: black;
						font-size: 20px !important;
					}
					span.BupForm-prefix {
						position: absolute;
						left: 16px;
						top: 16px;
					}
					span.BupForm-sufix {
						position: absolute;
						right: 16px;
						top: 16px;

						i.BupIcon-root {
							color: $success;
						}
					}
				}
				label.BupForm-label {
					display: none !important;
					font-size: 14.5px;
				}
			}
		}
		div.BupModal-share {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 12px;
		}
		div.BupModal-copy {
			align-items: center;
			border-radius: var(--bup-rounded);
			border: 1px solid var(--bup-input-border);
			background: var(--bup-input-background);
			display: grid;
			grid-template-columns: auto 64px;
			grid-gap: 12px;
			font-weight: normal;
			height: 48px;
			outline: none;
			padding: 6px 16px;
			overflow: hidden;

			&:hover,
			&:focus {
				background: var(--bup-input-background-hover);
				border-color: var(--bup-input-border-hover);
				outline: none;
			}
			button {
				border: 1px solid var(--bup-icon-border);
				border-radius: 14px;
				height: 28px;
				width: 28px;
			}
			span {
				@include clamp(1);
				font-size: 15px;
			}
			@media only screen and (max-width: $sm) {
				padding: 6px $padding-sm;
			}
		}
	}
	&-header {
		align-items: center;
		border-bottom: 1px solid var(--bup-divider);
		display: flex;
		justify-content: space-between;
		padding: 12px 12px 12px 20px;

		h2.MuiTypography-h6 {
			font-size: 21.5px;
			font-weight: 500;
			color: inherit;
		}
		button.BupButton-icon {
			color: inherit;
			font-weight: bold;
			opacity: 0.5;
			transition: opacity 0.2s ease;

			i.BupIcon-root {
				font-size: 21px !important;
			}
			&:hover {
				opacity: 1;
			}
		}
		@media only screen and (max-width: $sm) {
			padding-left: $padding-sm;

			h2.MuiTypography-h6 {
				font-size: 20px;
			}
		}
	}
	&-paper {
		@include paper-box;
		border-radius: var(--bup-border-radius);
		max-width: 100%;
		outline: none;
		position: relative;
		width: $sm;
		z-index: 1100;

		&.BupModal {
			&-sizeMedium {
				width: $md;
			}
			&-sizeLarge {
				width: $lg;
			}
			&-fullWidth {
				overflow-y: auto;
				width: $xl;
			}
		}
		@media only screen and (max-width: $sm) {
			border-radius: var(--bup-border-radius);
		}
	}
	&-root {
		@include row-center;
		backdrop-filter: blur(5px);
		background: var(--bup-common-backdrop);
		height: 100vh;
		left: 0;
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 999;

		&:has(iframe) {
			div.BupModal-paper {
				background: transparent;
				width: fit-content;

				iframe.Bup-video {
					border: none !important;
					border-radius: var(--bup-border-radius);
					display: block;

					&:not(.Bup-reel) {
						@include video;
						max-width: 100%;
						width: 100%;
					}
					&.Bup-reel {
						@include reel;
						border-radius: 8px;
						border: none;
						height: 80vh;

						@media only screen and (max-width: $xs) {
							height: auto;
							width: calc(100vw - 16px);
						}
					}
				}
			}
		}
		@media only screen and (max-width: $sm) {
			padding: 6px;
		}
	}
}
