html,
body {
  background: white;
  font-family:
    -apple-system,
    Roboto,
    Helvetica Neue,
    sans-serif;
  font-size: var(--bup-font-size);
  font-weight: var(--bup-font-weight);
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0 !important;
  scroll-behavior: smooth;

  &.BupMode-dark {
    background: $dark;
    color: white;
  }
  &.Bup-modal {
    overflow-y: hidden !important;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    div.BupConsole-root {
      max-height: 100vh;
    }
  }
}
*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
br,
i,
button,
label {
  user-select: none;
}
img {
  max-width: 100%;
  pointer-events: none;
  user-select: none;
}
hr {
  border: none;
  border-top: 1px solid $bg-2;
  margin: 75px 0 !important;
}
ol,
ul {
  list-style: none;
}
::selection {
  background: $danger;
  color: white;
}
::-webkit-scrollbar {
  width: 8px;

  &-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #ddd;
  }
  &-thumb {
    -webkit-border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: #ccc;
    border-radius: 4px;
    border: 1px solid transparent;

    &:window-inactive {
      background: rgba(160, 160, 160, 0.4);
    }
  }
  @media only screen and (max-width: $md) {
    width: 4px;

    &-track {
      -webkit-box-shadow: transparent;
      background: transparent;
    }
  }
}
