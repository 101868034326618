div.BupCard-root {
  background: transparent;
  border-radius: var(--bup-rounded) !important;
  box-shadow: none;
  color: inherit;
  width: 100%;

  @import "bup-media";
  @import "bup-content";
  @import "bup-actions";

  div.BupCard-main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 12px;

    @media only screen and (max-width: $sm) {
      @include responsive;
      background: var(--bup-paper-background);
      border: 1px solid var(--bup-common-border);
      border-bottom-left-radius: var(--bup-border-radius);
      border-bottom-right-radius: var(--bup-border-radius);
      border-top: none;
      padding: 12px;
    }
  }
  &.BupCard-listed {
    align-items: flex-start;
    display: grid;
    grid-template-columns: 227.5px auto;
    gap: 24px;

    div.BupCard-main {
      padding-top: 0;
      margin-top: -3px;

      @media only screen and (max-width: $sm) {
        background-color: transparent;
        border-radius: var(--bup-rounded);
        border: none;
        margin-top: 0;
        padding: 0 !important;
      }
    }
    &:has(.BupCard-mediaSquare) {
      gap: 20px;
      grid-template-columns: 128px auto;
      transform: grid-template-columns 0.2s ease;

      @media only screen and (max-width: $sm) {
        grid-template-columns: 92px auto;
        gap: 16px;
      }
      @media only screen and (max-width: $xs) {
        grid-template-columns: 64px auto;
        gap: 12px;
      }
    }
    &:has(.BupCard-mediaMinified) {
      grid-template-columns: 64px auto !important;
      gap: 12px !important;
      margin-top: 0;
      padding-right: 8px;

      div.BupCard-main {
        gap: 4px;
      }
      @media only screen and (max-width: $sm) {
        padding-right: 0;
      }
    }
    &:has(.BupCard-mediaCollapsed) {
      grid-template-columns: 48px auto !important;
      grid-gap: 12px !important;
      padding-right: 0;
    }
    &:not(:has(.BupCard-eventRating)) {
      div.BupCard-main {
        @media only screen and (max-width: $xs) {
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    &.BupCard-skeleton {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
  &.BupCard-mosaic {
    @include developer;
    background-color: var(--bup-paper-background);
    border: 1px solid var(--bup-common-border);
    border-radius: var(--bup-border-radius) !important;
    text-align: center;
    padding-top: 12px;

    div.BupCard-main {
      border: none !important;
      padding: 8px 8px 12px;
    }
    &:hover {
      cursor: pointer;

      div.BupAvatar-root {
        border-radius: var(--bup-border-radius);
        border-width: 2px;
      }
    }
  }
  &.BupCard-collapsed {
    width: 100%;

    div.BupCard-main {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      div.MuiCardContent-root {
        padding-right: 16px;
      }
      div.MuiCardActions-root {
        margin-top: 3px;
      }
    }
    &.BupCard-panel {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding: 8px;

      div.BupCard-main {
        align-items: center;
      }
      &:hover {
        background-color: var(--bup-common-hover);
      }
    }
  }
}

@import "bup-suggest";
