section.BupDocument-root {
	header.BupDocument-header {
		@include paper-box;

		@import "header/bup-actions.scss";
		@import "header/bup-content.scss";
		@import "header/bup-media.scss";
		@import "header/bup-navbar.scss";
		@import "header/bup-photo.scss";

		&:not(:has(.BupDocument-tabs)) {
			padding-bottom: 12px;
		}
	}
	main.BupDocument-main {
		@import "main/bup-description.scss";
		@import "main/bup-details.scss";
		@import "main/bup-images.scss";
		@import "main/bup-map.scss";
	}
	aside.BupDocument-aside {
		@import "aside/bup-access.scss";
		@import "aside/bup-booking.scss";
		@import "aside/bup-interests.scss";
		@import "aside/bup-ticket.scss";

		&:empty {
			display: none;

			& + * {
				margin-top: 0;
			}
		}
	}
	@media only screen and (max-width: $xs) {
		padding-bottom: $padding-sm;
	}
}
