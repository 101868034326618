div.BupDocument-photo {
  @include square;

  border: 4px solid var(--bup-paper-background);
  border-radius: var(--bup-border-radius);
  margin-bottom: 16px;
  margin-top: -140px;
  width: 160px;

  & + span.BupDocument-file {
    position: relative;
    width: fit-content;

    button.BupButton-icon {
      background: var(--bup-paper-background);
      border: 1px solid var(--bup-paper-border);
      border-radius: 18px;
      color: var(--bup-text-primary);
      height: 32px;
      margin-left: 120px;
      margin-top: -56px;
      position: absolute;
      min-width: 32px;
      width: 32px;

      i.BupIcon-root {
        font-size: 20px !important;
      }
    }
  }
  @media only screen and (max-width: $xs) {
    margin: -130px auto 16px;

    & + span.BupDocument-file {
      margin-left: calc(50% - 80px);
    }
  }
}
