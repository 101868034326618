div.BupDocument-images {
  display: grid;
  gap: 4px;
  width: 100%;

  &:not(:has(:nth-child(3))) {
    display: none;
  }
  img.BupImage-root {
    border-radius: 0;

    &:nth-child(1) {
      border-top-left-radius: var(--bup-border-radius);
      grid-area: a;
    }
    &:nth-child(2) {
      grid-area: b;
    }
    &:nth-child(3) {
      grid-area: c;
    }
    &:nth-child(4) {
      grid-area: d;
    }
    &:nth-child(5) {
      grid-area: e;
    }
    &:nth-child(6) {
      grid-area: f;
    }
    &:nth-child(n + 7) {
      display: none;
    }
  }
  &:has(:nth-child(3)) {
    grid-template-areas: "a b c";

    &:not(:has(img:nth-child(4))) img {
      @include square;
      &:nth-child(1) {
        border-bottom-left-radius: var(--bup-border-radius);
      }
      &:nth-child(3) {
        border-bottom-right-radius: var(--bup-border-radius);
        border-top-right-radius: var(--bup-border-radius);
      }
    }
  }
  &:has(:nth-child(4)) {
    grid-template-areas:
      "a a a b b b"
      "c c c d d d";

    &:not(:has(img:nth-child(5))) img {
      @include video;

      &:nth-child(2) {
        border-top-right-radius: var(--bup-border-radius);
      }
      &:nth-child(3) {
        border-bottom-left-radius: var(--bup-border-radius);
      }
      &:nth-child(4) {
        border-bottom-right-radius: var(--bup-border-radius);
      }
    }
  }
  &:has(:nth-child(5)) {
    grid-template-areas:
      "a a a b b b"
      "c c d d e e";

    &:not(:has(img:nth-child(6))) img {
      @include video;

      &:nth-child(2) {
        border-top-right-radius: var(--bup-border-radius);
      }
      &:nth-child(3) {
        border-bottom-left-radius: var(--bup-border-radius);
      }
      &:nth-child(n + 3) {
        @include landscape;
      }
      &:nth-child(5) {
        border-bottom-right-radius: var(--bup-border-radius);
      }
    }
  }
  &:has(:nth-child(6)) {
    grid-template-areas:
      "a b c"
      "d e f";

    img {
      @include landscape;

      &:nth-child(3) {
        border-top-right-radius: var(--bup-border-radius);
      }
      &:nth-child(4) {
        border-bottom-left-radius: var(--bup-border-radius);
      }
      &:nth-child(6) {
        border-bottom-right-radius: var(--bup-border-radius);
      }
    }
  }
  @media only screen and (max-width: $sm) {
    grid-template-areas:
      "a a a b b b"
      "c c c d d d";
  }
  @media only screen and (max-width: $xs) {
    gap: 2px;
    padding: 2px 8px 0;
  }
}
