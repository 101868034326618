div.BupSubscription-plan {
  border: 0.75px solid var(--bup-paper-border);
  border-radius: var(--bup-border-radius);

  & {
    button {
      margin: 8px auto 0;

      &:first-child {
        margin-top: 32px !important;
      }
      &.Bup-showAll {
        background-color: var(--bup-paper-background);
        border-radius: var(--bup-border-radius);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 1px solid var(--bup-common-border);
        color: var(--bup-link-color);
        font-size: 13.5px;
        font-weight: 500;
        height: 56px;
        margin: 0;
        width: 100%;

        &:hover {
          background: var(--bup-common-hover);
        }
      }
    }
    span {
      text-align: center;

      &.BupPlan-name {
        color: var(--bup-icon-color);
        font-size: 30px;
        font-weight: 400;
      }
      &.BupPlan-affiliation {
        color: var(--bup-icon-color-hover);
        font-size: 13.5px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      &.BupPlan-price {
        font-size: 18px;
        margin-top: 12px;
      }
      &.BupPlan-periodicity {
        font-size: 12.5px;
        margin-top: 4px;
      }
      &.MuiTypography-p {
        font-size: 14px;
        font-weight: 300;
        margin: 32px auto 0;
        max-width: 80%;
      }
    }
    hr.MuiDivider-root {
      margin: 32px 0 16px !important;
    }
    ul {
      display: flex;
      flex-direction: column;
      padding: 0 8px;
      gap: 4px;

      li {
        font-size: 13px;
        width: 90%;

        &:first-child {
          color: var(--bup-text-primary);
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 12px;
        }
        &:has(i) {
          align-items: center;
          color: var(--bup-text-secondary);
          display: flex;
          font-weight: 400;
          gap: 16px;
          justify-content: row;

          i {
            color: var(--bup-icon-color);
            font-size: 18px;
            font-weight: bold;
          }
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
  & > div {
    background: var(--bup-paper-background);
    border-radius: var(--bup-border-radius);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0px;
    padding: 64px 16px 32px;
  }
  &:has(.Bup-popular) {
    span.Bup-popular {
      @include background($secondary);
      border-radius: 16px;
      color: white;
      font-size: 11px;
      margin: 0 auto 32px;
      padding: 2px 16px;
      user-select: none;
      width: fit-content;
    }
    & > div {
      padding: 16px 16px 32px;
      height: calc(100% - 8px);
      margin-bottom: -46px;

      @media only screen and (max-width: $xs) {
        margin-bottom: 0;
      }
    }
  }
  @media only screen and (max-width: $md) {
    max-width: 100%;
    min-width: 0;
    margin: 0 auto;
    width: $xs;
  }
  @media only screen and (max-width: $xs) {
    margin-top: 0;
    width: calc(100vw - 16px);
  }
}
