nav.BupAppbar-root {
	align-items: center;
	background: var(--bup-navbar-background);
	border-top: 1px solid var(--bup-common-border);
	bottom: 0;
	display: none;
	height: $navbar-y;
	padding: 0 $padding-lg;
	position: fixed;
	width: 100%;
	z-index: 99;

	ul.BupAppbar-nav {
		@include center;
		height: 100%;
		justify-content: space-around;

		li {
			@include center(column);
			background: var(--bup-navbar-item-background);
			border-radius: 20px;
			color: var(--bup-navbar-item-color);
			cursor: pointer;
			gap: 2.5px;
			height: 32px;
			margin-top: 0;
			max-width: 120px;
			padding: 0 16px;
			width: 61px;

			span {
				@include font(10.5px);
				line-height: 11px;
			}
			i {
				@include center;
				font-size: 22px !important;
				opacity: 0.66;
			}
			&.Bup-active {
				pointer-events: auto;

				i {
					border-radius: 40px;
					color: var(--bup-navbar-item-background-active);
					opacity: 1;
					width: 44px;
				}
			}
		}
		button.BupButton-icon {
			background: var(--bup-input-background) !important;
			border: 1px solid var(--bup-input-border);
			color: var(--bup-icon-color);
			margin-top: -32px;
			height: 48px;

			i.BupIcon-root {
				font-size: 24px;
			}
		}
	}
	@media only screen and (max-width: $sm) {
		display: initial;
		padding: 0 $padding-sm;
		z-index: 998;
	}
	@media only screen and (max-width: $xs) {
		height: calc(#{$navbar-y} * 0.9);
		padding: 0;
	}
}
