ul.BupDocument-interests {
  align-items: center;
  display: none;
  gap: 8px;
  height: 40px;

  @include slide-scroll;

  li {
    @include paper-box;
    @include row-center;
    border-radius: 18px;
    background: white;
    color: black;
    font-size: 13px;
    height: 36px;
    padding: 4px 12px;
    gap: 8px;
    white-space: nowrap;
    user-select: none;

    i.BupIcon-root {
      font-size: 18px;
    }
    &.main {
      @include paper-box;
      background: $bg-9;
      border-radius: 50%;
      color: white;
      padding: 4px 8px;
      min-width: 36px;
      width: 36px;
    }
    &.divider {
      border: none;
      box-shadow: none;
      border-right: 1.5px solid var(--bup-divider);
      height: 32px;
      padding: 0;
      max-width: 1.5px;
      margin: 0 2px;
    }
  }
  @media only screen and (max-width: $xs) {
    padding: 0 8px;
  }
}
span.MuiSkeleton-root {
  &.BupDocument-interests {
    height: 40px;
    display: none;
    width: 100%;
  }
}
