div.BupChip-root {
	user-select: none;

	&.MuiChip {
		&-filledPrimary {
			background: var(--bup-button-primary);
			color: white;
		}
		&-filledSuccess {
			@include background($success);
			color: white;
		}
		&-filledPremium {
			background: var(--bup-premium-button-background);
			color: var(--bup-premium-button-color);
		}
	}
	&.BupChip-tutor {
		background-color: var(--bup-icon-background);
		border: 1px solid var(--bup-icon-border);
		color: var(--bup-icon-color);
		gap: 8px;
		margin-top: 16px;
		margin-left: 0 !important;
		height: 28px;
		padding: 3px;

		svg {
			height: 12px;

			* {
				fill: var(--bup-icon-color);
			}
		}
		span {
			@include row-center;
			font-size: 12px;
			font-weight: 500;
			gap: 4px;
		}
	}
}
ul.BupChip-group {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;

	li {
		div.MuiChip {
			&-root {
				margin: 0 !important;
			}
			&-filled {
				background-color: var(--bup-icon-background);
				border: 1px solid var(--bup-icon-border);
				color: var(--bup-icon-color);
			}
			&-outlined {
				border-color: var(--bup-chip-border);
				color: var(--bup-text-secondary);

				&.Bup-active {
					background: var(--bup-tab-background);
					border-color: var(--bup-tab-border);
					color: var(--bup-tab-color);
				}
				i.BupIcon-root {
					color: inherit;
				}
			}
		}
		div.BupChip-skeleton {
			background-color: var(--bup-skeleton);
			height: 32px;
			width: 96px !important;
		}
		i.BupIcon-root {
			font-size: 16px;
			margin-left: 8px;
		}
	}
}
