.BupAccordion {
  &-root {
    &:not(:has(.Bup-hidden)) {
      .BupAccordion-header i.BupIcon-root {
        opacity: 0.5;
      }
    }
    @media only screen and (max-width: $xs) {
      border: 1px solid var(--bup-common-border);
      cursor: pointer;

      &.Bup-expanded .BupAccordion-header {
        background: transparent;
        padding-bottom: 0;
      }
      &:first-child {
        border-top-left-radius: var(--bup-border-radius);
        border-top-right-radius: var(--bup-border-radius);
      }
      &:last-child {
        border-bottom-left-radius: var(--bup-border-radius);
        border-bottom-right-radius: var(--bup-border-radius);
        margin-bottom: 8px;
      }
      &:not(:first-child) {
        border-top: none;
      }
    }
  }
  &-header {
    align-items: center;
    border-radius: inherit;
    display: flex;
    justify-content: space-between;

    span.MuiTypography-p {
      color: var(--bup-text-primary) !important;
      font-weight: 500 !important;
      font-size: 15.5px !important;
      margin: 0 !important;
      opacity: 1;
    }
    i.BupIcon-root {
      color: var(--bup-icon-color);
      font-size: 20px;
      font-weight: 600;
      visibility: hidden;
    }
    @media only screen and (max-width: $md) {
      background: var(--bup-icon-background);
      padding: 18px 12px;

      i.BupIcon-root {
        visibility: visible;
      }
    }
  }
  &-content {
    border-top: 1px solid var(--bup-common-border);
    padding-top: 16px;

    @media only screen and (max-width: $xs) {
      border: none;
      padding: 0 12px 16px;
    }
  }
}
