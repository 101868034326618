nav.BupBrowser-nav {
  align-items: center;
  background: var(--bup-navbar-background);
  border-bottom: 1.5px solid var(--bup-common-border);
  color: var(--bup-navbar-color);
  display: flex;
  justify-content: space-between;
  height: $navbar-y;
  left: 0;
  padding: 0px 78px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  & > div a {
    align-items: center;
    display: flex;
    gap: 12px;
  }
  span.BupLogo-span {
    @include font(28px, 500);
    @include logo($tertiary);
    margin: 0;
  }
  svg.BupBrowser-logo {
    display: inline;
    height: 40px;
    width: 40px;
  }
  img.BupLogo-provider {
    background-color: transparent;
    border: none;
    border-radius: 0;
    max-height: 28px;
    height: 28px;
  }
  button {
    height: 36px;
  }
  hr {
    border-color: var(--bup-common-border);
    height: 28px;
  }
  @media only screen and (max-width: $lg) {
    padding: 0 48px;
  }
  @media only screen and (max-width: $md) {
    padding: 0 16px;
  }
  @media only screen and (max-width: $sm) {
    padding: 0 12px;

    span.BupLogo-span,
    hr {
      display: none;
    }
  }
}
