div.BupSearch-container {
	padding: 32px 24px;

	h2.MuiTypography-h2 {
		align-items: center;
		display: flex;
		font-size: 24px;
		justify-content: space-between;
		margin-bottom: 8px;

		button.BupButton-icon {
			background: var(--bup-icon-background);
			border: 1px solid var(--bup-icon-border);
			border-radius: 14px;
			height: 28px;
			width: 28px;

			i.BupIcon-root {
				font-size: 18px;
			}
			&:hover {
				background: var(--bup-icon-background-hover);
				border-color: var(--bup-icon-border-hover);
			}
		}
	}
	input {
		background: var(--bup-input-background);
		border: 1.5px solid var(--bup-input-border);
		border-radius: 22px;
		height: 44px;
		padding: 6px 16px;
		width: 100%;

		&:hover,
		&:focus {
			background: var(--bup-input-background-hover);
			border-color: var(--bup-input-border-hover);
			outline: none;
		}
	}
	ul.BupSearch {
		&-results {
			display: flex;
			flex-direction: column;

			li button {
				background: var(--bup-navbar-item-background);
				box-shadow: none;
				border: none !important;
				border-radius: 8px;
				display: grid;
				grid-template-columns: 1fr 32px;
				gap: 8px;
				font-weight: normal;
				padding: 6px 8px 6px 16px;
				height: 36px;
				justify-content: space-between;
				text-align: left;
				width: 100%;

				i.BupIcon-root {
					opacity: 0.1;
				}
				span {
					overflow-x: hidden;
				}
				&.MuiButton-root {
					@media only screen and (max-width: $sm) {
						padding: 6px 8px;
						gap: 4px;
					}
					@media only screen and (max-width: $xs) {
						padding: 6px 4px;
					}
				}
				&:hover {
					background: var(--bup-navbar-item-background-hover);

					i.BupIcon-root {
						opacity: 1;
					}
				}
			}
		}
		&-suggest {
			display: flex;
			flex-direction: row;
			gap: 8px;
			padding: 0 8px;

			div.MuiChip-root {
				@include row-center;
				background: var(--bup-chip-background);
				border-radius: 16px;
				border: 1px solid var(--bup-chip-border);
				color: var(--bup-chip-color);
				cursor: pointer;
				font-size: 12px;
				height: 20px;
				width: fit-content;

				span {
					padding: 2px 6px;
				}
				&:hover {
					background: var(--bup-chip-background-hover);
					border-color: var(--bup-chip-border-hover);
					color: var(--bup-chip-color-hover);
				}
				&.Bup-active {
					background-color: var(--bup-chip-background-active);
					border-color: var(--bup-chip-border-active);
					color: var(--bup-chip-color-active);
				}
			}
			@media only screen and (max-width: $sm) {
				padding: 0 4px;
			}
		}
	}
	@media only screen and (max-width: $sm) {
		padding: 16px;
	}
	@media only screen and (max-width: $sm) {
		padding: 16px 8px;
	}
}
