div.BupPost {
	&-root {
		@media only screen and (max-width: $xs) {
			padding: 0;
		}
	}
	&-item {
		@include paper-box;
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 8px 0;

		&:not(.BupPost-inset) {
			@include responsive;
		}
		header.BupPost-header {
			display: grid;
			gap: 16px;
			grid-template-columns: 36px 1fr 36px;
			padding: 8px 0 4px;
			width: 100%;

			div.BupAvatar-root,
			span.BupAvatar-root {
				height: 36px;
				width: 36px;
			}
			@media only screen and (max-width: $xs) {
				grid-template-columns: 44px 1fr 36px;
				gap: 8px;
			}
		}
		div.BupPost {
			&-actions {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-left: auto;
				margin-right: -4px;
				gap: 8px;

				i.BupIcon-root {
					color: var(--bup-text-primary);
					font-size: 20px !important;

					&.BupIcon-pin {
						color: var(--bup-link-color);
					}
				}
				button.BupButton-icon {
					border-radius: 50%;
					height: 36px;
					padding: 0;
					width: 36px;

					&:hover {
						background-color: var(--bup-divider);
					}
				}
			}
			&-content {
				padding-left: 52px;

				p.BupPost-message {
					font-size: 13.5px;
					line-height: 1.4;
					white-space: pre-wrap;

					a {
						color: var(--bup-text-tertiary);

						&:hover {
							text-decoration: underline;
						}
					}
				}
				button.BupButton-root {
					border-color: var(--bup-divider);
					border-radius: var(--bup-rounded);
					border-width: 0;
					font-weight: 400;
					height: 28px !important;

					&.MuiButton-textPrimary {
						font-weight: 500;
					}
					&:hover {
						background: var(--bup-common-hover) !important;
					}
				}
				@media only screen and (max-width: $md) {
					padding-left: 0;
				}
			}
			&-from {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				overflow: hidden;
				gap: 0px;

				& > div {
					@include row-center;
					gap: 3px;
					margin-bottom: 2px;
					width: fit-content;
				}
				span.MuiTypography-span,
				span.BupPost-target a {
					cursor: pointer;
					font-size: 14.5px;
					font-weight: 500;
					white-space: nowrap;

					b {
						font-weight: 500;
					}
					&:hover {
						text-decoration: underline;
					}
				}
				span.BupPost-target {
					align-items: center;
					display: flex;
					gap: 3px;
				}
				small {
					@include row-center;
					font-size: 12px;
					gap: 4px;
					width: fit-content;
				}
				i.BupIcon-root {
					font-size: 14px !important;
					font-weight: 400;
					color: var(--bup-text-secondary);

					&.BupIcon-verified {
						color: var(--bup-link-color) !important;
					}
					&.BupIcon-premium {
						color: $golden !important;
					}
				}
			}
			&-likes {
				align-items: center;
				display: flex;
				flex-direction: row;
				font-size: 12px;
				user-select: none;

				button.BupButton-icon {
					border-radius: 50%;
					background-color: inherit;
					color: inherit;
					height: 26px !important;
					transition: none;
					width: 32px !important;

					i.BupIcon-root {
						font-size: 18px !important;
					}
					&.BupButton-liked {
						color: $danger;
					}
				}
				small {
					font-size: 12px;
				}
			}
			&-likesDivider {
				align-items: center;
				border-left: 1px solid var(--bup-divider);
				display: flex;
				gap: 2px;
				height: 20px;
				padding-left: 8px;
			}
			&-likesGroup {
				align-items: center;
				background-color: var(--bup-paper-background);
				border-radius: var(--bup-rounded);
				border: 0 solid var(--bup-divider);
				color: var(--bup-text-tertiary);
				cursor: pointer;
				display: flex;
				font-weight: 400;
				gap: 0px;
				height: 28px;
				justify-content: center;
				padding-right: 8px;

				&:hover {
					background: var(--bup-common-hover);
					border-color: var(--bup-input-border);
				}
			}
			&-social {
				align-items: center;
				display: flex;
				flex-direction: row;
				gap: 12px;
				padding-bottom: 4px;
				width: 100%;

				&:not(:has(.MuiSkeleton-root)) {
					margin-left: -8.5px;
				}
				button.BupButton-root {
					height: 32px;
					font-size: 12px;

					&.BupPost-show {
						margin-left: auto;
					}
				}
				span.MuiSkeleton-root {
					border-radius: var(--bup-rounded);

					&.BupSkeleton-small {
						height: 16px;
						width: 80px;
					}
					&.BupSkeleton-button {
						height: 32px;
						border-radius: 16px;
						width: 120px;

						&:last-child {
							margin-left: auto;
						}
					}
					&.BupSkeleton-icon {
						border-radius: 50%;
						height: 32px;
						margin-left: 0;
						width: 32px;
					}
				}
				@media only screen and (max-width: $md) {
					margin-left: 0;

					&:not(:has(.MuiSkeleton-root)) {
						margin-left: 0px;
					}
				}
			}
		}
		span.MuiSkeleton-root {
			border-radius: var(--bup-rounded);

			&.BupSkeleton-avatar {
				height: 36px;
				width: 36px;
			}
			&.BupSkeleton-text {
				&:first-child {
					margin-bottom: 2px;
					width: 180px;
				}
				&:last-child {
					width: 120px;
				}
			}
			&.BupSkeleton-icon {
				border-radius: 50%;
				height: 32px;
				width: 32px;
				margin-left: auto;
			}
		}
	}
	&-list {
		&:empty {
			display: none;

			& + div.BupEmpty-root {
				margin-top: 0;
				width: 100%;

				@media only screen and (max-width: $xs) {
					border-radius: 0 !important;
					margin: 0 !important;
				}
			}
		}
	}
}
