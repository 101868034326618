section.BupConsole-section {
	margin: 0 auto;
	max-width: $xl;
	padding: 32px 16px;

	&.Bup-fixed {
		left: 0;
		max-width: 100vw;
		padding: 0;
		position: absolute;
		top: 0;
		width: 100vw;
		z-index: 0;
	}
	&.Bup-float {
		position: relative;
		z-index: 1;
	}
	&:has(footer.BupConsole-footer) {
		padding-bottom: 16px;
	}
	div.BupSection-wrapper {
		@media only screen and (max-width: $xs) {
			padding-left: $padding-sm !important;
			padding-right: $padding-sm !important;
		}
	}
	@media only screen and (max-width: $lg) {
		padding: 16px;
	}
	@media only screen and (max-width: $xs) {
		padding: 0 0 $padding-sm;
	}
}
