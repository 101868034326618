.BupHelp {
  &-root {
    h2.MuiTypography-h2 {
      font-size: 21px;
      font-weight: 500;
    }
    h3.MuiTypography-h3 {
      font-size: 18px;
      font-weight: 500;
    }
    p.MuiTypography-body1 {
      font-size: 15px;
    }
    ul.BupGrid-container {
      display: grid;
      grid-gap: 12px;

      &:not(:has(:only-child)) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    li.BupGrid-item {
      background-color: var(--bup-paper-background);
      border: 1px solid var(--bup-common-border);
      border-radius: var(--bup-border-radius);
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 240px;
      padding: 24px 16px;
      text-align: center;

      &:only-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 32px;
        padding-left: 32px;
        text-align: left;
      }
      img {
        border: none;
        background-color: transparent;
        height: 90px;
        width: 90px;
        margin: 0 auto;
      }
      p.MuiTypography-body1 {
        @include clamp(2);
        color: var(--bup-text-secondary);
        font-size: 14px;
      }
      &:hover {
        cursor: pointer;
        background-color: var(--bup-common-hover);
        border-color: var(--bup-paper-border);
      }
    }
  }
  &-image {
    background-color: var(--bup-input-background);
    border-radius: var(--bup-rounded);
    border: 1px solid var(--bup-input-border);
    color: transparent;
    display: block;
    height: 100px;
    width: 100px;
  }
  &-support {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;

    li {
      @include responsive;
      background-color: var(--bup-paper-background);
      border: 1px solid var(--bup-common-border);
      border-radius: var(--bup-border-radius);
      cursor: pointer;
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding: 16px;

      &:hover {
        background-color: var(--bup-common-hover);
        border-color: var(--bup-input-border);
      }
      h4.MuiTypography-h4 {
        font-size: 16px;
        font-weight: 500;
      }
      span.MuiTypography-p {
        font-size: 13.5px;
        color: var(--bup-text-secondary);
      }
      i.BupIcon-root {
        color: var(--bup-icon-color);
        font-size: 20px;
        margin-top: 2px;
      }
    }
    &.BupHelp-supportInset {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: $lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: $sm) {
      grid-template-columns: repeat(1, 1fr) !important;
    }
    @media only screen and (max-width: $xs) {
      padding: 0 $padding-sm;
    }
  }
  &-tutorial {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;

    li {
      background-color: var(--bup-paper-background);
      border: 1px solid var(--bup-common-border);
      border-radius: var(--bup-border-radius);
      cursor: pointer;
      display: flex;
      flex-direction: column;

      div.BupHelp-media {
        @include landscape;
        background-color: var(--bup-common-border);
        border-radius: var(--bup-border-radius);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: transparent;
        user-select: none;
      }
      div.BupHelp-content {
        padding: 16px !important;

        h4.MuiTypography-h4 {
          font-size: 16px;
          font-weight: 500;
        }
      }
      &:hover {
        background-color: var(--bup-common-hover);
        border-color: var(--bup-input-border);
      }
    }
    @media only screen and (max-width: $lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: $md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: $sm) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media only screen and (max-width: $xs) {
      padding: 0 $padding-sm;
    }
  }
}
