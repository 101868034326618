div.BupDocument-actions {
  position: relative;

  div.MuiButtonGroup-root {
    border-radius: 18px;
    background: var(--bup-button-primary);

    button {
      box-shadow: none;
      border: none;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-right: 12px;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.Bup-danger {
        cursor: not-allowed;
      }
    }
  }
  div.MuiPopper-root {
    position: absolute !important;
    margin-top: 40px;
    margin-left: 16px;

    &:has(ul.MuiList-root) {
      position: relative;
      z-index: 99;

      ul.MuiList-root {
        padding: 4px 0;

        li.MuiMenuItem-root {
          font-size: 13.5px;
        }
      }
    }
  }
  div.BupDocument-rating {
    align-items: flex-end;
    margin-top: -8px;

    & > div {
      margin: 0;

      span {
        margin: 0;

        &.Mui-disabled {
          opacity: 1;
        }
        &.MuiTypography-span {
          font-size: 13.5px;
        }
      }
    }
    & > p {
      font-size: 12px;
      margin-bottom: 8px;
    }
    @media only screen and (max-width: $sm) {
      align-items: flex-start;
      margin-top: 8px;
    }
    @media only screen and (max-width: $xs) {
      align-items: center;
    }
  }
  button.MuiButton-root {
    padding: 6px 20px;
  }
  button.BupButton-icon {
    background: var(--bup-input-background-hover);
    border: 0.5px solid var(--bup-input-border-hover);
    color: var(--bup-text-primary);
    cursor: pointer;
    height: 36px;
    width: 36px;

    &:hover {
      background: var(--bup-input-background);
      border-color: var(--bup-input-border);
    }
    &.Bup-selected {
      color: $danger;
    }
  }
  button.MuiSkeleton-root {
    height: 36px;
    border-radius: 18px;
    border: none;

    &.BupSkeleton-button {
      width: 120px;
    }
    &.BupSkeleton-icon {
      width: 36px;
    }
  }
  @media only screen and (max-width: $xs) {
    width: 100%;

    &:not(:has(button.BupButton-only)) {
      &:not(:has(button.BupButton-icon)) {
        display: flex;
        justify-content: center;
      }
      &:has(button.BupButton-icon) {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        gap: 6px;
      }
    }
    &:has(button.BupButton-only),
    &:has(div[role="group"]) {
      display: grid;
      grid-template-columns: 1fr auto auto !important;

      button:first-child {
        width: 100%;
      }
      button:last-child {
        width: 32px;
      }
    }
    button.BupButton-root {
      width: 100%;
    }
  }
}
