div.BupAvatar {
	&-root {
		&.MuiAvatar-colorDefault {
			background-color: var(--bup-avatar-background);
		}
		&.MuiAvatar-rounded i.BupIcon-root {
			color: var(--bup-avatar-color) !important;
		}
		svg.MuiAvatar-fallback {
			fill: var(--bup-avatar-color);
			height: 66%;
		}
	}
	&-group {
		height: 36px;
		width: fit-content;

		div.MuiAvatar-root {
			border-color: var(--bup-paper-background);
		}
		hr.MuiDivider-vertical {
			height: 16px;
		}
		svg.BupLogo-root {
			border: 2px solid transparent;
			height: 32px;
			min-width: 32px;
		}
		span.BupAvatar-text {
			font-size: 12px;
			opacity: 0.8;
			text-wrap: wrap !important;
		}
		span.MuiTypography-span {
			color: var(--bup-text-secondary);
			font-size: 12px !important;
			white-space: nowrap;
		}
		span.MuiSkeleton-root {
			&.BupSkeleton-avatar {
				height: 32px;
				min-width: 32px;
			}
			&.BupSkeleton-member {
				min-width: 240px;
			}
		}
		@media only screen and (max-width: $xs) {
			gap: 8px !important;

			span.MuiTypography-span {
				white-space: wrap;
			}
		}
	}
}
