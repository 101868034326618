div.BupSubscription-payment {
  div.BupSubscription {
    &-creditCard {
      border: 1.5px solid var(--bup-input-border);
      border-radius: var(--bup-border-radius);
      padding: 12px 16px;
      width: $xs;

      & > div {
        align-items: center;
        color: var(--bup-text-secondary);
        display: flex;
        font-size: 15px;
      }
      i.BupIcon-root {
        font-size: 24px;
        margin-right: 16px;
      }
      @media only screen and (max-width: $sm) {
        width: 100%;
      }
    }
    &-creditLock {
      width: $xs;

      h4.MuiTypography-h4 {
        font-size: 18.5px;
      }
      i.BupIcon-root {
        color: var(--bup-icon-color);
        font-size: 18px;
      }
      @media only screen and (max-width: $sm) {
        width: 100%;
      }
    }
  }
}
