.BupUser-points {
  div.BupPoints-current {
    div.MuiChip-root {
      @include row-center;
      background-color: $primary;
      color: white;
      height: 20px;
      width: 20px;
      margin-right: 8px;

      span.MuiChip-label {
        font-size: 12px;
        font-weight: bold;
        padding: 0;
      }
    }
    span {
      font-size: 14px;
    }
  }
  div.BupPoints-details {
    margin: 16px 0;

    h6.MuiTypography-h6 {
      font-size: 14px;
      text-decoration: underline;
    }
  }
  div.BupPoints-progress {
    background-color: $border;
    border-radius: 8px;
    height: 8px;
    width: 100%;

    div.BupPoints-bar {
      background-color: $primary;
      border-radius: 8px;
      height: inherit;
    }
    & + span {
      color: $primary;
      display: block;
      font-size: 13px;
      font-weight: bold;
    }
  }
  ul {
    &.BupPoints-benefits {
      @include grid(6);
    }
    &.BupPoints-levels {
      display: flex;
      flex-direction: column;
      grid-gap: 16px;
      margin-bottom: 16px;

      li {
        align-items: center;
        border-radius: var(--bup-rounded);
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 60px auto;
        padding: 16px;

        &:not(.Bup-active) {
          @include background($bg-1);
          border: 1.5px solid $bg-4;

          &:hover {
            border-color: darken($bg-6, 7%);
          }
        }
        &.Bup-active {
          @include bg-blue;
          border-color: rgba($primary, 0.11);
          border-width: 1.5px;
          pointer-events: auto;

          &:hover {
            border-color: rgba($primary, 0.33);
          }
        }
      }
      div {
        display: flex;
        flex-direction: column;

        b {
          @include font(16px, bold);
        }
        span.MuiTypography-span {
          @include font(12px);
          opacity: 0.66;
        }
        span.MuiTypography-p {
          @include font(15px);
          opacity: 1;
          margin: 0;
        }
      }
      img,
      i {
        @include circle;
        font-size: 60px;
      }
    }
    &.BupPoints-referred {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 16px 0;

      li {
        align-items: center;
        display: flex;
        gap: 12px;

        b {
          @include font(14px, 500);
        }
        p {
          @include font(13px);
        }
        i {
          color: $secondary;
        }
        small,
        p,
        span {
          margin-top: 0;
          padding: 0;
          border: none;
        }
      }
    }
  }
  small,
  p,
  span {
    margin-top: 0 !important;
    padding: 0 !important;
    border: none !important;
  }
  form.BupPoints-invite {
    margin-bottom: 8px;
    padding: 16px 20px 20px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;

    h6 {
      font-size: 16px;
      text-align: left;
    }
    div.BupPoints-copy {
      align-items: center;
      display: grid;
      grid-template-columns: 1fr auto;
      margin-top: -8px;
      user-select: none;
      gap: 0;

      a {
        @include row-center;
        @include font(13px, bold);
        background: rgba($primary, 0.11);
        border-bottom-right-radius: var(--bup-rounded);
        border-top-right-radius: var(--bup-rounded);
        border: 1px solid rgba($primary, 0.22);
        color: $primary;
        gap: 5px;
        padding: 6px 12px;
        transform: all 0.3s ease;

        &:hover {
          background: rgba($primary, 0.13);
        }
      }
      i {
        font-size: 16px;
      }
      input {
        margin: 0;
        border-radius: 4px;

        &:disabled {
          background: $bg-1 !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          pointer-events: auto !important;
        }
      }
      button {
        border-radius: 4px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-left: none;
        height: 40px;
      }
    }
    @media only screen and (max-width: $sm) {
      padding: 12px;
    }
  }
}
