div.BupTabs {
  &-root {
    position: relative;
    z-index: 10;

    button.MuiTab-root {
      color: var(--bup-text-primary);
      font-size: 12px;
      text-transform: none;

      i.BupIcon-root {
        font-size: 24px;
      }
      &:hover {
        color: var(--bup-text-secondary);
      }
      &.Bup-active {
        color: var(--bup-link-color);
      }
    }
    div.MuiTabs-scrollButtons {
      background: rgba(white, 0.9);
      color: black;
      height: 100%;
      margin: 0;
      opacity: 0.9;
      padding: 4px;
      position: absolute;
      width: 24px;
      z-index: 20;

      &.Mui-disabled {
        opacity: 0;
        width: 0;
      }
      &:hover {
        background: var(--bup-paper-background);
      }
      &:last-child {
        right: 0;
      }
      @media only screen and (max-width: $xs) {
        border-radius: 50%;
        border: 1px solid var(--bup-paper-border);
        height: 32px;
        width: 32px;
        margin: 16px 0;
      }
    }
    div.MuiTabScrollButton-root {
      display: none;

      @media only screen and (max-width: $lg) {
        display: flex;
      }
    }
    span.MuiTabs-indicator {
      background: var(--bup-link-color);
    }
  }
  &-filter {
    button.MuiTab-root {
      border-radius: var(--bup-border-radius);
      color: var(--bup-text-secondary);
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: normal;
      gap: 3px;
      justify-content: center;
      min-height: 64px;
      padding: 0px 16px;
      opacity: 0.9;

      i.BupIcon-root {
        color: inherit;
        font-size: 22px !important;
        margin: 0;
      }
      &:hover {
        color: var(--bup-icon-color-hover);
      }
      &.Bup-active {
        color: var(--bup-tab-color);
        opacity: 1;
        z-index: -2;
      }
      @media only screen and (max-width: $sm) {
        min-width: 0;
        padding: 0px 12px;
      }
    }
    span.MuiTabs-indicator {
      background: var(--bup-tab-background);
      border: 1px solid var(--bup-tab-border);
      border-radius: var(--bup-border-radius);
      height: 100%;
      z-index: -1;

      @media only screen and (max-width: $xs) {
        background: rgba($primary, 0.72);
        height: 2px;
      }
    }
    & + button.BupButton-root {
      border-color: var(--bup-button-border);
      border-radius: var(--bup-rounded);

      &:hover {
        background-color: var(--bup-input-background-hover) !important;
      }
    }
  }
  &-list {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row !important;
    padding: 2px 16px 0;

    h6.MuiTypography-h6 {
      font-size: 15px;
      font-weight: bold;
    }
    button.BupButton-root {
      box-shadow: none;
      border-radius: 18px;
      margin-left: 4px;

      &:not(.Bup-active) {
        background: transparent;
        color: $primary;

        &:hover {
          background: $bg-3;
        }
      }
    }
    @media only screen and (max-width: $sm) {
      padding: 2px 12px 0;
    }
  }
  &-nav {
    border-bottom: 1.5px solid var(--bup-paper-border);
    min-height: 40px;

    button.MuiTab-root {
      border-bottom: 1.5px solid transparent;
      border-radius: 0;
      font-size: 13.5px;
      font-weight: 500;
      min-height: 40px;
      padding: 6px 16px;

      i {
        display: none;
      }
      @media only screen and (max-width: $xs) {
        font-size: 12.5px;
      }
      &:hover {
        background-color: var(--bup-common-hover);
        border-color: var(--bup-input-border-hover);
      }
    }
    @media only screen and (max-width: $sm) {
      padding: 0;
    }
    @media only screen and (max-width: $xs) {
      border-radius: 0 !important;
      box-shadow: none !important;
      margin: 16px auto 0;
      width: fit-content;
    }
  }
}
article.BupTabs-panel {
  @media only screen and (max-width: $sm) {
    background: transparent;
    padding: 0;
  }
}
div.MuiPaper-root {
  &:has(div.BupTabs-root) {
    background: transparent;
    box-shadow: none;
    padding: 0 0 16px;

    @media only screen and (max-width: $xs) {
      box-shadow: none;
      border-bottom: none;
      background: transparent;
      margin: 0 !important;
      padding: 4px 8px 0px;
    }
  }
}
