div.BupEmpty-root {
  @include col-center;
  background: var(--bup-paper-background);
  border: 1px solid var(--bup-common-border);
  border-radius: var(--bup-border-radius);
  height: 240px;
  margin: 0;
  width: 100%;

  & {
    a.BupLink-root,
    button.BupButton-root {
      @include row-center;
      background: var(--bup-paper-background);
      border-radius: 16px;
      border: 1px solid var(--bup-input-border-hover);
      color: var(--bup-text-tertiary);
      font-size: 12px;
      font-weight: 600 !important;
      height: 32px;
      margin-top: 16px;
      padding: 3px 12px;

      &:hover {
        background: var(--bup-navbar-item-background-hover);
        border-color: var(--bup-input-border);
      }
      &:disabled {
        color: gray;
        opacity: 0.8;
      }
    }
    h6.MuiTypography-h6 {
      color: var(--bup-text-primary);
      font-size: 16px;
    }
    i.BupIcon-root {
      @include col-center;
      border-radius: 50%;
      color: var(--bup-icon-color);
      height: 32px;
      font-size: 32px;
      margin-bottom: 8px;
      pointer-events: none;
      width: 32px;
    }
    span.MuiCircularProgress-indeterminate {
      height: 32px !important;
      width: 32px !important;
    }
    span.MuiTypography-span {
      color: var(--bup-text-secondary);
      font-size: 13px;
      line-height: 1.33;
      opacity: 0.66;

      b {
        font-size: 13.5px;
      }
    }
  }
  &.BupEmpty-inset {
    background-color: var(--bup-common-background);
    border-radius: var(--bup-rounded);
    height: 150px;

    h6.MuiTypography-h6 {
      font-size: 14px;
    }
    i.BupIcon-root {
      height: 24px;
      width: 24px;
      font-size: 24px;
      margin-bottom: 4px;
    }
    span.MuiTypography-span {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: $xs) {
    margin-left: $padding-sm !important;
    margin-right: $padding-sm !important;
    width: calc(100% - 2 * #{$padding-sm});
  }
}
