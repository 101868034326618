nav.BupConsole-addons {
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  margin-top: -36px !important;
  width: 100%;

  button.BupButton {
    &-root {
      border-radius: 18px;

      &:not(.BupButton-icon) {
        background: transparent;
        color: var(--bup-link-color);

        &.MuiButton-outlinedInherit {
          border-radius: var(--bup-rounded);
          color: var(--bup-icon-color);
        }
        &:hover {
          background: var(--bup-input-background-hover) !important;
          color: var(--bup-link-color-hover);
        }
      }
    }
    &-icon {
      background: var(--bup-navbar-background);
      border: 1px solid var(--bup-chip-border);
      border-radius: 50%;
      color: var(--bup-chip-color);

      &.Bup-active {
        background: var(--bup-tab-background) !important;
        border: 1px solid var(--bup-tab-border) !important;
        color: var(--bup-tab-color) !important;
        pointer-events: auto;
      }
      &:hover {
        background: var(--bup-chip-background-hover);
        border-color: var(--bup-chip-border-hover);
      }
    }
  }
  div.MuiChip-root {
    background: transparent;
  }
  @media only screen and (max-width: $sm) {
    display: none;
  }
}
