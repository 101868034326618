div.BupModal-root {
  div.BupModal-wrapper {
    @include col-center;
    width: 100%;

    div.BupModal-title,
    div.BupModal-terms {
      display: none;
    }
  }
  ul.BupList-nav {
    @include paper-box;
    width: 400px;

    li {
      @include row-center;
      -webkit-user-select: none;
      background-color: var(--bup-paper-background);
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
      border-top: 1px solid var(--bup-divider);
      color: var(--bup-text-primary);
      font-family: var(--font-family-system);
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
      min-height: 48px;
      padding: 4px 8px;
      text-align: center;
      user-select: none;
      vertical-align: middle;

      &:first-child {
        border-top-left-radius: var(--bup-border-radius);
        border-top-right-radius: var(--bup-border-radius);
        border: none;
      }
      &:last-child {
        border-bottom-left-radius: var(--bup-border-radius);
        border-bottom-right-radius: var(--bup-border-radius);
      }
      &:hover:not(.BupList-avatar) {
        background: var(--bup-navbar-item-background-hover);
        cursor: pointer;
      }
      &.BupList-avatar {
        @include col-center;
        padding: 15px 15px 12px;

        span {
          @include clamp(1);
          font-size: 21px;
          margin-top: 10px;
          white-space: none;
        }
        small {
          font-size: 13px;
          opacity: 0.66;
        }
        span.BupAvatar-root,
        div.BupAvatar-root {
          cursor: default !important;
          border-radius: var(--bup-border-radius);
          height: 65px;
          width: 65px;

          @media only screen and (max-width: $xs) {
            height: 50px !important;
            width: 50px !important;
          }
        }
      }
      a.BupLink-root {
        color: var(--bup-link-color);
        font-weight: 400;

        &:hover {
          color: var(--bup-link-color-hover);
        }
      }
    }
  }
  @media only screen and (max-width: $xs) {
    div.BupModal-wrapper {
      background-color: var(--bup-input-background);
      border-radius: var(--bup-border-radius);
      padding: 12px 6px 12px;
      gap: 8px;

      div.BupModal-title {
        align-items: center;
        display: flex;
        gap: 8px;

        h6 {
          color: var(--bup-text-secondary);
          font-size: 18px;
          font-weight: 400;
        }
        svg {
          height: 20px;
          width: 20px;

          * {
            fill: var(--bup-text-secondary);
          }
        }
      }
      div.BupModal-terms {
        display: flex;
        justify-content: center;

        button {
          color: var(--bup-text-tertiary);
          font-weight: 400;
          height: 24px;
          min-width: 0;
        }
      }
    }
    ul.BupList-nav {
      border-radius: var(--bup-border-radius);
      width: 100%;
    }
  }
}
