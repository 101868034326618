div.BupMain-loading {
  @include center;
  height: calc(100vh - #{$navbar-y});
  gap: 16px;

  h1 {
    text-align: center;
    font-size: 24px;
    width: 100%;
  }
  svg {
    height: 80px;
    opacity: 0.9;
    -webkit-animation: spin 5s linear infinite;
    -moz-animation: spin 5s linear infinite;
    animation: spin 5s linear infinite;
  }
}
