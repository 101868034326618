div.BupForm {
  &-field {
    input,
    select,
    textarea {
      background: var(--bup-input-background);
      border: 1px solid var(--bup-input-border-hover);
      border-radius: 20px;
      height: 40px;
      margin-top: 6px;
      outline: none;
      padding: 4px 16px;
      width: 100%;

      &::placeholder {
        color: var(--bup-primaryDark-main);
      }
      &:hover {
        background: var(--bup-input-background-hover);
        border-color: var(--bup-input-border);
      }
      &:focus {
        border-color: var(--bup-link-color);
      }
      &:disabled {
        pointer-events: none;
        user-select: none;
      }
    }
    textarea {
      border-radius: var(--bup-rounded);
      resize: none;
      padding-top: 12px;
      height: 256px;
    }
    i.BupIcon-root {
      color: var(--bup-icon-color);
    }
    &:has(.BupForm-error) {
      input,
      select {
        border-color: $danger !important;
        margin-bottom: 4px;
      }
      small {
        color: $danger !important;
        font-size: 12px;
      }
    }
    &.BupInput-search {
      height: 64px;

      div.BupForm-inline {
        height: inherit;

        input {
          height: inherit;
          margin: 0;
          padding-left: 64px !important;
          font-size: 14px;
        }
        span.BupForm-prefix {
          left: 21px;
          top: 21px;

          i.BupIcon-root {
            font-size: 22px;
          }
        }
      }
    }
  }
  &-inline {
    display: block;
    position: relative;
    width: 100%;

    span.BupForm {
      &-prefix {
        display: flex;
        align-items: center;
        font-size: 14px;
        left: 16px;
        position: absolute;
        top: 17px;

        i.BupIcon-root {
          font-size: 18px;
        }
      }
      &-password {
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 17px;

        i.BupForm-check {
          color: var(--bup-success-color);
          font-size: 18px;
        }
      }
    }
    &:has(span.BupForm-prefix) {
      input {
        padding-left: 48px;
      }
    }
  }
}
