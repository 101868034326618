button.BupButton-root {
	border-radius: 18px;
	cursor: pointer;
	font-weight: 500;
	height: 36px;
	text-transform: none;
	white-space: nowrap;
	width: fit-content;

	&.MuiButton {
		&-contained {
			&.MuiButton {
				&-containedPrimary {
					background-color: var(--bup-button-primary);

					&:hover {
						background-color: var(--bup-button-primary-hover);
					}
				}
				&-containedInherit {
					background: var(--bup-input-background-hover);
					border: 0.5px solid var(--bup-input-border-hover);
					color: var(--bup-text-primary);

					&:hover {
						background: var(--bup-input-background);
						border-color: var(--bup-input-border);
					}
				}
				&-containedPremium {
					background-color: var(--bup-premium-button-background) !important;
					border: 0.5px solid var(--bup-premium-button-border) !important;
					color: var(--bup-premium-button-color) !important;

					&:hover {
						background: var(--bup-premium-button-background-hover) !important;
						border-color: var(--bup-premium-button-border-hover) !important;
					}
				}
			}
			&.BupButton {
				&-success {
					background: var(--bup-success-whatsapp);
				}
				&-danger {
					background: $danger;
				}
			}
			&:disabled {
				background: var(--bup-disabled-button-background) !important;
				border: 0.75px solid var(--bup-disabled-button-border) !important;
				color: var(--bup-disabled-button-color) !important;
				cursor: not-allowed;
				pointer-events: none;
			}
			&:has(.fa-whatsapp) {
				@include background($success);
				color: white;

				&:hover {
					@include background(darken($success, 4%));
					color: white;
				}
			}
		}
		&-outlined,
		&-text {
			&.MuiButton {
				&-textPrimary,
				&-outlinedPrimary {
					border-color: var(--bup-link-border) !important;
					color: var(--bup-link-color);

					&:hover {
						background-color: var(--bup-link-background-hover);
						border-color: var(--bup-link-border-hover) !important;
						color: var(--bup-link-color-hover);
					}
				}
				&-textSuccess,
				&-outlinedSuccess {
					border-color: var(--bup-success-border);
					color: var(--bup-success-color);
				}
				&-outlinedInherit {
					background: var(--bup-paper-background);
					border-color: var(--bup-input-border-hover);
					color: var(--bup-text-tertiary);

					&:hover {
						background: var(--bup-navbar-item-background-hover);
						border-color: var(--bup-input-border);
					}
					&:disabled {
						color: gray;
						opacity: 0.8;
					}
				}
			}
			&.BupButton {
				&-success {
					border-color: $success;
					color: $success;
				}
				&-danger {
					border-color: $danger;
					color: $danger;
				}
			}
		}
		&-text {
			border-radius: var(--bup-rounded);
		}
		&-fullWidth {
			height: 36px;
			width: 100%;
		}
	}
	&.MuiButton,
	&.MuiIconButton {
		&-sizeSmall {
			border-radius: 16px;
			font-size: 12px;
			height: 32px;

			i.BupIcon-root {
				font-size: 15px;
				font-weight: 500;
			}
		}
		&-sizeMedium {
			border-radius: 18px;
			font-size: 13px;
			height: 36px;

			i.BupIcon-root {
				font-size: 16px;
				font-weight: 500;
			}
		}
		&-sizeLarge {
			border-radius: 20px;
			font-size: 14px;
			height: 40px;

			i.BupIcon-root {
				font-size: 17px;
				font-weight: 500;
			}
		}
	}
	&.MuiIconButton {
		&-root {
			aspect-ratio: 1 !important;
		}
		&-sizeSmall {
			height: 32px !important;
			width: 32px !important;

			i.BupIcon-root {
				font-size: 16px !important;
			}
		}
		&-sizeMedium {
			height: 36px !important;
			width: 36px !important;

			i.BupIcon-root {
				font-size: 18px !important;
			}
		}
		&-sizeLarge {
			height: 40px !important;
			width: 40px !important;

			i.BupIcon-root {
				font-size: 20px !important;
			}
		}
	}
	&.MuiButtonBase-root {
		&.BupButton {
			&-icon {
				@include circle;
				border-radius: 50%;
				width: auto;

				span.MuiBadge-badge {
					max-height: 18px;
					margin-right: -4px;
					margin-top: -4px;
				}
			}
			&-inherit {
				@include background($bg-0);
				background: $bg-0;
				color: $dark;

				&:hover {
					background: darken($bg-0, 5%);
					color: $dark;
				}
			}
			&-redirect {
				background: var(-bup-common-backdrop) !important;
				border-radius: 18px;
				border: 1px solid var(--bup-button-border) !important;
				box-shadow: none;
				color: var(--bup-button-color) !important;
				font-size: 13px;
				font-weight: bold;
				height: 36px;
				padding: 4px 24px;

				&:hover {
					border-color: var(--bup-button-border-hover) !important;
				}
				&.Bup-pending {
					border-color: $danger !important;
					border-width: 2px !important;
				}
			}
			&-square {
				border-radius: var(--bup-rounded) !important;
			}
			&-dark {
				background-color: $color-8 !important;
				color: $bg-0 !important;

				&:hover {
					background-color: $color-9 !important;
					color: $bg-0;
				}
			}
			&-light {
				@include background($color-5);
				color: $bg-0;

				&:hover {
					@include background($color-6);
					color: $bg-0;
				}
			}
		}
		&.Bup-active {
			background: var(--bup-tab-background) !important;
			border-color: var(--bup-tab-border) !important;
			color: var(--bup-tab-color) !important;
			cursor: wait;
		}
		&.Bup-loading {
			pointer-events: none;
			cursor: default;
			filter: grayscale(0.33);
			opacity: 0.9;
		}
		&.Bup-disabled {
			filter: grayscale(0.5);
			pointer-events: none;
			opacity: 0.5;
		}
	}
	&.BupButton {
		&-contained {
			background: var(--bup-icon-background);
			border-radius: 20px;
			border: 1px solid var(--bup-icon-border);
			color: var(--bup-icon-color);
			font-size: 20px;
			transition: all 0.2s ease;

			i.BupIcon-root {
				color: inherit;
				font-size: 20px;
			}
			&:hover {
				background: var(--bup-icon-background-hover);
				border-color: var(--bup-icon-border-hover);
				color: var(--bup-icon-color-hover);
			}
			&.Bup-active {
				border: none;
				cursor: pointer !important;
				pointer-events: auto;
			}
		}
		&-outlined {
			border: 1px solid var(--bup-common-border);

			&.MuiIconButton-colorPrimary {
				border-color: var(--bup-link-border);
			}
			&.MuiIconButton-colorSuccess {
				border-color: var(--bup-success-border);
			}
			&.MuiIconButton-colorDanger {
				border-color: var(--bup-danger-border);
			}
		}
	}
	&.BupLink-root {
		&.Bup-inline {
			display: inline;
			height: fit-content;
			font-weight: 500;
			color: $tertiary;
			padding: 0;
		}
	}
}
div.BupButton-group {
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 12px;

	button.BupButton-root {
		border-radius: var(--bup-rounded) !important;
		border-width: 1px;

		i.BupIcon-root {
			font-size: 16px !important;
		}
		&.MuiButton-outlined {
			i.BupIcon-root {
				color: var(--bup-link-color);
			}
		}
	}
}
h6.BupButton-title {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.33;
}
