div.BupSubscription-selector {
  .BupSubscription {
    &-periodicity {
      @include paper-box;
      padding: 32px 24px;
      text-align: center;

      h2.MuiTypography-h2 {
        font-size: 28px;
        font-weight: 400;
      }
      span.MuiTypography-p {
        font-size: 15px;
        line-height: 1.25;
        color: var(--bup-text-secondary);
      }
      span.MuiTypography-span {
        font-size: 14px;
        line-height: 20px;
        color: var(--bup-text-secondary);

        a {
          color: var(--bup-link-color);
        }
      }
      ul {
        @include row-center;
        background-color: var(--bup-input-background);
        border-radius: 22px;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        padding: 4px;
        gap: 4px;
        width: fit-content;

        button.BupButton-root {
          align-items: center;
          background-color: var(--bup-input-background);
          border-color: transparent;
          border-radius: 24px;
          border-width: 0.75px;
          display: flex;
          flex-direction: column;
          gap: 2px;
          height: 40px;
          width: 160px;

          &:hover {
            background-color: var(--bup-input-background-hover);
            border-color: var(--bup-input-border-hover);
          }
          a {
            color: var(--bup-link-color);
          }
          b {
            line-height: 13px;
            font-weight: 500;
          }
          small {
            font-weight: 400;
            line-height: 13px;
          }
        }
      }
      @media only screen and (max-width: $sm) {
        background-color: white;
        text-align: left;

        h2.MuiTypography-h2 {
          font-size: 23px;
          font-weight: 400;
        }
      }
      @media only screen and (max-width: $xs) {
        border-radius: 0;
        border-width: 0.75px;
        padding: 24px 8px 32px;
        text-align: center;

        span.MuiTypography-p {
          font-size: 14.5px;
        }
      }
    }
    &-options {
      align-items: stretch;
      display: grid;
      gap: 16px;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(3, 1fr);

      @media only screen and (max-width: $lg) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (max-width: $md) {
        grid-template-columns: 1fr;
        margin: 12px auto 0;
      }
      @media only screen and (max-width: $xs) {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}
