div,
img {
	&.BupImage {
		&-root {
			@include image;
			background: var(--bup-paper-background);
			border-radius: var(--bup-border-radius);
			border: 0.25px solid var(--bup-paper-border);

			&.BupSkeleton-image {
				&[src=""],
				&:not([src]) {
					content: url("data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==") !important;
				}
			}
		}
		&-bg {
			background-position: center;
			background-size: cover;
			border-radius: var(--bup-border-radius);
			padding: 12px;

			&:has(.BupImage-filter) {
				padding: 0;

				.BupImage-filter {
					background: rgba(black, 0.08);
					border-radius: var(--bup-border-radius);
					height: 100%;
				}
			}
		}
	}
}
