div.BupDrawer-root {
  & > div.MuiDrawer-paper {
    background-color: var(--bup-paper-background);
    color: var(--bup-text-primary);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 20px;

    div.BupDrawer-puller {
      background-color: var(--bup-input-background-hover);
      border-radius: 4px;
      height: 6px;
      margin: 20px auto 16px;
      width: 48px;
    }
    ul.BupDrawer-content {
      li.MuiMenuItem-root,
      button {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        border-radius: 0;
        font-size: 14.5px !important;
        font-weight: 400;
        padding: 6px 20px;
        height: 48px;
        width: 100%;

        i.BupIcon-root {
          @include row-center;
          border-radius: 16px;
          color: var(--bup-icon-color);
          font-size: 20px;
          margin-right: 16px;
          width: 20px;
        }
        span.MuiButton-startIcon {
          margin: 0;
        }
        s {
          text-decoration: none;
          margin-left: 4px;
          margin-right: 4px;
        }
      }
    }
  }
}
