div.BupEvent-filter {
  @include slide-scroll;

  & {
    div.MuiButton-root {
      font-size: 13px;
      font-weight: 500;

      i.BupIcon-root {
        font-size: 18px;
      }
    }
    div.BupChip-root {
      background: var(--bup-chip-background);
      border: 1px solid var(--bup-chip-border);
      color: var(--bup-chip-color);
      transition: all 0.2s ease;

      &:hover {
        background: var(--bup-chip-background-hover);
        border-color: var(--bup-chip-border-hover);
        color: var(--bup-chip-color-hover);
      }
      &.Bup-selected {
        pointer-events: none;
        background: var(--bup-chip-background-active);
        border-color: var(--bup-chip-border-active);
        color: var(--bup-chip-color-active);
      }
    }
  }
  div.BupEvent-actions {
    border-right: 1px solid var(--bup-divider);
    margin-right: 16px;
    padding-right: 16px;

    div.MuiChip-root {
      background: var(--bup-button-background);
      border: 1px solid var(--bup-button-border);
      border-radius: 16px;
      color: var(--bup-text-primary);
      cursor: pointer;

      &:hover {
        border-color: var(--bup-button-border-hover);
      }
      i.BupIcon-root {
        color: inherit;
        font-size: 18px;
        margin-left: 8px;
      }
    }
    @media only screen and (max-width: $xs) {
      border: none;
      gap: 4px;
      margin-right: 0;
      padding-right: 0;

      div:has(.MuiButton-containedPrimary) {
        display: block;
        width: 100%;

        button {
          min-width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: $xs) {
    flex-direction: column;
    margin-top: 0 !important;
    padding: 12px $padding-sm 0;
    overflow-x: hidden;

    div.BupEvent-category {
      @include slide-scroll;
      margin-top: 12px;
    }
  }
}
