section.BupLogin-view {
	margin: 32px 0;
	padding: 16px;

	& {
		h1.MuiTypography-h1,
		h2.MuiTypography-h2 {
			font-size: 36px;
			line-height: 1.15;
			font-weight: 400;
		}
		h2.MuiTypography-h2 {
			font-weight: 300 !important;

			b {
				font-weight: 400 !important;
			}
		}
		button.MuiButton-root {
			&:has(.fa-whatsapp) {
				width: 256px;
			}
		}
		div.BupImage-root,
		img.BupImage-root {
			@include video;
			@include row-center;

			&:has(button.BupButton-icon) {
				cursor: pointer;

				&:hover {
					button.BupButton-icon {
						background: rgba(black, 0.66);
					}
				}
			}
			button.BupButton-icon {
				@include landscape;
				background: rgba(black, 0.5);
				color: white;
				border-radius: 20px;
				width: 80px;

				i.BupIcon-root {
					font-size: 32px !important;
				}
			}
		}
		span.MuiTypography-p {
			font-size: 15px;

			b {
				color: var(--bup-text-tertiary);
			}
		}
		small.MuiTypography-body1 {
			font-size: 13.5px;
		}
	}
	form.BupForm-root {
		background-color: var(--bup-paper-background);
		border-radius: 24px;
		border: 1px solid var(--bup-paper-border);
		margin: 8px auto 0;
		min-width: 500px;
		padding: 48px 32px 40px;
		text-align: center;
		width: $xs;

		& {
			.BupLink-href,
			.BupLink-nav {
				font-size: 14px;

				&:hover {
					color: var(--bup-button-tertiary);
				}
			}
			.BupLink-nav {
				color: var(--bup-link-color);
				font-weight: 400;
				height: 20px;
			}
			button.MuiButton-fullWidth {
				@include background($dark);
				color: white;
				height: 40px;

				&:hover {
					@include background(lighten($dark, 4%));
					color: white;
				}
			}
			div.BupStack-root {
				margin-top: 32px;
				text-align: left;
			}
			div.BupForm-group {
				display: grid;
				grid-template-columns: 1fr;
				gap: 12px;

				&.gr-2 {
					grid-template-columns: repeat(2, 1fr);

					@media only screen and (max-width: $sm) {
						grid-template-columns: 1fr;
						gap: 0;

						label.BupLabel-empty {
							display: none;
						}
						div.BupForm-field:last-child:not(:has(label.BupLabel-empty)) {
							margin-top: 16px;
						}
					}
					&:has(button) {
						margin-top: 32px;
					}
				}
				&.gr-3 {
					grid-template-columns: repeat(3, 1fr);

					@media only screen and (max-width: $sm) {
						gap: 4px;
					}
					@media only screen and (max-width: $x1) {
						grid-template-columns: 1fr;
						gap: 0;
					}
				}
			}
			label.BupForm-label,
			span.MuiTypography-root {
				font-size: 14px;

				.BupLink-root {
					color: var(--bup-link-color);
					font-weight: 500;
					padding: 6px 8px;
				}
			}
			span.MuiTypography-root {
				border-top: 1px solid var(--bup-divider);
				padding-top: 32px;
				margin-top: 32px;
			}
			p.MuiTypography-body1 {
				font-size: 14px;
			}
			small.BupLogin-caption {
				display: block;
				font-size: 12px;
				max-width: 380px;

				a.BupLink-href {
					font-size: inherit;
					font-weight: bold;
					line-height: 1.5;

					&:not(:hover) {
						color: lighten($dark, 16%);
					}
				}
				@media only screen and (max-width: $xs) {
					br {
						display: none;
					}
				}
			}
		}
		&.BupForm-info {
			background-color: transparent;
			border: none;
			padding: 0;
			text-align: left;
			width: 100%;

			h1.MuiTypography-h1 {
				font-size: 24px;
			}
			@media only screen and (max-width: $lg) {
				max-width: 100%;
				width: 100%;
			}
			@media only screen and (max-width: $md) {
				padding: 0 4px;
			}
		}
		@media only screen and (max-width: $xl) {
			min-width: 100%;
			width: 100%;
		}
		@media only screen and (max-width: $lg) {
			max-width: calc((#{$xs} + #{$sm}) / 2);
			min-width: 0;
			margin: 12px auto 0;
		}
		@media only screen and (max-width: $md) {
			max-width: $xs;
		}
		@media only screen and (max-width: $sm) {
			padding: 32px 16px;
		}
		@media only screen and (max-width: $xs) {
			span.MuiTypography-root {
				padding-top: 24px;
				margin-top: 24px;
			}
		}
	}
	ul.BupLogin-links {
		@include font(12px);
		display: flex;
		justify-content: center;
		margin: 15px auto 0;
		gap: 10px;

		li {
			white-space: nowrap;

			&:not(:first-child):before {
				content: "|";
				margin-right: 10px;
			}
			a {
				&:hover {
					color: var(--bup-button-tertiary);
				}
			}
		}
	}
	@media only screen and (max-width: $lg) {
		margin: 0;
		padding: 20px 12px;

		div.MuiGrid-root > div.BupStack-root {
			justify-content: center;
			min-height: calc(100vh - #{$navbar-y} * 0.9 - 48px);
			padding-bottom: 32px;
		}
	}
}
