/* ASPECT-RATIO */

@mixin circle {
  aspect-ratio: 1;
  border-radius: 50%;
  height: auto;
}

@mixin square {
  aspect-ratio: 1;
  height: auto;
}

@mixin landscape {
  aspect-ratio: 40 / 27;
  height: auto;
  width: 100%;
}

@mixin video {
  aspect-ratio: 16 / 9;
  height: auto;
  width: 100%;
}

@mixin reel {
  aspect-ratio: 9 / 16;
  height: 100%;
  width: auto;
}

@mixin portrait {
  aspect-ratio: 27 / 40;
  height: auto;
  width: 100%;
}

@mixin banner {
  aspect-ratio: 21 / 9;
  height: auto;
  width: 100%;
}

@mixin blur($blur-amount) {
  backdrop-filter: blur($blur-amount);
  -webkit-backdrop-filter: blur($blur-amount);
}

/* DISPLAY */

@mixin d-none {
  display: none !important;
}

@mixin d-block {
  display: block !important;
}

@mixin d-inline {
  display: inline !important;
}

@mixin d-flex {
  display: flex !important;
}

@mixin center($direction: row, $justify: center, $align: center) {
  align-items: $align;
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
}

@mixin col-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin row-center {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* FILTER */

@mixin blur($blur: 20px) {
  backdrop-filter: blur($blur);
}

@mixin grayscale($ratio: 1) {
  -webkit-filter: grayscale($ratio);
  -moz-filter: grayscale($ratio);
  -ms-filter: grayscale($ratio);
  -o-filter: grayscale($ratio);
  filter: grayscale($ratio);
}

/* TEXT FORMAT */

@mixin clamp($lines: 3) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin disabled {
  cursor: default;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

@mixin font($size: 16px, $weight: normal) {
  font-size: $size;
  line-height: $size + 6px;
  font-weight: $weight;
}

@mixin nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* COLOR FILL */

@mixin background($bg: $primary, $color: inherit) {
  background: $bg;
  //background: linear-gradient(90deg, $bg 0%, darken($bg, 12%) 100%);
  color: $color;
}

@mixin bg-blue {
  background-color: rgba($primary, 0.11);
  border: 1px solid rgba($primary, 0.22);
  border-radius: var(--bup-border-radius);
  color: darken($primary, 8%);

  &:hover {
    background-color: rgba($primary, 0.13);
  }
}
@mixin bg-green {
  background-color: rgba($success, 0.11);
  border: 1px solid rgba($success, 0.22);
  border-radius: var(--bup-border-radius);
  color: darken($success, 8%);

  &:hover {
    background-color: rgba($success, 0.13);
  }
}
@mixin bg-red {
  background-color: rgba($danger, 0.11);
  border: 1px solid rgba($danger, 0.22);
  border-radius: var(--bup-border-radius);
  color: darken($danger, 8%);

  &:hover {
    background-color: rgba($danger, 0.13);
  }
}

@mixin fill($color: $primary) {
  fill: $color;
  fill: linear-gradient(90deg, $color 0%, darken($color, 10%) 100%);
}

@mixin logo($color: $primary) {
  background: -webkit-linear-gradient(left, $color, darken($color, 15%));
  background: -o-linear-gradient(right, $color, darken($color, 15%));
  background: -moz-linear-gradient(right, $color, darken($color, 15%));
  background: linear-gradient(to right, $color, darken($color, 15%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &::selection {
    background: inherit;
  }
}

@mixin text($color: $primary) {
  background: -webkit-linear-gradient(left, $color, darken($color, 25%));
  background: -o-linear-gradient(right, $color, darken($color, 25%));
  background: -moz-linear-gradient(right, $color, darken($color, 25%));
  background: linear-gradient(to right, $color, darken($color, 25%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &::selection {
    background: inherit;
  }
}

@mixin image() {
  object-fit: cover;
  object-position: center;
}

/* GRID */

@mixin box-shadow {
  border-radius: var(--bup-border-radius);
  box-shadow: 1px 1px 1px 1px rgba($text, 0.1);
}

@mixin inset-shadow {
  border-radius: var(--bup-border-radius);
  box-shadow: inset 1px 1px 1px 1px rgba($text, 0.1);
}

@mixin fullscreen {
  min-height: 100vh;
  width: 100%;
}

@mixin grid($cols: 3, $gap: 5px) {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
  grid-gap: 16px;

  & > * {
    @include col-center;
    @include bg-blue;
    @include square;

    font-size: 13px;
    gap: 3px;
    text-transform: uppercase;
    width: 100%;

    img {
      @include image;
      cursor: pointer;
      height: 100%;
      pointer-events: all;
      user-select: none;
    }
  }
}

@mixin slide-scroll {
  overflow-x: scroll;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:window-inactive {
    display: none;
  }
}
@mixin paper-box {
  background-color: var(--bup-paper-background);
  border-radius: var(--bup-border-radius);
  border: 1px solid var(--bup-common-border);
  box-shadow: none;
  color: var(--bup-paper-color);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  @media only screen and (max-width: $xs) {
    box-shadow: none !important;
    border-radius: 0;
  }
}
@mixin responsive {
  @include developer;
  padding-left: $padding-lg !important;
  padding-right: $padding-lg !important;

  @media only screen and (max-width: $md) {
    padding-left: $padding-md !important;
    padding-right: $padding-md !important;
  }
  @media only screen and (max-width: $xs) {
    padding-left: $padding-sm !important;
    padding-right: $padding-sm !important;
  }
}
@mixin developer {
  &.dev {
    background-color: $danger !important;
    border-color: rgba(white, 0.33) !important;
    color: white !important;

    @media only screen and (max-width: $lg) {
      background-color: $primary !important;
    }
    @media only screen and (max-width: $md) {
      background-color: $success !important;
    }
    @media only screen and (max-width: $sm) {
      background-color: $tertiary !important;
    }
    @media only screen and (max-width: $xs) {
      background-color: orange !important;
    }
  }
}
