section.BupUser-interests {
  ul.BupInterests-selector {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px 12px;

    li {
      @include font(14px);
      align-items: center;
      border: 1px solid $bg-6;
      border-radius: 18px;
      cursor: pointer;
      display: grid;
      grid-template-columns: 50px 1fr auto;
      height: 36px;
      padding: 5px 15px;

      i {
        font-size: 18px;
        width: 18px;
      }
      &.Bup-selected {
        background: var(--bup-success-background);
        border-color: var(--bup-success-border);
        color: var(--bup-success-color);
      }
      &.Bup-disabled {
        opacity: 0.66;
      }
      &:not(.Bup-selected):hover {
        background: var(--bup-common-background);
        border-color: var(--bup-tab-border);
        color: var(--bup-tab-color);
        cursor: pointer;
      }
      &.BupInterests-category {
        background-color: var(--bup-icon-background);
        border-color: var(--bup-icon-border);
        border-radius: var(--bup-rounded);
        color: var(--bup-icon-color);
        font-weight: 500;
        grid-column: span 2;
        grid-template-columns: 1fr auto;

        &.Bup-disabled {
          opacity: 1;
          pointer-events: none;
        }
        &:hover {
          background: var(--bup-link-background-hover);
          border-color: var(--bup-input-border-hover);
          color: inherit;
        }
      }
      &.BupInterests-divider {
        grid-column: span 4;
        visibility: hidden;
        height: 0.5px;
      }
    }
    & + button.MuiButton-root {
      max-width: calc(25% - 8px);
      width: 100%;
    }
    @media only screen and (max-width: $lg) {
      grid-template-columns: repeat(3, 1fr);
      & + button.BupButton-root {
        max-width: calc(33.3% - 8px);
      }
    }
    @media only screen and (max-width: $md) {
      grid-template-columns: repeat(2, 1fr);
      & + button.BupButton-root {
        max-width: calc(50% - 8px);
      }
    }
    @media only screen and (max-width: $sm) {
      display: flex;
      flex-direction: column;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        grid-template-columns: 30px 1fr auto;
        width: 100%;

        i {
          margin-right: 0;
        }
        span {
          overflow: hidden;
          white-space: nowrap;
        }
      }
      & + button.BupButton-root {
        max-width: 100%;
      }
    }
    @media only screen and (max-width: $xs) {
      flex-direction: column;

      li {
        width: 100%;
      }
    }
  }
  span.MuiTypography-p {
    line-height: 1.33;

    @media only screen and (max-width: $md) {
      b {
        display: block;
        margin-top: 16px;
      }
    }
  }
}
