.BupDocument-media,
img.BupSkeleton-image {
  align-items: flex-end;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: none;
  display: flex;
  height: 360px;
  justify-content: flex-end;

  button.BupButton-icon {
    background-color: rgba(black, 0.5);
    color: rgba(white, 0.8);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    button.BupButton-icon {
      opacity: 1;
    }
  }
  &:has(button.BupButton-icon) {
    flex-direction: column;
    justify-content: space-between;
  }

  @media only screen and (max-width: $md) {
    height: 288px;
  }
  @media only screen and (max-width: $sm) {
    height: 256px;
  }
  @media only screen and (max-width: $xs) {
    border-radius: 0;
    border: none !important;
    height: 224px;
  }
}
img.BupSkeleton-image {
  background-color: var(--bup-skeleton);
  border-color: var(--bup-skeleton) !important;
}
