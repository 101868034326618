div.BupDocument-description {
  & {
    p,
    span.MuiTypography-p {
      font-size: 14.5px;
      line-height: 1.33;
      margin: 12px 0 0;
      white-space: pre-wrap;
      color: var(--bup-text-secondary);

      &.Bup-clamp {
        @include clamp(5);
      }
      a {
        color: var(--bup-text-tertiary);

        &:hover {
          text-decoration: underline;
        }
      }
      b {
        font-weight: 500;
      }
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    a.BupLink-root {
      color: var(--bup-link-color);
      display: block;
      font-size: 13.5px;
      font-weight: bold;
    }
    button.BupButton-root {
      margin-top: 12px;
    }
  }
  ul.BupEvent-checklist {
    display: flex;
    flex-direction: column;

    li {
      align-items: center;
      display: flex;
      gap: 16px;
      font-size: 14.5px;

      &:hover i.BupIcon-root {
        &.Bup-check {
          background-color: var(--bup-icon-background);
        }
        &.Bup-uncheck {
          background-color: var(--bup-danger-background);
        }
      }
    }
    i.BupIcon-root {
      @include col-center;
      border-radius: 12px;
      border: 1px solid var(--bup-common-border);
      font-size: 16px;
      font-weight: 600;
      height: 24px;
      transition: all 0.2s ease;
      width: 40.5px;

      &.Bup-check {
        border: 1px solid var(--bup-common-border);
        color: var(--bup-icon-color);
      }
      &.Bup-uncheck {
        color: var(--bup-danger-color);
        opacity: 0.8;
      }
      @media only screen and (max-width: $xs) {
        border: none !important;
        font-size: 18px;
        width: 20px;
      }
    }
  }
  div.BupEvent-itinerary {
    gap: 0;
    margin-top: 4px;

    ol {
      align-items: flex-start;
      border-left: 3px solid transparent;
      display: flex;
      margin-left: 8px;
      gap: 16px;

      span.MuiTypography-p {
        margin-top: 0;
      }
      i.BupIcon-root {
        background-color: var(--bup-paper-background);
        border-radius: 12px;
        color: var(--bup-icon-color);
        font-size: 20px;
        margin-left: -11.5px;
      }
      &:hover {
        i.BupIcon-root {
          color: var(--bup-link-color);
        }
      }
      &:not(:last-child) {
        border-color: var(--bup-icon-color);
        padding-bottom: 24px;
      }
    }
  }
  div.BupEvent-complement {
    background-color: var(--bup-common-background);
    border: 0.5px solid var(--bup-paper-border);
    border-radius: var(--bup-rounded);
    padding: 12px;

    * {
      margin: 0;
    }
    span.MuiTypography-p {
      font-size: 14px;
      color: var(--bup-text-primary);
      line-height: 1.4;
    }
    @media only screen and (max-width: $xs) {
      padding: 12px 8px;

      span.Typography-p {
        font-size: 13.5px;
      }
    }
  }
  div.BupEvent-organizer {
    span.MuiTypography-p {
      align-items: center;
      color: var(--bup-text-tertiary);
      display: flex;
      font-size: 14px;

      b {
        color: var(--bup-button-tertiary);
      }
    }
    svg {
      height: 14px;
      margin-left: 4px;
      margin-bottom: 2px;
    }
  }
}
