div.BupHelp-faq {
  border-radius: var(--bup-border-radius);
  padding: 8px 0 0;

  div.MuiGrid-root {
    h4.MuiTypography-h4 {
      color: var(--bup-text-primary);
      font-size: 16px !important;
      font-weight: 500;
    }
    span.MuiTypography-p {
      color: var(--bup-text-secondary);
      font-size: 13.5px !important;
      line-height: 1.5;
      margin-top: 8px;

      a {
        color: var(--bup-link-color);
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
      q {
        color: var(--bup-text-primary);
        font-weight: 500;
      }
    }
    i.BupIcon-root {
      color: var(--bup-icon-color);
      display: none;
    }
    @media only screen and (max-width: $sm) {
      h4.MuiTypography-h4 {
        font-size: 14.5px !important;
      }
      span.MuiTypography-p {
        &.BupText-collapse {
          display: none;
        }
      }
      i.BupIcon-root {
        display: block;
      }
    }
  }
  @media only screen and (max-width: $sm) {
    padding-bottom: 4px;
  }
}
