footer.BupConsole-footer {
	border-top: 1px solid var(--bup-divider);
	margin-top: 32px !important;
	padding-top: 16px;
	color: inherit;

	button.MuiTypography-root {
		background: transparent;
		border: none;
		color: inherit;
		cursor: pointer;
		font-size: 12px;
		font-weight: normal;
		padding: 0 12px;
		height: fit-content;
		white-space: nowrap;

		&:hover {
			color: var(--bup-link-color);
		}
	}
	span.MuiTypography-root {
		@include col-center;
		color: inherit;
		font-size: 12px;
		height: 24px;
		padding: 0 12px;
		white-space: nowrap;
	}
	&.mt-2 {
		margin-top: 16px !important;
	}
	&.mt-4 {
		margin-top: 32px !important;
	}
	&.Bup-fixed {
		position: fixed;
		bottom: 16px;
		left: 0;
		width: 100%;
	}
	@media only screen and (max-width: $xs) {
		margin: 16px 0 4px;
		display: none;
	}
}
