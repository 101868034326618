div.BupTopbar-tools {
	position: relative;

	div.MuiStack-root {
		@include paper-box;
		background: var(--bup-paper-background);
		border-radius: var(--bup-border-radius);
		border: 0.75px solid var(--bup-paper-border);
		color: var(--bup-paper-color);
		height: fit-content;
		padding: 16px;
		position: absolute;
		right: 0;
		top: 36px;
		width: calc(302px + 3 * #{16px});

		button.MuiButton-fullWidth {
			display: none;
		}
		h6.MuiTypography-h6 {
			@include font(16px, bold);
			margin-bottom: 8px;
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			gap: calc(16px / 2);

			li,
			.BupLink-nav {
				@include col-center;
				@include landscape;

				background: $bg-1;
				border: 1.5px solid $bg-2;
				border-radius: var(--bup-border-radius);
				color: inherit;
				cursor: pointer;
				font-size: 12px;
				gap: calc(16px / 8);
				position: relative;
				width: 100px;

				i.BupIcon-root {
					font-size: 21px;
				}
				svg.BupLogo-root {
					height: 18px;
					width: 18px;

					* {
						fill: black;
					}
				}
				&:hover {
					@include bg-blue;

					svg.BupLogo-root * {
						fill: darken($primary, 7%);
					}
				}
				&.Bup-disabled {
					pointer-events: none;
					opacity: 0.5;
					color: $bg-7;
				}
				span {
					position: absolute;
					font-size: 9.5px;
					top: 60px;
					white-space: nowrap;
				}
			}
			&.BupTopbar-social {
				gap: 10px;
				margin: 0;

				li {
					@include circle;
					width: 40px;

					i.BupIcon-root {
						font-size: 18px;
					}
				}
			}
		}
	}
	i.BupTopbar-caret {
		color: var(-bup-paper-background);
		font-size: 40px;
		margin-top: -2px;
		position: absolute;
		right: 52px;
		top: 0;
		z-index: 1000;
	}
	@media only screen and (max-width: $xs) {
		align-items: center;
		background: rgba(black, 0.66);
		display: flex;
		height: 100vh;
		justify-content: center;
		left: 0;
		padding: calc(16px / 4);
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 999;

		div.MuiStack-root {
			position: relative;
			padding: 16px calc(16px / 2) 0;
			width: 100%;

			button.MuiButton-fullWidth {
				display: block;
				font-weight: normal;
				border-top: 1px solid var(--bup-paper-border);
				border-radius: 0;
				height: 48px;
				width: 100%;
			}
			h6.Typoghraphy-h6 {
				font-size: 14px;
				text-align: center;
			}
			ul {
				&.BupTopbar-shortcuts {
					display: grid;
					grid-template-columns: repeat(3, 1fr);

					li,
					a.BupLink-href {
						width: 100%;
					}
				}
				&.BupTopbar-social {
					justify-content: center;
				}
			}
		}
		i.BupTopbar-caret {
			display: none;
		}
	}
}
