header.BupConsole-header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 4px;

	& > div.BupConsole-title {
		width: 100%;

		& > h1 {
			@include developer;
			align-items: center;
			display: flex;
			font-size: 28px;
			font-weight: 500;
			gap: 16px;
			line-height: 1.25;

			div.MuiChip-root {
				height: 22px;

				span.MuiChip-labelSmall {
					font-size: 12px;
					padding: 0 6px;
					padding-right: 6px;
				}
			}
			b {
				font-weight: 500;
			}
		}
		@media only screen and (max-width: $xs) {
			@include responsive;
			flex-direction: column;
			gap: 4px;

			& > h1 {
				font-size: 26px;
				display: block;

				div.MuiChip-root {
					margin-left: 8px;
				}
			}
		}
		@media only screen and (max-width: $x1) {
			& > h1 {
				font-size: 28px;
			}
		}
	}
	& + div.MuiStack-root {
		&:has(nav.BupConsole-addons) {
			margin-top: 0;
		}
	}
	@media only screen and (max-width: $xs) {
		margin-top: 32px !important;
	}
}
