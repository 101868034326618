div.BupConsole-root {
	min-height: 100vh;

	& > main.BupConsole-container {
		background: var(--bup-common-background);
		min-height: 100vh;
		padding-left: 256px;
		padding-top: $navbar-y;
		width: 100%;

		div.BupMain-loading {
			padding-right: 256px;

			@media only screen and (max-width: $md) {
				padding-right: 0;
			}
		}
		span.MuiDivider-wrapper {
			font-size: 13px;
		}
		&.BupConsole-expanded {
			padding-left: 72px;

			div.BupMain-loading {
				padding-right: 72px;
			}
		}
		&.BupConsole-premium {
			div.BupMain-loading svg.BupLogo-root path.BupLogo {
				&-primary {
					fill: darken($golden, 2%);
				}
				&-secondary {
					fill: lighten($golden, 8%);
				}
			}
		}
		@media only screen and (max-width: $sm) {
			padding-bottom: $navbar-y;

			&,
			&.BupConsole-expanded {
				padding-left: 0;
			}
		}
		@media only screen and (max-width: $xs) {
			padding: calc(#{$navbar-y} * 0.9) 0;
		}
	}
	&:has(.BupTopbar-install) {
		& > main.BupConsole-container {
			@media only screen and (max-width: $sm) {
				padding-bottom: calc(#{$navbar-y} * 1 + 40px);
			}
			@media only screen and (max-width: $xs) {
				padding-bottom: calc(#{$navbar-y} * 0.9 + 52px);
			}
		}
	}
}

@import "navbar/bup-appbar";
@import "navbar/bup-options";
@import "navbar/bup-search";
@import "navbar/bup-topbar";
@import "navbar/bup-toolbar";

@import "section/bup-addons";
@import "section/bup-drawer";
@import "section/bup-footer";
@import "section/bup-header";
@import "section/bup-mobile";
@import "section/bup-toast";
