section.BupBenefits-root {
  div.BupBenefits-selector {
    grid-gap: 32px 0;

    div.MuiBox-root {
      cursor: pointer;

      h2.MuiTypography-h2 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      img.BupImage-root,
      div.BupImage-root {
        @include video;
        margin-bottom: 16px;
        border: 1.5px solid transparent;
        transition: all 0.3s ease;
      }
      div.BupImage-root {
        border: none;

        @media only screen and (max-width: $xs) {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: flex-end;
          align-items: flex-end;
          padding: 6px 8px;

          div.MuiChip-filled {
            background: var(--bup-navbar-item-background);
            border: 1px solid var(--bup-navbar-item-border);
            color: var(--bup-navbar-color);
            opacity: 0.9;
          }
          ul {
            gap: 4px;
          }
        }
      }
      span.MuiTypography-p {
        color: var(--bup-text-secondary);
        display: block;
        font-size: 13.5px;
        line-height: 18px;
        opacity: 0.8px;
        width: 90%;

        @media only screen and (max-width: $xs) {
          font-size: 14px;
        }
      }
      &.Bup-disabled {
        user-select: none;
        pointer-events: none;
        filter: grayscale(1);
      }
      &.Bup-minified {
        img {
          aspect-ratio: auto;
          border: none;
          height: 256px;
          margin-bottom: 12px;
        }
        span.MuiTypography-p {
          display: none;
        }
        &:not(.Bup-selected) {
          display: none;

          h2.MuiTypography-h2 {
            color: var(--bup-text-secondary);
          }
          img {
            aspect-ratio: auto;
            height: 16px;
            filter: grayscale(1);
          }
        }
      }
      &:hover {
        img {
          border-color: var(--bup-paper-border);
        }
        h2.MuiTypography-h2 {
          text-decoration: underline;
        }
      }
    }
    div.MuiGrid-item {
      &:has(.Bup-minified:not(.Bup-selected)) {
        display: none;
      }
    }
    h5.MuiTypography-h5 {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      margin-top: 32px;
      width: 100%;
    }
    button.MuiButton-root {
      margin: 16px auto 0;
      height: 44px;
      width: 220px;
    }
    @media only screen and (max-width: $lg) {
      div.MuiBox-root {
        img {
          width: 100%;
          margin-bottom: 12px;
        }
      }
    }
    @media only screen and (max-width: $xs) {
      padding: 0 8px;
    }
  }
  h6.BupVideo-title {
    font-size: 14px !important;
    text-decoration: underline;
  }
  ul.benefits {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5 * 16px 16px;

    li.card {
      @include col-center;
      border-radius: var(--bup-border-radius);
      gap: 8px;

      img {
        @include bg-blue;
        @include video;
        border-radius: var(--bup-border-radius);
        width: 100%;
      }
      div {
        border-radius: var(--bup-border-radius);
        display: flex;
        flex-direction: column;
        gap: 3px;
        width: 100%;

        span {
          @include clamp(1);
          @include font(12px);
          opacity: 0.66;
          text-transform: uppercase;
        }
        b,
        p {
          @include clamp(2);
        }
        a {
          @include font(13px, 500);
          color: var(--bup-link-color);
        }
      }
    }
  }
  video[poster] {
    @include video;
    background-color: $bg-1;
    background-size: cover;
    background-position: center;
    border-radius: var(--bup-border-radius);
    border: 1px solid $bg-3;
    width: 100%;
  }
  @media only screen and (max-width: $xs) {
    button.MuiButton-root {
      margin-top: 32px;
      width: 100%;
    }
  }
}
iframe.no-printable {
  @media only screen and (max-width: $sm) {
    left: -15px !important;
    top: -15px !important;
    height: calc(100vh + 30px) !important;
    width: calc(100vw + 30px) !important;
  }
}
