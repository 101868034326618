div.BupPost {
  &-create {
    @include paper-box;
    @include responsive;
    padding: 12px 0;

    button.BupButton-root {
      background: var(--bup-input-background);
      border-color: var(--bup-input-border);
      border-radius: 22px;
      color: var(--bup-text-primary);
      height: 44px;
      justify-content: flex-start;
      width: 100%;

      &.Bup-loading {
        color: #777;
        cursor: wait;
      }
      &:hover {
        background: var(--bup-input-background-hover);
        border-color: var(--bup-input-border-hover);
      }
    }
    div.BupAvatar-root {
      height: 44px;
      width: 44px;
    }
    h3.MuiTypography-h3 {
      height: 44px;
      padding: 12px 0;
    }
    span.MuiSkeleton-root {
      &.BupSkeleton-avatar {
        height: 44px;
        width: 44px;
      }
      &.BupSkeleton-button {
        border-radius: 22px;
        height: 44px;
        width: 100%;
      }
    }
  }
  &-event {
    border-top: 1px solid var(--bup-divider);
    margin-top: 16px;
    padding-right: 16px;
    padding-top: 16px;
    position: relative;

    div.BupCard-preview.BupCard-listed {
      padding-bottom: 0;
      width: inherit;

      div.MuiCardContent-root {
        width: 100%;
      }
    }
    &:has(span.MuiCircularProgress-root) {
      @include row-center;
      height: 60px;

      span.MuiCircularProgress-root {
        height: 60px;
      }
    }
    @media only screen and (max-width: $md) {
      margin-left: 0;
    }
    @media only screen and (max-width: $xs) {
      padding-right: 0;

      div.BupCard-preview.BupCard-listed {
        margin-bottom: 0;
        padding: 0;
      }
    }
  }
  &-inset {
    border: none !important;
    padding: 0 !important;
  }
}
