div.BupBrowser-root {
  background: var(--bup-common-background);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $screen;
  min-height: 100vh;
  overflow-x: hidden;
  padding: $navbar-y 60px 0;

  @import "bup-login";
  @import "bup-navbar";
  @import "bup-register";

  @media only screen and (max-width: $xl) {
    padding: $navbar-y 30px 0;
  }
  @media only screen and (max-width: $lg) {
    padding: $navbar-y 0 0;

    section > div.MuiGrid-root {
      display: flex;
      justify-content: center;
    }
  }
}
