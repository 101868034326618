div.BupDocument-tabs {
  @include responsive;
  border-bottom-left-radius: var(--bup-border-radius);
  border-bottom-right-radius: var(--bup-border-radius);
  border-bottom: none;
  width: 100%;

  button.MuiTab-root {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border: none;
    font-size: 13px;
    text-transform: all 0.2s ease;
  }
  @media only screen and (max-width: $xs) {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
}
div.BupDocument-group {
  @include responsive;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 8px;

  @media only screen and (max-width: $sm) {
    flex-direction: column;
    gap: 12px;
  }
  @media only screen and (max-width: $xs) {
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 0;
  }
}
