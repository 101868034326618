@import "../main/bup-map.scss";

div.BupDocument-map {
  margin-bottom: -20px;
  margin-left: -20px;
  width: calc(100% + 40px) !important;

  iframe.BupDocument-googleMap {
    @include video;
    border: none !important;
    border-radius: 0;
  }
  button.BupItem-action {
    border: none !important;
    margin: 0 !important;
    width: 100% !important;
  }
}
div.BupDocument-meet {
  small {
    color: var(--bup-text-secondary);
    display: block;
    font-size: 12px;
  }
}
span.MuiSkeleton-root {
  &.BupDocument-map {
    @include video;
    border-radius: var(--bup-border-radius);
  }
  &.BupDocument-button {
    border-radius: 18px;
    height: 36px;
    width: 100%;
  }
}
