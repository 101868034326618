div.MuiCardMedia-root {
  & {
    @include video;
    align-items: flex-end;
    background-color: var(--bup-navbar-background);
    border-radius: var(--bup-border-radius);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px;

    &.MuiSkeleton-root {
      background-color: var(--bup-skeleton);
    }
    div.BupCard-mediaFlags {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 2px;
      margin: 8px;
      margin-right: -4px;

      &.BupCard-leftFlags {
        margin-right: 0;
        margin-left: -4px;
      }
    }
    div.BupCard-mediaIcons {
      button.BupCard-mediaButton {
        color: $bg-0;
        text-shadow: 0px 0px 8px black;

        i.BupIcon-root {
          font-size: 22px !important;
        }
        &.Bup-active {
          color: $bg-1 !important;
          background-color: transparent !important;
        }
        &.Bup-selected {
          color: var(--bup-danger-color);
        }
      }
    }
    span.MuiTypography-span {
      @include blur(6px);
      background: var(--bup-navbar-item-background);
      border: 0.5px solid var(--bup-navbar-item-border);
      border-right: none;
      border-radius: 32px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      color: var(--bup-navbar-color);
      font-size: 12px;
      font-weight: bold;
      opacity: 0.9;
      padding: 2px 8px;
      user-select: none;

      &:has(b) {
        background-color: var(--bup-text-primary);
        border-color: var(--bup-text-tertiary);
        border-width: 0.5px;
        color: var(--bup-paper-background);
        opacity: 1;
      }
      &:nth-child(2) {
        background-color: var(--bup-text-primary);
        border-color: var(--bup-text-tertiary);
        color: var(--bup-paper-background);
        font-size: 11.5px;
        font-weight: 500;
        width: fit-content;
      }
      &.BupCard-primary {
        background-color: var(--bup-button-secondary);
        border-color: var(--bup-button-secondary);
        border-radius: 32px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        color: white;
        font-size: 11px;
      }
      &.BupCard-danger {
        background-color: var(--bup-error-main);
        border-color: var(--bup-error-main);
        color: white;
      }
      &.BupCard-success {
        background-color: var(--bup-success-dark);
        border-color: var(--bup-success-dark);
        border-radius: 32px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        color: white;
        font-size: 11px;
      }
    }
    div.BupCard-rightFlags {
      height: 100%;
    }
  }
  &.BupCard-mediaSquare,
  &.BupCard-mediaMinified {
    @include square;

    @media only screen and (max-width: $sm) {
      border-radius: var(--bup-rounded);
    }
  }
  &.BupCard-mediaMinified {
    border-radius: var(--bup-rounded);
  }
  &.BupCard-mediaAvatar {
    background-color: var(--bup-paper-background);
    border-radius: var(--bup-rounded);
    display: flex;
    align-items: flex-start;
    padding: 0 !important;
    width: fit-content;

    .BupAvatar-root {
      background-color: var(--bup-avatar-background);
      height: 48px;
      width: 48px;

      &:has(svg.MuiAvatar-fallback) {
        border-color: var(--bup-paper-border);

        svg.MuiAvatar-fallback {
          fill: var(--bup-avatar-color);
        }
      }
    }
    &.BupCard-mediaMinified {
      @include square;
      width: 100%;

      .BupAvatar-root {
        @include square;
        width: inherit;
      }
    }
    &.BupCard-mediaCollapsed {
      .BupAvatar-root {
        svg.MuiAvatar-fallback {
          height: 60% !important;
        }
      }
    }
  }
  &.BupCard-mediaBanner {
    align-items: center;
    aspect-ratio: auto;
    background-color: var(--bup-paper-background);
    border-radius: 0;
    display: flex;
    justify-content: center;
    height: fit-content;
    width: 100%;

    .BupAvatar-root {
      border: 3px solid var(--bup-avatar-background);
      height: 64px;
      transition: all 0.2s ease;
      width: 64px;

      &.Bup-premium {
        border-color: var(--bup-premium-button-color-active);
      }
      &.Bup-admin {
        border-color: var(--bup-tab-border);
      }
    }
  }
  @media only screen and (max-width: $sm) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-width: 0;

    &.BupCard-mediaSquare {
      margin-top: 4px;
    }
  }
}
