@import "config";
@import "components";
@import "cards";
@import "document";
@import "layouts/browser";
@import "layouts/console";
@import "views";

.grecaptcha-badge {
  bottom: 75px !important;
}
