aside.BupConsole {
	&-drawer {
		$font-size: 24px;
		background: var(--bup-navbar-background);
		border-right: 1px solid var(--bup-common-border);
		color: var(--bup-navbar-color);
		display: grid;
		grid-template-rows: 92px 1fr auto;
		height: calc(100vh - #{$navbar-y});
		left: 0;
		padding: 8px 4px;
		position: fixed;
		top: $navbar-y;
		width: 256px;
		z-index: 98;

		.BupAside {
			&-rail {
				align-items: center;
				display: flex;
				justify-content: space-between;

				h4.MuiTypography-h4 {
					@include font($font-size, 500);
					padding-left: 22px;
				}
				button.BupButton-icon {
					opacity: 0.2;
					color: inherit;

					i.BupIcon-root {
						font-size: 22px !important;
					}
					&:hover {
						opacity: 0.5;
					}
				}
				@media only screen and (max-width: $lg) {
					height: 60px;
				}
			}
			&-section {
				display: flex;
				flex-direction: column;
				gap: 8px;
				padding-bottom: 8px;

				li {
					@include font(14px);
					align-items: center;
					background: var(--bup-navbar-item-background);
					border-radius: 20px;
					border: none;
					color: var(--bup-navbar-item-color);
					display: flex;
					flex-direction: row;
					gap: 6px;
					height: 36px;
					justify-content: flex-start;
					padding: 0 16px;

					i.BupIcon-root {
						@include row-center;
						border-radius: 50%;
						font-size: 20px;
						height: 30px;
						pointer-events: none;
						width: 30px;
					}
					small {
						@include font(8px, bold);
						background: var(--bup-navbar-item-background-active);
						border-radius: var(--bup-rounded);
						color: $bg-0;
						margin-left: 5px;
						padding: 3px 5px;
						text-transform: uppercase;
						user-select: none;
						vertical-align: middle;
					}
					&.Bup-active,
					&.Bup-current {
						background: var(--bup-navbar-item-background-active) !important;
						color: white !important;
						pointer-events: auto;

						small {
							background: $bg-0;
							color: var(--bup-navbar-item-background-active);
						}
						i.BupIcon-root {
							background-color: rgba(white, 0.16);
							color: inherit;
						}
					}
					&:hover {
						background: var(--bup-navbar-item-background-hover);
						cursor: pointer;
					}
					&.BupAside-title {
						font-weight: 500;
						padding-left: 22px;
						pointer-events: none;
						user-select: none;
					}
				}
			}
			&-module {
				border-bottom: 1px solid var(--bup-common-border);
				display: flex;
				flex-direction: column;
				gap: 2px;
				margin-bottom: 4px;
				padding-bottom: 10px;
			}
		}
		&.BupDrawer-collapse {
			padding: 8px 4px;
			width: 72px;

			.BupAside {
				&-rail {
					justify-content: center;

					h4.MuiTypography-h4 {
						display: none;
					}
					button.BupButton-icon {
						opacity: 1;
					}
				}
				&-section li {
					justify-content: center;
					padding: 0 12px;

					i.BupIcon-root {
						font-size: 21px;
					}
					span {
						display: none;
					}
					&:has(.fa-whatsapp):hover {
						background: var(--bup-success-whatsapp);
						color: white;
					}
				}
			}
		}
		@media only screen and (max-width: $lg) {
			grid-template-rows: 76px 1fr auto;
		}
		@media only screen and (max-width: $sm) {
			height: calc(100vh - #{$navbar-y} * 1.8);
			transition: top 0.1s linear;
			top: calc(#{$navbar-y} * 0.9);

			&.BupDrawer-collapse {
				top: 100vh;
			}
		}
	}
	&-backdrop {
		backdrop-filter: blur(5px);
		background: rgba(black, 0.66);
		display: none;
		height: calc(100vh - #{$navbar-y} * 1.8);
		position: fixed;
		right: 0;
		top: calc(#{$navbar-y} * 0.9);
		width: 100vw;
		z-index: 97;

		@media only screen and (max-width: $sm) {
			display: block;
		}
	}
}
