.Bup-active {
  cursor: default !important;
  pointer-events: none;
}
.Bup-disabled {
  cursor: default;
  pointer-events: none;
  user-select: none;
}
.Bup-flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.Bup-grayscale {
  filter: grayscale(1);
}
.Bup-hidden {
  display: none !important;
}
.Bup-outlined {
  border: 1px solid var(--bup-common-border);
}
.Bup-danger {
  color: var(--bup-danger-color) !important;
}
.Bup-pending {
  border-color: var(--bup-danger-border) !important;
  border-width: 1.5px !important;
  filter: grayscale(0.1) !important;
  pointer-events: none !important;
}
.Bup-pointer {
  cursor: pointer;
}
.Bup-readOnly {
  user-select: none;
}
.Bup-rounded {
  border-radius: var(--bup-border-radius);
}
.Bup-selected {
  cursor: pointer;
}
.Bup-wrapper:empty {
  display: none !important;
}
i.Bup {
  &-diamond {
    @include text($diamond);
  }
  &-gold {
    @include text($golden);
  }
  &-silver {
    @include text($silver);
  }
  &-bronze {
    @include text($bronze);
  }
}
.BupText {
  &-capitalize {
    text-transform: capitalize;
  }
  &-lowercase {
    text-transform: lowercase;
  }
  &-overline {
    text-decoration: overline;
  }
  &-through {
    text-decoration: line-through;
  }
  &-underline {
    text-decoration: underline;
  }
  &-uppercase {
    text-transform: uppercase;
  }
}
