div.BupGrid-root,
div.MuiGrid-root {
  &:empty {
    display: none;
    padding: 0;
  }
  &.BupGrid-tutor {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    div.MuiGrid-item {
      min-width: 100%;

      div.BupCard-tutor {
        background-color: var(--bup-navbar-background);
        border: 1px solid var(--bup-common-border);
        border-radius: var(--bup-border-radius) !important;
        color: var(--bup-icon-color);
        display: grid;
        gap: 12px;
        grid-template-columns: 80px 1fr;
        height: 100%;
        padding: 16px 12px;

        & > div {
          display: flex;
          height: 100%;
          flex-direction: column;
          width: 100%;
          gap: 12px;
        }
        div.MuiCardMedia-root {
          background-color: transparent;
          margin: 0;
          justify-content: flex-start;
          padding: 0;

          div.BupAvatar-root {
            border-radius: var(--bup-border-radius);
            margin: 0;
            height: 80px;
            width: 80px;
          }
        }
        div.MuiCardContent-root {
          padding: 0;

          div.MuiTypography-h6 {
            @include clamp(5);
            font-size: 14px !important;
            text-align: left;
            font-weight: 400;
            line-height: 1.33;

            b {
              font-size: 16px !important;
              font-weight: 600;
            }
          }
          p.MuiTypography-body1 {
            align-items: center;
            color: var(--bup-text-tertiary);
            display: flex;
            font-size: 13px;
            font-weight: 500;
            gap: 6px;
            line-height: 1.33;
            margin-top: 8px;
            text-align: left;

            svg {
              height: 12px;
            }
          }
          @media only screen and (max-width: $xs) {
            padding-top: 2px;

            p.MuiTypography-body1 {
              margin: 12px 0 4px;
            }
          }
        }
        div.MuiCardActions-root {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          flex-direction: column;
          padding: 0;

          button.BupButton-root {
            margin: 0;
            margin-left: -2px;
            padding: 5px 12px;
          }
        }
      }
    }
    &.BupGrid-aside {
      grid-template-columns: 1fr !important;

      div.MuiGrid-item div.BupCard-tutor {
        padding: 0;
        border: none;
      }
    }
    & > div:has(div.BupCard-tutor) {
      grid-column: span 3;
      width: 100%;
    }
    @media only screen and (max-width: $xl) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media only screen and (max-width: $lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: $xs) {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 12px;
    }
  }
}
