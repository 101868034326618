div.BupSubscription-status {
  width: 100%;

  div.BupSubscription {
    &-actions {
      width: $xs;

      img {
        @include video;
      }
      @media only screen and (max-width: $lg) {
        flex-direction: row;
        gap: 8px;
        width: 100%;

        img {
          width: calc(100% - #{$xs} - 8px);
        }
        & > div {
          gap: 8px;
        }
        div.BupLink-item {
          margin: 0;
          width: $xs;
        }
      }
      @media only screen and (max-width: $md) {
        flex-direction: column;

        img,
        div.BupLink-item {
          width: 100%;
        }
      }
      @media only screen and (max-width: $xs) {
        img {
          border: none;
          border-radius: 0 !important;
          display: none;
          margin-bottom: 8px;
        }
        div.BupLink-item {
          margin: 0 8px;
          width: calc(100% - 16px);
        }
      }
    }
    &-content {
      @include paper-box;
      @include developer;
      border: 0.5px solid var(--bup-divider);
      padding: 24px;
      width: calc(100% - #{$xs});

      & {
        h1.MuiTypography-h1 {
          font-size: 40px;
          font-weight: 400;
          min-width: 450px;
        }
        span.MuiTypography-p {
          font-size: 14.5px;
          font-weight: 400;
          line-height: 20px;
          min-width: $xs;
        }
        span.MuiTypography-span:first-child {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          width: 300px;
        }
        svg.BupLogo-root {
          height: 32px;
          min-width: 32px;
          width: 32px;
        }
        div.BupAvatar-group {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 16px;
        }
        a.BupLink-root {
          color: var(--bup-link-color);
          font-size: 14px;
          font-weight: 500;
        }
      }
      @media only screen and (max-width: $lg) {
        @include responsive;
        width: 100%;

        div.BupAvatar-group {
          margin-top: 24px;
        }
        svg.BupLogo-root {
          display: none;
        }
      }
      @media only screen and (max-width: $xs) {
        border: none;
        border-top: 1px solid var(--bup-common-border);
        border-bottom: 1px solid var(--bup-common-border);
        border-radius: 0;
        padding: 16px 8px;

        span.MuiTypography-p {
          display: block;
          font-size: 14px;
          min-width: 0;
          width: calc(100vw - 16px);
        }
      }
    }
  }
}
