div.BupBox-root,
div.BupStack-root {
  @for $i from 0 through 15 {
    &.BupOrder-#{$i} {
      order: $i;
    }
  }
  &:empty {
    display: none !important;
  }
  &.BupStack-group {
    h2.MuiTypography-h2 {
      @include developer;
      font-size: 22px;
      font-weight: 500;

      i.BupIcon-root {
        display: none;
      }
      @media only screen and (max-width: $xs) {
        font-size: 20px;
        padding: 0 $padding-sm;
      }
    }
    &.BupStack-slider {
      @media only screen and (max-width: $xs) {
        h3.MuiTypography-h3 {
          align-items: center;
          display: flex;
          justify-content: space-between;
          font-size: 20px;

          i.BupIcon-root {
            font-size: 24px;
          }
        }
        div.MuiGrid-container {
          @include slide-scroll;
          scroll-behavior: smooth;
          transition: transform 0.3s;

          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;

          div.MuiGrid-item {
            min-width: 75vw;
            width: 100vw;

            b {
              @include clamp(1);
            }
            span.MuiTypography-span {
              small,
              s {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
div.MuiDivider-fullWidth {
  span.MuiDivider-wrapper {
    color: var(--bup-text-secondary);
  }
  &::after,
  &::before {
    border-color: var(--bup-divider);
  }
}
div.MuiPaper-root {
  border-radius: var(--bup-border-radius);
}
