aside.BupConsole-drawer {
  &.BupDrawer-mobile {
    border: none;
    padding: $padding-sm;
    width: 100vw;
    overflow-y: auto;
  }
}
.BupAccount {
  &-header {
    padding-top: 8px;

    h1.MuiTypography-h1 {
      align-items: center;
      display: flex;
      font-size: 23px;
      font-weight: bold;
      gap: 8px;
      line-height: 1.25;
      text-align: center;
      white-space: nowrap;
    }
    button {
      max-height: 24px;
      padding: 4px 8px;
      margin-left: -2px !important;
    }
    span.MuiTypography-p {
      color: var(--bup-text-tertiary);
      font-size: 13px;
      font-weight: 400;
    }
    svg.BupLogo-root {
      height: 20px;
      width: 20px;

      * {
        fill: var(--bup-premium-button-color-active) !important;
      }
    }
    div.BupAccount-avatar {
      @include row-center;
      position: relative;
      background-color: var(--bup-icon-background);
      border-radius: 50%;
      height: 70px;
      width: 70px;

      div.MuiAvatar-root {
        position: absolute;
        height: 87%;
        width: 87%;
        z-index: 1;
      }
      svg.BupAvatar-ring {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        circle {
          stroke: var(--bup-link-color);
          stroke-width: 10px;
        }
      }
      span {
        @include row-center;
        background-color: var(--bup-link-color);
        border-radius: 4px;
        bottom: -4px;
        color: white;
        font-size: 13px;
        height: 20px;
        line-height: 1;
        padding: 0 8px;
        position: absolute;
        z-index: 2;
      }
    }
  }
  &-content {
    &:last-child {
      padding-bottom: 16px;
    }
    span.MuiTypography-p {
      font-size: 15px;
      font-weight: 500;
      line-height: 1;
    }
    div.BupAccount-item {
      border-radius: var(--bup-border-radius);
      border: 1px solid var(--bup-icon-border);
      font-size: 14px;
      padding: 16px 12px;
      width: calc(50% - 6px);

      i.BupIcon-root {
        color: var(--bup-icon-color);
        font-size: 20px;
        opacity: 0.66;
        margin-left: auto;
      }
      span.MuiTypography-p {
        color: var(--bup-text-primary);
        font-size: 15px;
      }
      span.MuiTypography-small {
        color: var(--bup-text-tertiary);
        font-size: 12.5px;
        opacity: 0.85;
      }
      &.Bup-active {
        background-color: var(--bup-tab-background);
        border-color: var(--bup-tab-border);
        color: var(--bup-tab-color);

        i.BupIcon-root {
          color: inherit;
        }
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 4px;

      li,
      div {
        align-items: center;
        background-color: var(--bup-common-item);
        border: 1px solid var(--bup-common-border);
        border-radius: var(--bup-rounded);
        color: var(--bup-text-primary);
        display: flex;
        gap: 12px;
        font-size: 14px;
        padding: 6px 16px;
        min-height: 56px;
        width: 100%;

        i.BupIcon-root {
          color: var(--bup-icon-color);
          font-size: 18px;
        }
        &:hover {
          background-color: var(--bup-common-hover);
        }
        &:has(div) {
          background-color: transparent;
          gap: 4px;
          padding: 0;

          div {
            @include landscape;
            background-color: transparent;
            border: 1px solid var(--bup-paper-border);
            flex-direction: column;
            font-size: 13px;
            justify-content: center;
            gap: 4px;
            height: auto;

            i.BupIcon-root {
              font-size: 20px;
            }
          }
        }
        &.Bup-active {
          background-color: var(--bup-tab-background);
          border-color: var(--bup-tab-border);
          color: var(--bup-tab-color);

          i.BupIcon-root {
            color: inherit;
          }
        }
      }
    }
    a {
      font-size: 12.5px;
      padding: 4px 6px;
    }
  }
  &-premium {
    & > div {
      background-color: var(--bup-paper-background);

      & > div {
        background-color: rgba($premium, 0.12);
        border-radius: inherit;
        @include col-center;
        text-align: center;
        padding: 20px 12px 16px;

        h2.MuiTypography-h2 {
          color: black;
          font-size: 18px;
          font-weight: 500;
          line-height: 1;
        }
        p {
          font-size: 14px;
        }
        button {
          border-radius: var(--bup-rounded) !important;
        }
      }
    }
  }
}
