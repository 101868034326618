div.BupAlert-root {
	@include paper-box;
	align-items: center;
	border-radius: var(--bup-rounded) !important;
	margin-bottom: 0;

	&.MuiAlert-standardInfo {
		background: var(--bup-alert-background);
		min-height: 60px;

		button.BupButton-root {
			border-radius: var(--bup-rounded);
			margin-left: 12px;
			min-width: 100px;
			width: fit-content;
		}
		div.MuiAlert-message {
			font-size: 14px;
			width: 100%;

			&:has(button.BupButton-root) {
				@media only screen and (max-width: $md) {
					align-items: center;
					display: grid;
					grid-template-columns: 1fr auto;
					font-size: 13.5px;
					gap: 8px;
					overflow: hidden;

					button {
						margin: 0 !important;
					}
				}
				@media only screen and (max-width: $xs) {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}
			}
		}
		svg.MuiSvgIcon-root {
			fill: var(--bup-alert-color);
		}
		&.Bup-premium {
			background: var(--bup-premium-alert-background);
			border-color: var(--bup-premium-alert-border);

			button.BupButton-root {
				background-color: var(--bup-premium-alert-button-background) !important;
				border: 1px solid var(--bup-premium-alert-button-border) !important;
				border-radius: 18px;
				padding: 4px 12px;
				height: 32px;

				b {
					color: var(--bup-premium-alert-button-color) !important;
				}

				&:hover {
					background: var(--bup-premium-alert-button-background-hover) !important;
					border-color: var(--bup-premium-alert-button-border-hover) !important;
				}
			}
			div.MuiAlert {
				&-action {
					button.MuiIconButton-root {
						border-radius: 50%;
					}
				}
				&-message {
					strong,
					b {
						color: var(--bup-premium-alert-color);
					}
				}
			}
			svg.MuiSvgIcon-root {
				fill: var(--bup-premium-alert-color);
			}
		}
	}
	@media only screen and (max-width: $xs) {
		align-items: center;
		padding: 4px $padding-sm;

		div.MuiAlert-icon {
			justify-content: flex-start;
			height: 100%;
			padding: 12px 0;
			display: flex;
			flex-direction: column;
			margin-right: 12px;
		}
		&.MuiAlert-standardInfo {
			display: grid;
			grid-template-columns: 1fr auto;

			div.MuiAlert-message:has(button) {
				padding-bottom: 4px;
			}
		}
	}
}
