div.MuiCardContent-root {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;

  h6 {
    &.BupCard-title {
      @include clamp(1);
      color: var(--bup-text-primary);
      font-weight: 500;
      line-height: 1.33;
    }
    &.BupCard-eventName {
      font-size: 20px;

      &.BupCard-eventNameSlider {
        font-size: 18px;
      }
      @media only screen and (max-width: $xs) {
        font-size: 18px;

        &:not(.BupCard-eventNameSlider) {
          @include clamp(2);
        }
      }
    }
    &.BupCard-friendName {
      font-size: 16px;

      b {
        font-weight: 500 !important;
      }
      svg {
        height: 13.5px;
      }
      &.BupCard-friendNameGrid {
        font-size: 15px;
      }
      &:has(svg),
      &:has(i) {
        align-items: center;
        display: flex;
        gap: 8px;
      }
    }
    &.BupCard-groupName {
      align-items: center;
      display: flex;
      justify-content: space-between;
      font-size: 18px;

      b {
        font-weight: inherit;
      }
      i.BupCard-groupIconMember {
        color: var(--bup-link-color);
        font-size: 18px;
      }
    }
  }
  p {
    &.BupCard-eventDate {
      @include clamp(1);
      color: var(--bup-text-tertiary);
      font-size: 12.5px;
      font-weight: 500;
      text-transform: uppercase;
      width: 100%;
    }
    &.BupCard-eventPlace {
      align-items: center;
      gap: 6px;

      small {
        background-color: var(--bup-input-background-hover);
        border: 1px solid var(--bup-input-border-hover);
        border-radius: var(--bup-rounded);
        color: var(--bup-icon-color);
        font-weight: 400;
        font-size: 11.5px;
        padding: 0 5px;
        text-wrap: nowrap;
        width: fit-content;

        &.Bup-active {
          background-color: var(--bup-input-color-hover);
          border-color: var(--bup-input-color-hover);
          color: var(--bup-icon-background);
        }
      }
      div,
      span {
        @include clamp(1);
        color: var(--bup-text-secondary);
        font-size: 13.5px;
        font-weight: 400;
        opacity: 0.8;

        &.BupCard-eventChips {
          align-items: center;
          display: flex;
          gap: 6px;
          opacity: 1;

          &:empty {
            display: none;
          }
        }
      }
      &:has(div) {
        display: grid;
        grid-template-columns: auto 1fr;
      }
      @media only screen and (max-width: $xs) {
        &:not(.BupCard-eventPlaceReverse) {
          align-items: flex-start;
          flex-direction: column-reverse;
        }
        &.BupCard-eventPlaceReverse {
          &:has(.BupCard-eventChips) {
            flex-direction: row-reverse;
            justify-content: space-between;
          }
        }
      }
    }
    &.BupCard-eventSummary {
      @include clamp(2);
      color: var(--bup-text-primary);
      opacity: 0.95;
      font-size: 14px;
      line-height: 1.4;
      margin: 8px 0;
    }
    &.BupCard-friendCommon {
      @include clamp(1);
      align-items: center;
      color: var(--bup-text-secondary);
      display: flex;
      font-size: 13px;
      gap: 4px;
      margin-top: 1px;
      opacity: 0.8;

      &.BupCard-alignCenter {
        justify-content: center;
        font-size: 12.5px;
        margin-top: 0;
      }
    }
    &.BupCard-groupMembers {
      @include clamp(1);
      font-size: 12px;
      color: var(--bup-text-secondary);
      opacity: 0.6;
    }
    &.BupCard-groupType {
      @include clamp(1);
      color: var(--bup-text-tertiary);
      font-size: 13.5px;
      font-weight: 400;
      width: 100%;
    }
  }
  &:has(.BupCard-eventName) {
    gap: 4px;

    &:has(.BupCard-eventNameSlider) {
      &:not(:has(small)) {
        gap: 2px;
      }
    }

    @media only screen and (max-width: $xs) {
      gap: 2px;
    }
  }
  &:has(.BupCard-FriendName) {
    gap: 2px;
  }
  &.BupCard-contentMinified {
    h6.BupCard-eventName,
    h6.BupCard-groupName {
      font-size: 16.5px;
    }
  }
}
