div.BupItem {
	&-root {
		& > div.MuiPaper-root {
			@include paper-box;
			padding: 0;

			&:has(button.BupItem-action) {
				button.BupItem-action {
					@include responsive;
					border: none;
					border-radius: var(--bup-border-radius);
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					border-top: 1px solid var(--bup-common-border);
					color: var(--bup-link-color);
					font-size: 13.5px;
					font-weight: 500;
					height: 56px;
					width: 100%;

					i.BupIcon-root {
						font-size: 15px;
					}
					&:hover {
						background-color: var(--bup-common-hover);
					}
					@media only screen and (max-width: $xs) {
						border-radius: 0;
						font-size: 14px;
						//justify-content: flex-start;
					}
				}
				div.BupItem-wrapper {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
			@media only screen and (max-width: $xs) {
				border-radius: 0;

				div.BupSection-wrapper {
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
			}
		}
		@media only screen and (max-width: $xs) {
			border-radius: 0 !important;
		}
	}
	&-header {
		a.BupItem-link {
			color: var(--bup-link-color);
			font-size: 13.5px;
			font-weight: 400;

			&:empty {
				display: none;
			}
		}
		div.MuiChip-root {
			font-size: 12px;
			font-weight: normal;
			width: fit-content;
			margin-left: 8px;
		}
		h2.MuiTypography-h2 {
			font-size: 25px;
		}
		h3.MuiTypography-h3 {
			font-size: 20.5px;
		}
		h2.MuiTypography-h2,
		h3.MuiTypography-h3 {
			font-weight: 400;

			@media only screen and (max-width: $xs) {
				font-size: 18.5px;
				font-weight: 500;
			}
		}
		i.BupIcon-item {
			color: var(--bup-text-tertiary);
			font-size: 18px;

			@media only screen and (max-width: $xs) {
				display: none;
			}
		}
		& + span.MuiTypography-p {
			margin-top: 4px;
		}
	}
	&-wrapper {
		@include responsive;
		border-radius: var(--bup-border-radius);
		padding: 20px 0;

		span.MuiTypography-p {
			font-size: 14px;
			line-height: 1.33;
			opacity: 0.85;
		}
		@media only screen and (max-width: $xs) {
			border-radius: 0;
		}
	}
}
