a.BupLink-root {
  font-weight: 500 !important;

  &.Bup-url:not(.Bup-disabled) {
    color: var(--bup-link-color);

    span {
      font-size: inherit;
      text-decoration: underline;
    }
  }
  &:has(i.BupIcon-root) {
    align-items: center;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    font-size: 14px;
    gap: 4px;

    &:hover {
      color: var(--bup-link-color-hover);
    }
  }
  &.Bup-disabled {
    pointer-events: none;
    user-select: none;
    color: #888;
  }
}
