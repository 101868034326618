div.BupPeople {
  &-root {
    div.BupEmpty-root {
      border: none;
    }
    &:has(div.BupPeople-tabs) {
      @include paper-box;
      padding-top: 16px;

      div.BupEmpty-root {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: none;
        background-color: transparent;
        border: none;
        border-top: 1px solid var(--bup-border-radius);
      }
      div.BupPeople-panel {
        border: none !important;
        margin-top: 0 !important;
      }
    }
    @media only screen and (max-width: $xs) {
      border-radius: 0 !important;
    }
  }
  &-panel {
    @include paper-box;
    padding: 12px 10px;

    &:empty {
      display: none !important;
    }
    @media only screen and (max-width: $xs) {
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;

      div.MuiGrid-root {
        margin: 0;
        padding: 0;
        width: 100%;

        div.BupCard-root {
          border: none !important;
          padding: 8px 0;
          padding-right: 2px;
        }
      }
    }
  }
  &-tabs {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;

    h3.MuiTypography-h3 {
      font-size: 20.5px;
      font-weight: 400;

      @media only screen and (max-width: $xs) {
        font-size: 18.5px;
        font-weight: 500;
      }
    }
    nav {
      button.MuiButton-text {
        height: 28px;
        margin-left: 2px;
        padding: 0 8px;
      }
    }
    @media only screen and (max-width: $xs) {
      margin-bottom: 16px;
      padding: 0 12px;
    }
  }
}
