section.BupCommunity-friends {
  div.BupCommunity-options {
    & > div {
      width: calc(50% - 6px);
    }
    @media only screen and (max-width: $sm) {
      margin-top: 0 !important;

      & > div {
        margin: 0;
        width: 100%;
      }
    }
    @media only screen and (max-width: $xs) {
      margin: 16px 0 8px;
      padding: 0 $padding-sm;

      & > div {
        margin: 0;
        width: 100%;
      }
    }
  }
}
