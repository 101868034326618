.BupSettings {
  &-root {
    display: grid;
    grid-template-columns: $x2 auto;

    h2.MuiTypography-h2 {
      font-size: 24px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 12px;

      button {
        padding: 6px 16px;

        &.BupButton-icon {
          background: transparent;
          border: 1px solid var(--bup-icon-border);
          color: var(--bup-icon-color);
          padding: 0;
          height: 28px;
          width: 28px;

          i.BupIcon-root {
            font-size: 18px;
          }
          &:hover {
            background: var(--bup-icon-background-hover);
            border-color: var(--bup-icon-border-hover);
          }
        }
      }
    }
    a.BupSettings-link {
      color: var(--bup-link-color);
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: var(--bup-link-color-hover);
        text-decoration: underline;
      }
    }
    button.BupSettings-submit {
      width: calc(#{$xs}/ 2);
    }
    div.BupSettings-buttonGroup {
      display: flex;
      flex-direction: row;
      gap: 8px;
      justify-content: flex-end;
    }
  }
  &-tabs {
    background-color: var(--bup-paper-background);
    border-bottom-left-radius: var(--bup-border-radius);
    border-top-left-radius: var(--bup-border-radius);
    border: 1px solid var(--bup-paper-border);
    border-right: none;
    padding: 12px 8px;

    small {
      display: block;
      margin-bottom: 16px;
      font-size: 13.5px;
      font-weight: bold;
      padding: 8px 12px 0;
    }
    ul {
      display: flex;
      flex-direction: column;
      padding-bottom: 4px;
      gap: 4px;

      li {
        align-items: center;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        gap: 8px;
        height: 40px;
        padding: 11px 12px;
        padding-left: 4px;
        user-select: none;
        width: 100%;

        i.BupIcon-root {
          @include row-center;
          font-size: 20px;
          height: 32px;
          border-radius: 18px;
          color: var(--bup-text-secondary);
          width: 32px;
        }
        &:hover {
          background-color: var(--bup-navbar-item-background-hover);
        }
        &.Bup-active {
          background-color: var(--bup-navbar-item-background-active);
          color: white;

          i.BupIcon-root {
            background-color: rgba(white, 0.16);
            color: inherit;
          }
        }
      }
    }
  }
  &-panel {
    background-color: var(--bup-paper-background);
    border-bottom-right-radius: var(--bup-border-radius);
    border-top-right-radius: var(--bup-border-radius);
    border: 1px solid var(--bup-paper-border);
    padding: 24px 16px;

    span.MuiTypography-p {
      font-size: 15px;
      margin: 0;
      width: calc(100% - 112px);
    }
    ul.BupSettings-checklist {
      padding-bottom: 16px;
      gap: 0;

      li {
        display: grid;
        grid-template-columns: auto 44px 44px;
        grid-gap: 12px;
        padding: 14px 16px;
        cursor: default !important;

        b {
          font-size: 15px;
          font-weight: 500;
        }
        small {
          font-size: 13px;
          color: var(--bup-text-secondary);
        }
        & > i {
          cursor: pointer !important;
          pointer-events: auto;
          font-size: 20px;
          text-align: center;
          margin-top: 8px;

          &.Bup-active {
            color: var(--bup-link-color);
          }
        }
        div.BupSettings-option {
          @include col-center;
          font-size: 11px;
          font-weight: 500;
          gap: 6px;
          text-transform: uppercase;

          & > i {
            color: var(--bup-text-secondary);
            font-size: 20px;
          }
        }
      }
      &:not(.BupSettings-list) {
        margin-bottom: -32px;
        margin-top: 0;
      }
    }
    ul.BupSettings-radiogroup {
      margin-top: 24px;

      li {
        gap: 16px;
        padding: 16px 12px;

        h6.MuiTypography-h6 {
          font-size: 15px;
          font-weight: 500;
        }
        p.MuiTypography-body1 {
          font-size: 14px;
          line-height: 1.33;

          b {
            color: var(--bup-text-tertiary);
            font-weight: 500;
          }
        }
        &.Bup-active {
          i.BupIcon-root {
            color: var(--bup-button-primary);
          }
        }
      }
    }
  }
}
