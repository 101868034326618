div.BupDocument-ticket {
  div.BupDocument-payment {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 100%;

    div.BupDocument {
      &-dateBox {
        min-width: 80px;

        & > div {
          @include col-center;
          border: 1.5px solid var(--bup-icon-border);
          border-radius: var(--bup-border-radius);
          gap: 4px;
          padding: 12px 0;
          width: 100%;

          span {
            font-size: 24px;
            font-weight: 500;

            &:first-child,
            &:last-child {
              font-size: 13px;
              font-weight: normal;
              opacity: 0.8;
            }
          }

          &.Bup-active {
            background: var(--bup-link-background);
            border-color: var(--bup-link-border);
            color: var(--bup-link-color);
          }
        }
        & > span {
          @include col-center;
          background: var(--bup-text-tertiary);
          border-radius: var(--bup-border-radius);
          color: var(--bup-paper-background);
          display: block;
          font-size: 10px;
          margin: 8px auto 0;
          padding: 1.5px 8px;
          width: fit-content;
        }
      }
      &-timeBox {
        display: flex;
        flex-direction: column;
        width: 100%;

        span {
          font-size: 13px;
        }
        div.MuiChip-root {
          background-color: var(--bup-input-background);
          border: 1px solid var(--bup-input-border);
          color: var(--bup-text-secondary);
          margin: 0;
          margin-right: 8px;
          margin-left: 2px;

          span.MuiChip-label {
            font-size: 12px;
          }
        }
      }
      &-details {
        border-radius: var(--bup-rounded);
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 4px;

        div.BupDocument-item {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 12px;

          span {
            color: rgba(black, 0.33);
            font-size: 20px;
          }
          p.MuiTypography-body1 {
            font-size: 14px;
          }
          a {
            color: var(--bup-link-color);

            &:hover {
              text-decoration: underline;
            }
          }
          s {
            margin-left: 4px;
            text-decoration: line-through;
            font-size: 12px;
          }
          & > div {
            align-items: center;
            display: flex;
            gap: 8px;
            width: 100%;
          }
          div.MuiTypography-p {
            border-radius: var(--bup-rounded);
            font-size: 14px;
            padding: 0px 28px;
            width: fit-content;
          }
        }
      }
    }
  }
  div.BupDocument-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    justify-content: center;
    width: 100%;
  }
  span.MuiSkeleton-root {
    &.BupDocument-title {
      border-radius: var(--bup-rounded);
      margin-bottom: 16px;
      margin-top: -16px;
      height: 24px;
    }
    &.BupDocument-dateBox {
      border-radius: var(--bup-rounded);
      height: 92px;
      min-width: 80px;
    }
    &.BupDocument-timeBox {
      border-radius: var(--bup-rounded);
      width: 100%;
      height: 150px;
    }
  }
}
