section.BupCommunity-feed {
  div.BupCommunity-summary {
    div.BupPaper-root {
      align-items: center;
      background: transparent;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      width: 100%;

      button.BupLink-root {
        color: var(--bup-text-primary);
        font-size: 13px;
        font-weight: 400;
        height: 24px;

        &:hover {
          color: var(--bup-link-color);
        }
      }
      div.BupAvatar-root {
        height: 80px;
        width: 80px;
      }
      h4.MuiTypography-h4 {
        color: var(--bup-text-primary);
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 2px;
      }
      & > div.MuiStack-root {
        padding: 24px 0;

        &:last-child {
          padding: 12px 0;
        }
      }
    }
    @media only screen and (max-width: $lg) {
      display: none;
    }
  }
  img.BupCommunity-image {
    @include paper-box;
    aspect-ratio: 32/9;
    border-radius: var(--bup-border-radius);
    border: none !important;
    height: auto;
    width: 100%;

    @media only screen and (max-width: $md) {
      aspect-ratio: 24/9;
    }
    @media only screen and (max-width: $xs) {
      aspect-ratio: 16/9;
      border-radius: var(--bup-border-radius);
      margin: 0 $padding-sm;
      width: calc(100% - 2 * #{$padding-sm});

      border-radius: 0;
      margin: 0;
      width: 100%;
    }
  }
  nav.MuiList-root {
    padding: 0 8px 24px;
    width: inherit;

    div.MuiListItemButton-root {
      background: transparent;
      border-top: 1px solid var(--bup-input-border-hover);
      color: var(--bup-text-primary);
      cursor: default;
      pointer-events: none;
      padding: 8px 0;

      &:last-child {
        border-bottom: 1px solid var(--bup-input-border-hover);
      }
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      span.MuiTypography-body1 {
        color: inherit;
        font-size: 14px;
      }
      p.MuiTypography-body2 {
        color: inherit;
        text-align: right;
      }
    }
    div.MuiListItemIcon-root {
      min-width: 48px;

      i.BupIcon-root {
        @include row-center;
        background: transparent;
        border-radius: 50%;
        color: var(--bup-icon-color);
        font-size: 18px;
        height: 32px;
        width: 32px;
      }
      &.arrow {
        min-width: 0;

        i.BupIcon-root {
          background: transparent;
          font-size: 16px;
        }
      }
    }
    div.BupAvatar-root {
      height: 32px;
      width: 32px;
    }
  }
}
