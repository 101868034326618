div.BupSubscription-features {
  @include developer;
  background: var(--bup-input-background-hover);
  border-radius: var(--bup-border-radius);
  border: 1px solid var(--bup-input-border);
  display: grid;
  grid-template-columns: 9fr 80px 10fr;
  height: 750px;
  overflow: hidden;

  div.BupPlan {
    &-header {
      color: var(--bup-text-primary);
      padding: 32px 24px;

      div.BupPlan {
        &-affiliation {
          h6 {
            font-size: 13.5px;
            font-weight: 400;
            margin-bottom: 8px;
          }
          img {
            background-color: transparent;
            border-radius: 0;
            border: none;
            height: auto;
          }
        }
        &-title {
          padding: 0 !important;

          h2.MuiTypography-h2 {
            font-size: 28px;
            font-weight: 400;
          }
          h3.MuiTypography-h3 {
            color: var(--bup-text-tertiary);
            font-size: 14px;
            line-height: 1.25;
          }
        }
      }
      a {
        color: var(--bup-link-color);
        font-size: 14px;
        font-weight: bold;
        width: fit-content;
      }
      button.BupButton-root {
        border-radius: 20px;
        height: 40px;
        margin-bottom: 16px;
        width: 256px;

        &.MuiButton-containedPremium {
          background: rgba($golden, 0.8);
        }
        &.BupPlan-referral {
          background-color: black !important;
        }

        @media only screen and (max-width: $xs) {
          width: 100%;
        }
      }
      i.BupIcon-root {
        font-size: 16px;
      }
      span.BupPlan-discount {
        font-size: 15px;
      }
      span.MuiTypography {
        &-body1 {
          display: block;
          font-size: 14px;
          line-height: 1.25;
          margin-bottom: 4px;

          &:has(i.BupIcon-root) {
            align-items: center;
            font-weight: 500;
            display: flex;
            margin-top: 16px;
            gap: 8px;
          }
        }
        &-body2 {
          color: var(--bup-text-secondary);
          display: block;
          font-size: 13px;
          line-height: 1.25;
        }
        &-p {
          font-size: 18px;
          line-height: 1.25;
        }
      }
      @media only screen and (max-width: $lg) {
        @include responsive;
      }
      @media only screen and (max-width: $xs) {
        border-radius: 0 !important;
      }
    }
    &-content {
      background: var(--bup-paper-background);
      border-bottom-right-radius: var(--bup-border-radius);
      border-top-right-radius: var(--bup-border-radius);
      padding: 40px 32px 40px 20px;

      h3.MuiTypography-h3 {
        color: var(--bup-icon-color);
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;

        @media only screen and (max-width: $sm) {
          padding: 0;
        }
      }
      span.MuiTypography-span {
        color: var(--bup-text-primary);
        font-size: 14.5px;
      }
      ul li {
        color: var(--bup-text-secondary);
        display: grid;
        font-size: 13.5px;
        grid-template-columns: 24px 1fr;
        margin-top: 16px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
        i.BupIcon-root {
          color: var(--bup-icon-color);
          font-size: 16px;
          font-weight: bold;
          margin-right: 8px;
          vertical-align: middle;
        }
        div.MuiChip-root {
          @include disabled;
          background: var(--bup-button-secondary);
          color: white;
          font-size: 12px;
          height: 16px;
          margin-left: 8px;

          &.Bup-exclusive {
            background: var(--bup-button-tertiary);
          }
        }
        @media only screen and (max-width: $sm) {
          display: grid;

          div.MuiChip-root {
            font-size: 10px;
            margin-left: 6px;

            span.MuiChip-label {
              padding: 0 6px;
            }
          }
        }
      }
      @media only screen and (max-width: $md) {
        @include responsive;
        border-top: 1px solid var(--bup-common-border);
      }
      @media only screen and (max-width: $sm) {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
      }
      @media only screen and (max-width: $xs) {
        border-radius: 0 !important;
        border: none;
      }
    }
  }
  svg.BupImage-root {
    background: transparent;
    border-radius: 0;
    border: none;
    height: 100%;
    margin-bottom: -5px;
    transform: scaleX(-1);
    width: 80px;

    * {
      fill: var(--bup-paper-background);
    }
    @media only screen and (max-width: $lg) {
      display: none;
    }
  }
  @media only screen and (max-width: $xl) {
    height: 760px;
  }
  @media only screen and (max-width: $lg) {
    grid-template-columns: 1fr 1fr;
    height: fit-content;
  }
  @media only screen and (max-width: $md) {
    grid-template-columns: 1fr;

    div.MuiStack-root:last-child {
      border-bottom-left-radius: var(--bup-border-radius);
      border-top-right-radius: 0;
    }
  }
  @media only screen and (max-width: $xs) {
    background-color: var(--bup-paper-background);
    border: none;
    border-bottom: 1px solid var(--bup-common-border);
    border-radius: 0 !important;
    border-top: 1px solid var(--bup-common-border);
    box-shadow: none;
    padding: 20px $padding-sm 32px !important;

    div.MuiStack-root {
      padding: 0 !important;

      span.MuiTypography-p {
        font-size: 14.5px !important;
      }
      &:last-child {
        margin-top: 48px;
      }
    }
  }
}
