section.BupBenefits-root {
  div.BupBenefits {
    &-medismart {
      h6.BupMedismart-title {
        font-size: 14px !important;
        text-decoration: underline;
        margin-top: 32px;
        margin-bottom: 15px !important;

        @media only screen and (max-width: $sm) {
          text-align: left;
        }
      }
      ul.BupMedismart-services {
        background: #f6faff;
        border-radius: var(--bup-border-radius);
        border: 1px solid $border;
        padding: 16px 6px;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        margin-top: 16px;

        li {
          @include font(15px);
          border-radius: 20px;
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 2.5px 10px;

          i {
            @include font(18px, 500);
            color: $primary;
          }
          &:hover {
            background: darken(#f6faff, 3%);
          }
        }
      }
      ul.BupMedismart-steps {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
        gap: 5px;

        li {
          @include font(15px);
          align-items: center;
          background: $bg-1;
          padding: 2.5px 15px;
          display: flex;
          justify-content: center;
          border: 1px solid $border;
          border-radius: 20px;
          gap: 16px;

          i {
            @include font(18px, bold);
            color: $primary;
            width: 18px;
            text-align: left;
          }
          span {
            width: calc(100% - 18px);
          }
        }
      }
      div.BupMedismart-obs {
        display: block;
        text-align: left;
        width: 100%;

        & + button.MuiButton-root {
          margin-top: 32px;
        }
      }
    }
    &-examedi {
      div.BupExamedi-services {
        background: #f6faff;
        border-radius: var(--bup-border-radius);
        border: 1px solid var(--bup-common-border);
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        flex-wrap: wrap;
        margin: 16px 0;

        img {
          @include square;
          border-radius: var(--bup-border-radius);
          border: none;
          height: 160px;
          margin: 0 auto;
          transform: scale(0.7);
          width: 160px;

          &:nth-child(1) {
            object-position: -120px 0px;
            margin-top: -15px;
          }
          &:nth-child(2) {
            object-position: -265px 0px;
            margin-top: -15px;
          }
          &:nth-child(3) {
            object-position: -395px 0px;
            margin-top: -15px;
          }
          &:nth-child(4) {
            object-position: -515px 0px;
            margin-top: -30px;
          }
          &:nth-child(5) {
            object-position: -645px 0px;
            margin-top: -30px;
          }
          &:nth-child(6) {
            object-position: -780px 0px;
            margin-top: -30px;
          }
        }
      }
      img.BupExamedi-mock {
        padding: 16px 32px;
        margin-top: 16px;
        width: 100%;
        height: auto;

        @media only screen and (max-width: $sm) {
          padding: 15px 0;
        }
      }
      ul.BupExamedi-steps {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        text-align: left;

        img {
          margin-bottom: 8px;
          height: auto;
          width: 100%;
        }
        b {
          font-size: 15px;
        }
        p {
          color: var(--bup-text-secondary);
          font-size: 13.5px;
          margin: 8px 0 0;
          line-height: 1.33;
          text-align: left;
        }
        li {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
      h4.MuiTypography-h4 {
        color: var(--bup-text-tertiary);
        margin-bottom: 8px;
      }
      span.MuiTypography-p {
        color: var(--bup-text-secondary);
        font-size: 14px;
      }
      button {
        margin-top: 16px;
      }
    }
    &-buho {
      div.BupBuho-steps {
        background: #f6faff;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        border-radius: var(--bup-border-radius);
        border: 1px solid var(--bup-common-border);
        margin: 16px 0;
        padding: 32px;
        gap: 16px;

        div {
          @include col-center;

          img {
            height: 60px;
            min-height: 60px;
            width: 60px;
            margin-bottom: 16px;
          }
          span.MuiTypography-p {
            font-weight: 500;
            font-size: 12px !important;
            max-width: 150px;
            margin: 0 auto;
            text-align: center !important;
          }
        }
        @media only screen and (max-width: $xl) {
          grid-template-columns: repeat(2, 1fr);
          padding: 32px 16px;
        }
      }
      div.BupBuho-services {
        background: #f6faff;
        border-radius: var(--bup-border-radius);
        border: 1px solid var(--bup-common-border);
        margin-bottom: 16px;
        margin-top: 16px;
        padding: 16px 32px;

        img {
          height: auto;
          width: 100%;
        }

        @media only screen and (max-width: $sm) {
          display: none;
        }
      }
      h5.MuiTypography-h5 {
        margin: 32px 0 8px;
      }

      img {
        background-color: transparent;
        border: none;
        box-shadow: none;
      }
    }
  }
}
