section.BupLogin-view {
  div.BupLogin-create {
    @include col-center;
    background: rgba($border, 0.5);
    border: 1px solid $border;
    border-radius: 24px;
    height: calc(100vh - 60px - 128px);
    padding-bottom: 16px;

    h3.MuiTypography-h3 {
      font-size: 32px;
      font-weight: bold;
    }
    span.MuiTypography-p {
      font-size: 20px;
      margin-bottom: 16px;

      b {
        color: $tertiary;
      }
    }
    svg.Bup-sync {
      height: 60px;
      width: 60px;

      * {
        animation-name: refill;
        animation-duration: 2.4s;
        animation-iteration-count: infinite;
        //fill: black;
      }
    }
    @media only screen and (max-width: $lg) {
      height: calc(100vh - 60px - 88px);
      text-align: center;

      h3.MuiTypography-h3 {
        font-size: 24px;
      }
      span.MuiTypography-p {
        font-size: 18px;
      }
      svg.Bup-sync {
        height: 48px;
        width: 48px;
      }
    }
  }
  div.BupLogin-partners {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px 24px;

    img.BupImage-root {
      aspect-ratio: auto;
      background-color: transparent;
      border: none;
      border-radius: 0;
      height: 20px;
      width: auto;
    }
  }
}
