div.BupMeter {
  &-progressBar {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    width: 100%;

    & > div {
      background-color: var(--bup-input-background-hover);
      border: 1px solid var(--bup-divider);
      border-right: none;
      border-left: none;
      height: 8px;

      &.BupMeter-completed {
        background: var(--bup-link-color);
        border-color: var(--bup-link-color);

        &:has(+ div.BupMeter-pending) {
          border-top-right-radius: var(--bup-border-radius);
          border-bottom-right-radius: var(--bup-border-radius);
        }
      }
      &:first-child {
        border-top-left-radius: var(--bup-border-radius);
        border-bottom-left-radius: var(--bup-border-radius);
      }
      &:last-child {
        border-top-right-radius: var(--bup-border-radius);
        border-bottom-right-radius: var(--bup-border-radius);
        border-right: 1px solid var(--bup-divider);
      }
    }
    & > i {
      align-items: center;
      aspect-ratio: 1;
      border-radius: 50%;
      border: 1px solid var(--bup-divider);
      color: var(--bup-button-primary);
      display: flex;
      font-size: 16px;
      font-weight: bold;
      justify-content: center;
      margin-left: 1px;
      text-align: center;
    }
    & + span.MuiTypography-span {
      font-size: 12.5px;
      white-space: nowrap;
    }
    @media only screen and (max-width: $lg) {
      width: calc($xs / 2);
    }
    @media only screen and (max-width: $md) {
      margin: 8px 0 12px;
      width: 90%;
    }
  }
  &-remaining {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    margin-top: 16px;
    margin-bottom: 16px;

    & > div.BupStack-root {
      border: 1px solid var(--bup-paper-border);
      border-radius: var(--bup-border-radius);
      background: var(--bup-navbar-background);
      padding: 16px;

      button.BupButton-root,
      a {
        @include font(12px, bold);
        border: none;
        border-radius: 16px;
        height: 32px;
        padding: 6px 16px;
        margin-top: 12px;
      }
      a {
        @include paper-box;
        @include row-center;
        border-radius: 16px;
        background: var(--bup-link-color);
        color: white;
        width: fit-content;

        &:hover {
          background: var(--bup-link-color-hover);
        }
      }
      h4.MuiTypography-h4 {
        margin: 0;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        gap: 16px;

        i {
          color: var(--bup-success-color);
          font-size: 18px;
        }
      }
      span.MuiTypography-p {
        @include clamp(3);
        color: var(--bup-text-secondary);
        font-size: 13.5px;
      }
      div.BupStack-root {
        margin-top: auto;
      }
      &:nth-child(n + 7) {
        display: none !important;
      }
    }
    @media only screen and (max-width: $lg) {
      & > * {
        &:nth-child(n + 4) {
          display: none !important;
        }
      }
    }
    @media only screen and (max-width: $md) {
      grid-template-columns: repeat(2, 1fr);

      & > * {
        &:nth-child(n + 3) {
          display: none !important;
        }
      }
    }
    @media only screen and (max-width: $sm) {
      grid-template-columns: 1fr;

      & > * {
        &:nth-child(n + 2) {
          display: none !important;
        }
      }
    }
  }
}
