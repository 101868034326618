div.BupDialog-root {
  div.MuiPaper-root {
    border-radius: var(--bup-border-radius);
    max-width: 100%;
    width: $xs;

    h2.MuiTypography-h6 {
      border-bottom: 0.5px solid var(--bup-paper-border);
      background-color: $bg-1;
      font-size: 20px;
      padding: 16px 16px 12px;
      margin-bottom: 16px;
    }
    div.MuiDialogActions-root {
      padding: 8px 16px 16px;

      button.BupButton-root {
        box-shadow: none;
        border-radius: 18px;

        &.MuiButton-containedInherit {
          background: transparent;

          &:hover {
            background: $bg-2;
          }
        }
      }
    }
    div.MuiDialogContent-root {
      padding: 0 16px 16px;

      p.MuiTypography-body1 {
        font-size: 15px;
      }
    }
  }
}
