nav.BupBreadcrumbs-root {
  padding-left: 1.5px;

  ol {
    gap: 0;

    li {
      @include row-center;
      font-size: 12px !important;
      opacity: 0.85;
      height: fit-content;

      a,
      button {
        color: var(--bup-text-tertiary) !important;
        font-size: inherit;
        font-weight: 400;
        line-height: 1.33 !important;
        letter-spacing: 0.6px !important;
        height: 16px !important;
        padding: 0;
        min-width: 0;
        width: fit-content;

        &:hover {
          color: var(--bup-link-color) !important;
          text-decoration: underline;
        }
      }
      &:not(:has(button)),
      a {
        font-weight: 400 !important;
        pointer-events: none;
        user-select: none;
      }
      &.MuiBreadcrumbs-separator {
        margin: 0 6px;
      }
      &:has(button):hover {
        opacity: 1;
      }
    }
  }
  @media only screen and (max-width: $xs) {
    display: none;
    padding: 0 $padding-sm;
  }
}
