div.BupLazy-load {
  margin-top: 40px !important;

  button.BupButton-root {
    background: var(-bup-common-backdrop) !important;
    border: 1px solid var(--bup-button-border) !important;
    color: var(--bup-button-color) !important;
    border-radius: 18px;
    margin-top: 24px;
    padding: 4px 24px;
    height: 36px;

    &:hover {
      border-color: var(--bup-button-border-hover) !important;
    }
  }
  span.MuiLinearProgress {
    &-colorInherit {
      background-color: var(--bup-input-border);
    }
    &-barColorInherit {
      background-color: var(--bup-link-color);
    }
  }
  span.MuiTypography-root {
    color: var(--bup-text-secondary);
    font-size: 13px;
  }
  @media only screen and (max-width: $xs) {
    padding-bottom: 24px;
  }
}
span.BupLazy-progress {
  border-radius: var(--bup-rounded);
  width: 66.6%;

  @media only screen and (max-width: $sm) {
    width: 100%;
  }
  @media only screen and (max-width: $xs) {
    width: calc(100% - 16px);
  }
}
