section.BupCalendar-root {
  border-radius: var(--bup-border-radius);
}
div.MuiDateCalendar-root {
  background: white;
  border: 1.5px solid var(--bup-paper-border);
  border-radius: var(--bup-rounded);

  * {
    color: black;
  }
  span {
    button {
      border-radius: 50%;
      border: 1px solid transparent;
      height: 36px;
      width: 36px;

      &.Mui-selected {
        background: var(--bup-link-color) !important;
        color: white;
      }
      &.MuiPickersDay-root {
        pointer-events: none;
      }
      &.MuiPickersDay-root:hover:not(.Mui-selected) {
        background: rgba($primary, 0.16);
        border-color: rgba($primary, 0.32);
        color: $primary;
      }
      &.MuiPickersDay-today {
        border-color: rgba($primary, 0.32);
      }
    }
    &:not(.Bup-disabled) button {
      &.MuiPickersDay-root {
        background: rgba(black, 0.06);
      }
    }
    &.Bup-selected button {
      &.MuiPickersDay-root,
      &.Mui-selected {
        background: var(--bup-link-color) !important;
        color: white !important;
      }
    }
  }
  &:hover {
    background: white;
    border-color: var(--bup-paper-border);
  }
  &:has(.Mui-disabled) {
    //filter: grayscale(0.5);
    pointer-events: none;

    * {
      background: transparent !important;
      color: black !important;
    }
  }
}
table.BupCalendar-schedule {
  background-color: var(--bup-navbar-background);
  border-radius: var(--bup-border-radius);
  color: var(--bup-text-primary);
  font-size: 13.5px;
  text-align: left;
  overflow: hidden;
  width: 100%;

  thead {
    tr {
      th {
        border: none;
        color: inherit;
        font-size: 12px;
        padding: 8px 12px 5px;
        text-transform: uppercase;
      }
    }
  }
  tbody {
    tr {
      border-top: 0.5px solid transparent;

      td {
        background-color: var(--bup-paper-background);
        border: 1px solid var(--bup-common-border);
        border-radius: var(--bup-rounded);
        color: inherit;
        height: 156px;
        padding: 8px;

        span.BupCalendar-date {
          @include row-center;
          border-radius: 16px;
          font-size: 11px;
          height: 16px;
          user-select: none;
          width: 16px;
        }
        div.MuiChip-root {
          font-size: 11px;
          margin-left: auto;
          height: 18px;
          background-color: rgba($text, 0.04);
          color: var(--bup-text-secondary);
        }
        & > div.head {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        & > div.body {
          margin-top: 8px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 4px;

          p {
            @include clamp(1);
            font-size: 12px;

            b {
              font-size: 11px;
            }
          }
        }
        &.Bup-current {
          span.BupCalendar-date {
            background-color: var(--bup-link-color);
            color: white;
          }
        }
        &.Bup-cursor {
          cursor: pointer;

          &:hover {
            border-color: var(--bup-tab-border);
            color: var(--bup-tab-color);
          }
        }
        &.Bup-disabled {
          background-color: rgba(white, 0.66);
          color: rgba(black, 0.32);
          cursor: not-allowed;

          & > div.body {
            filter: grayscale(0.5);
            opacity: 0.33;
          }
        }
        &.Bup-grayscale {
          opacity: 0.8;
        }
        &.Bup-minified {
          @include square;
          height: auto;
          padding: 2px;
          overflow: hidden;

          div.MuiChip-root {
            display: none;
          }
          & > div.body {
            grid-template-columns: repeat(1, 1fr);
            margin-top: 4px;
            gap: 1.5px;

            div.Bup-event {
              border-radius: 4px !important;
              background-color: var(--bup-tab-border);
              height: 4px !important;
              width: 100%;
              font-size: 0px;

              &.Bup-active {
                background-color: var(--bup-success-border);
              }
              &.Bup-disabled {
                background-color: var(--bup-common-empty);
              }
            }
          }
        }
        &.Bup-grayscale div.BupCard-thumb {
          opacity: 0.5;
        }
        div.Bup-loading {
          @include col-center;
          pointer-events: none;
          height: 100%;
          padding-bottom: 20px;
        }
      }
    }
  }
  tr {
    display: grid;
    grid-gap: 1.5px;
    grid-template-columns: repeat(7, 1fr);
    min-width: 100%;
  }
}
div.BupCalendar-content {
  h6.MuiTypography-h6 {
    font-size: 13.5px;
    text-transform: uppercase;
  }
  ul.BupCalendar-list {
    display: flex;
    flex-direction: column;
    gap: 1.5px;
    margin: 12px 0 6px;

    li {
      align-items: center;
      background-color: var(--bup-common-item);
      border-radius: var(--bup-rounded);
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      font-size: 14px;
      height: 48px;
      padding: 8px 16px;

      &:hover {
        background-color: var(--bup-common-empty);
      }
      span {
        display: inline-block;
        margin-left: 8px;
        @include clamp(1);
      }
      i.BupIcon-root {
        color: var(--bup-icon-color);
        margin-left: 12px;
        font-size: 16px;
      }
      div:last-child {
        margin-left: auto;

        span {
          @include row-center;
          background-color: var(--bup-icon-background-hover);
          border-radius: 20px;
          border: 1px solid var(--bup-paper-border);
          color: var(--bup-icon-color-hover);
          font-size: 12px;
          height: 20px;
          width: 36px;

          &.Bup-booking {
            padding: 0 5px;
            width: fit-content;
          }
        }
      }
    }
  }
}
