div.BupInfo-root {
  &:empty,
  &:has(div.MuiGrid-container:empty) {
    display: none;
  }
  h4.MuiTypography-h4 {
    font-size: 16px;
    font-weight: 500;
  }
  ul.BupInfo-list {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &:last-child {
      margin-bottom: 0;
    }
    a,
    li {
      @include font(15px);
      border-radius: var(--bup-rounded);
      border: 1px solid transparent;
      display: grid;
      grid-template-columns: 24px 1fr auto auto;
      grid-gap: 16px;
      padding: 4px;
      margin-left: -4px;
      width: calc(100% + 8px);
    }
    a {
      @include row-center;
      @include font(14px);

      color: var(--bup-tab-color);
      border-radius: 22px;
      border: 1px solid transparent;
      gap: 16px;
      justify-content: flex-start;
      width: 80%;

      &:hover {
        background: var(--bup-tab-background);
        border-color: var(--bup-tab-border);
      }
    }
    button {
      padding: 6px 16px;

      &.BupButton-icon {
        background: var(--bup-icon-background);
        border: 1px solid var(--bup-icon-border);
        color: var(--bup-icon-color);
        padding: 0;
        height: 32px;
        width: 32px;

        i.BupIcon-root {
          font-size: 18px;
        }
        &:hover {
          background: var(--bup-icon-background-hover);
          border-color: var(--bup-icon-border-hover);
        }
      }
    }
    li {
      &:not(.Bup-edit):hover {
        background: var(--bup-common-hover) !important;
        border-color: var(--bup-divider);
      }
      &.BupLink-root:hover {
        border-color: var(--bup-tab-border);
        color: var(--bup-tab-color);
        cursor: pointer;

        small,
        button.BupButton-icon:not(.Bup-disabled),
        i.private {
          color: var(--bup-text-primary);
        }
      }
      &.Bup-edit {
        background-color: var(--bup-input-background) !important;
        border-color: var(--bup-input-border-hover);
        grid-template-columns: 24px 1fr;
        padding-bottom: 8px;
        padding-top: 8px;

        i.BupIcon-root {
          margin-top: 10px;
        }

        div {
          width: 100%;
        }
      }
    }
    i {
      &:first-child {
        font-size: 24px;
        margin-top: 2px;
      }
      &.private {
        @include col-center;
        color: var(--bup-text-tertiary);
        font-size: 18px;
        opacity: 0.66;
        height: 32px;
      }
    }
    input,
    select,
    textarea {
      border-radius: 22px;
      background: var(--bup-paper-background);
      border: 1px solid var(--bup-input-border);
      color: var(--bup-input-color);
      height: 44px;
      outline: none !important;
      padding: 4px 16px;
      width: 100%;

      &.Bup-error {
        border-color: $danger;
        border-width: 2px;
      }
      &:hover,
      &:focus {
        border: 1px solid var(--bup-input-border-hover);
      }
    }
    textarea {
      border-radius: var(--bup-border-radius);
      padding: 12px 16px;
      height: 100px;
      resize: none;
    }
    p {
      margin-bottom: 0 !important;
      padding: 4px 0;
      white-space: break-spaces;
    }
    small {
      display: block;
      opacity: 0.66;
    }
  }
}
