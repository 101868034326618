form.BupSurvey-form {
  padding: 16px;
  min-width: 0;

  div.BupSurvey-question {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    span.MuiTypography-p {
      font-size: 15px !important;
    }
    & > div {
      align-items: center;
      display: flex;
      gap: 12px;

      label {
        height: 24px;
        margin: 0 !important;
      }
      span.BupSurvey-label {
        font-size: 13px;
        line-height: 24px;
        opacity: 0.5;
      }
    }
  }
  h3 {
    font-size: 20px;

    @media only screen and (max-width: $xs) {
      font-size: 18px !important;
    }
  }
  p {
    font-size: 15px !important;
    margin: 0 !important;
    max-width: 100%;
    opacity: 1;
  }
  span.MuiRating-root label:not(:hover) {
    span.MuiRating-iconEmpty svg {
      color: var(--bup-input-color);
    }
  }
  span.MuiTypography-p {
    color: var(--bup-text-secondary);
  }
  button {
    max-height: 200px;
    width: fit-content !important;
  }
}
