form.BupConsole-form {
  background-color: var(--bup-paper-background);
  border-radius: var(--bup-border-radius);
  border: 1px solid var(--bup-paper-border);
  margin: 8px auto 0;
  min-width: 500px;
  padding: 24px;
  text-align: center;

  .BupLink {
    &-href,
    &-nav {
      font-size: 14px;

      &:hover {
        color: var(--bup-button-tertiary);
      }
    }
    &-nav {
      color: var(--bup-link-color);
      font-weight: 400;
      height: 20px;
    }
  }
  div.BupStack-root {
    margin-top: 24px;
    text-align: left;
    display: flex;
    justify-content: flex-end;

    &.BupForm-submit {
      flex-direction: row;
      gap: 8px;
      margin-top: 32px;
    }
  }
  div.BupForm-group {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    &.gr-2 {
      grid-template-columns: repeat(2, 1fr);

      @media only screen and (max-width: $sm) {
        grid-template-columns: 1fr;
        gap: 0;

        label.BupLabel-empty {
          display: none;
        }
        div.BupForm-field:last-child:not(:has(label.BupLabel-empty)) {
          margin-top: 16px;
        }
      }
      &:has(button) {
        margin-top: 32px;
      }
    }
    &.gr-3 {
      grid-template-columns: repeat(3, 1fr);

      @media only screen and (max-width: $sm) {
        gap: 4px;
      }
      @media only screen and (max-width: $x1) {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }
  }
  & {
    h3.MuiTypography-h3 {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
    }
    label.BupForm-label,
    span.MuiTypography-root {
      font-size: 14px;

      .BupLink-root {
        color: var(--bup-link-color);
        font-weight: 500;
        padding: 6px 8px;
      }
    }
    span.MuiTypography-root {
      border-top: 1px solid var(--bup-divider);
      padding-top: 32px;
      margin-top: 32px;

      @media only screen and (max-width: $xs) {
        padding-top: 24px;
        margin-top: 24px;
      }
    }
    small.BupLogin-caption {
      display: block;
      font-size: 12px;
      max-width: 380px;

      a.BupLink-href {
        font-size: inherit;
        font-weight: bold;
        line-height: 1.5;

        &:not(:hover) {
          color: lighten($dark, 16%);
        }
      }
      @media only screen and (max-width: $xs) {
        br {
          display: none;
        }
      }
    }
    p.MuiTypography-body1 {
      font-size: 14px;
    }
    button.MuiButton-fullWidth {
      @include background($dark);
      color: white;
      height: 40px;

      &:hover {
        @include background(lighten($dark, 4%));
        color: white;
      }
    }
  }
  &.BupForm-info {
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: left;
    width: 100%;

    h1.MuiTypography-h1 {
      font-size: 24px;
    }
    @media only screen and (max-width: $lg) {
      max-width: 100%;
      width: 100%;
    }
    @media only screen and (max-width: $md) {
      padding: 0 4px;
    }
  }
  @media only screen and (max-width: $xl) {
    min-width: 100%;
    width: 100%;
  }
  @media only screen and (max-width: $lg) {
    max-width: $sm;
    min-width: 0;
  }
  @media only screen and (max-width: $md) {
    max-width: $xs;
    margin: 0 auto;
  }
  @media only screen and (max-width: $sm) {
    padding: 32px 16px;
  }
}
