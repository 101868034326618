div.BupLink {
  &-root {
    cursor: pointer;
  }
  &-item {
    @include responsive;
    @include paper-box;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 0;
    gap: 16px;

    span.MuiTypography {
      &-p {
        color: var(--bup-text-primary);
        display: block;
        font-size: 16px;
        font-weight: 500;
      }
      &-span {
        display: block;
        font-size: 13px;
        color: var(--bup-text-secondary);
      }
    }
    div.MuiChip-root {
      background: var(--bup-link-primary);
      border-radius: 10px;
      height: 20px;
      margin-left: 16px;
      min-width: 20px;
      padding: 0 4px;
      width: fit-content;

      span.MuiChip-label {
        @include center;
        color: white;
        font-size: 11px;
        margin: 0;
        padding: 0;
      }
    }
    i.BupIcon-root {
      font-size: 20px;

      &:last-child {
        margin-left: auto;
      }
    }
    span.MuiBadge-root {
      margin-left: 12px;
      margin-bottom: 4px;

      span.MuiBadge-badge {
        height: 18px;
        font-size: 10px;
        font-weight: bold;
      }
    }
    &:hover {
      background: var(--bup-common-hover);
      border-color: var(--bup-input-border);
    }
    @media only screen and (max-width: $xs) {
      border-radius: var(--bup-border-radius);
      padding: 16px;
    }
  }
}
