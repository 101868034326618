div.BupPremium {
  &-root {
    border-radius: 12px !important;

    & > div.MuiPaper-root {
      background-color: var(--bup-common-background);
      border-radius: 10px !important;

      div.BupPremium-container {
        @include responsive;
        border-radius: inherit !important;
        gap: 16px;
        padding: 20px 0 16px;
        overflow: hidden;
      }
      button {
        border-radius: var(--bup-rounded) !important;
        color: var(--bup-text-tertiary);

        &.MuiButton-containedPrimary {
          background-color: black;
          color: white;
        }
      }
      &:not(:has(svg)) {
        flex-direction: column;
      }
      svg {
        height: 40px;
        margin-top: 1px;
        min-width: 32px;
      }
    }
    & {
      h5.BupPremium-title {
        color: var(--bup-text-primary);
        font-size: 18.5px;
        font-weight: 400;

        @media only screen and (max-width: $xs) {
          font-size: 16.5px;
        }
      }
      span.BupPremium-text {
        color: var(--bup-text-secondary);
        font-size: 14px;
        opacity: 0.8;
      }
    }
    @media only screen and (max-width: $xs) {
      border-radius: 0 !important;
      width: 100%;

      & > div.MuiPaper-root {
        border-radius: 0 !important;

        div.BupPremium-container {
          background-color: rgba($premium, 0.12);
        }
      }
    }
  }
  &-avatar {
    div.BupAvatar {
      &-root {
        height: 36px;
        width: 36px;
      }
      &-group {
        align-items: center;
        display: flex;
        gap: 8px;
        margin-left: -2px;
        overflow: hidden;

        div.BupAvatar-root {
          height: 24px;
          width: 24px;

          svg.MuiAvatar-fallback {
            height: 18px;
          }
        }
      }
    }
    button {
      margin-top: 0;
    }
  }
  &-badge {
    background: var(--bup-premium-button-background);
    border-radius: var(--bup-border-radius);
    color: var(--bup-premium-button-color);
    font-size: 10px;
    font-weight: bold;
    margin-top: 3px;
    padding: 1.5px 6px;
    user-select: none;
  }
  &-border {
    background:
      linear-gradient(90deg, #ddb655 4%, #f0bb66 0) top/100% 34% no-repeat,
      linear-gradient(90deg, #f6cb86 50%, #f0bb66 0) top/100% 82% no-repeat,
      linear-gradient(90deg, #f6cb86 10%, #c8981d 0) top/100% 100%;
    border-radius: var(--bup-rounded);
    padding: 3px;
  }
  &-regular {
    background:
      linear-gradient(90deg, $tertiary 4%, rgb(66, 133, 244) 0) top/100% 34% no-repeat,
      linear-gradient(90deg, $secondary 50%, rgb(66, 133, 244) 0) top/100% 82% no-repeat,
      linear-gradient(90deg, $secondary 10%, $dark 0) top/100% 100%;
    border-radius: var(--bup-rounded);
    padding: 3px;
  }
  &-pretitle {
    align-items: center;
    display: flex;
    gap: 6px;

    span {
      color: var(--bup-text-tertiary);
      font-size: 12px;
      text-transform: uppercase;
      user-select: none;
    }
  }
}
