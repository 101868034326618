div.BupDocument-map {
	@include paper-box;
	background: var(--bup-paper-background);
	border-radius: var(--bup-border-radius);

	iframe.BupDocument-googleMap {
		@include banner;
		background: var(--bup-skeleton);
		border: 1px solid var(--bup-paper-border);
		border-radius: var(--bup-border-radius);
		margin-bottom: -4.5px !important;

		@media only screen and (max-width: $xs) {
			@include video;
			border-radius: 0 !important;
			border: none;
		}
	}
	a.BupLink-href {
		@include row-center;
		border-radius: 18px;
		border: 1px solid rgba($primary, 0.66);
		color: var(--bup-link-color);
		font-size: 13px;
		font-weight: bold;
		gap: 12px;
		height: 36px;
		justify-content: center;
		margin: 8px;
		padding: 5px 15px;
		width: calc(100% - 16px);

		&:hover {
			background: rgba($primary, 0.04);
		}
		i.BupIcon-root {
			display: none;
			font-size: 18px;
		}
		@media only screen and (max-width: $xs) {
			display: flex;
		}
	}
}
