.BupNotification {
  &-root {
    div.BupItem-header {
      * {
        margin: 0 !important;
      }
      div.BupPeople-tabs {
        padding-left: 0;
        padding-right: 0;
      }
    }
    @media only screen and (max-width: $xs) {
      padding: 0 !important;

      div.BupPaper-root {
        min-height: calc(100vh - 2 * #{$navbar-y} * 0.9);
      }
      div.BupEmpty-root {
        margin: 0 !important;
        width: 100% !important;
      }
    }
  }
  &-list {
    li {
      cursor: pointer;
      display: grid;
      gap: 16px;
      min-height: 40px;
      padding: 8px 20px;
      grid-template-columns: 56px auto 16px;
      margin-left: -20px;
      width: calc(100% + 40px);

      &:hover {
        background-color: var(--bup-common-hover);
      }
      div.BupAvatar-root {
        margin-top: 3px;
        height: 56px;
        width: 56px;

        svg.MuiAvatar-fallback {
          height: 24px;
        }
        &.MuiAvatar-rounded i.BupIcon-root {
          font-size: 20px !important;
        }
      }
      span.MuiTypography-span {
        font-size: 14px;

        a {
          display: block;
          color: var(--bup-link-color);
          font-size: 13.5px;
          font-weight: 500;
          margin-top: 8px;
        }
      }
      i.BupIcon-root {
        color: var(--bup-link-color);
        font-size: 14px;
      }
    }
  }
}
