nav.BupTopbar-root {
	align-items: center;
	background: var(--bup-navbar-background);
	border-bottom: 1px solid var(--bup-common-border);
	color: var(--bup-navbar-color);
	display: flex;
	gap: 16px;
	height: $navbar-y;
	justify-content: space-between;
	padding: 0px 16px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99;

	& > div.MuiBox-root {
		align-items: center;
		display: flex;
		gap: 16px;

		&:first-child {
			gap: 16px;
			position: relative;

			button.BupTopbar-search {
				align-items: center;
				border-radius: 20px;
				border: 1px solid var(--bup-input-border);
				background: var(--bup-input-background);
				display: flex;
				font-weight: normal;
				height: 40px;
				justify-content: space-between;
				outline: none;
				padding: 6px 16px;
				width: 256px;

				i.BupIcon-root {
					@include col-center;
					background: var(--bup-common-inset);
					border: 1px solid var(--bup-icon-border);
					border-radius: 14px;
					color: var(--bup-icon-color);
					font-size: 16px;
					height: 28px;
					width: 28px;
				}
				&:hover {
					background: var(--bup-input-background-hover);
					border-color: var(--bup-input-border-hover);
				}
			}
			svg.BupLogo-root {
				height: 44px;
				min-width: 44px;

				@media only screen and (max-width: $sm) {
					height: 40px;
					min-width: 40px;
					width: 40px;
				}
			}
			@media only screen and (max-width: $sm) {
				gap: 8px;
			}
		}
		&:last-child {
			gap: 8px;

			div.BupTopbar-menu {
				position: relative;

				div.BupAvatar-root,
				span.BupAvatar-root {
					cursor: pointer;
					pointer-events: auto;
				}
				ul.more {
					top: 8px;
				}
			}
			ul.BupTopbar-nav {
				@include center;
				gap: 4px;

				& > li {
					@include center;
					background: var(--bup-navbar-item-background);
					border: none;
					border-radius: 18px;
					color: inherit;
					cursor: pointer;
					gap: 4px;
					height: 36px;
					padding: 0 14px 0 3px;
					width: auto;

					i {
						@include row-center;
						border-radius: 50%;
						font-size: 20px;
						height: 30px;
						width: 30px;
					}
					span {
						@include font(13px);
						line-height: 13px;
						opacity: 0.66;
						padding: 0;
					}
					&:not(.Bup-active):hover {
						background: var(--bup-navbar-item-background-hover);
						border-color: var(--bup-navbar-item-background-hover);
					}
					&.Bup-active {
						background: var(--bup-navbar-item-background-active);
						color: white;

						span {
							opacity: 1;
						}
						i.BupIcon-root {
							background-color: rgba(white, 0.16);
							color: inherit;
						}
					}
					@media only screen and (max-width: $lg) {
						border-radius: 20px;
						height: 40px;
						padding: 8px;
						width: 40px;

						span {
							display: none;
						}
					}
				}
				@media only screen and (max-width: $lg) {
					gap: 12px;
					margin-right: 8px;
				}
				@media only screen and (max-width: $sm) {
					display: none;
				}
			}
			@media only screen and (max-width: $xs) {
				& > div.MuiBox-root {
					height: 36px;

					div.BupAvatar-root {
						height: 40px;
						min-width: 40px;
						width: 40px;
					}
				}
			}
		}
		button.BupTopbar {
			&-install {
				@include blinker;
				border: 1px solid darken($primary, 16%);
				background-color: rgba($primary, 0.04);
				color: $primary;
				z-index: 1;

				@media only screen and (max-width: $sm) {
					@include square;
					@include row-center;

					animation: none;
					background-color: $primary;
					border-radius: 30px;
					bottom: 68px;
					box-shadow: 2px 2px 2px rgba(black, 0.32);
					color: white;
					height: 40px;
					min-width: 0;
					padding: 0;
					position: fixed;
					right: calc(50vw - 160px);
					width: 320px;
					z-index: 96;

					i.BupIcon-root {
						font-size: 20px;
					}
				}
				@media only screen and (max-width: $xs) {
					bottom: 64px;
					right: 8px;
					width: calc(100vw - 16px);
				}
			}
			&-rail {
				background: transparent !important;
				border: none;
				display: none;
				align-items: flex-end;
				justify-content: flex-end;
				padding: 0;

				i.BupIcon-root {
					font-size: 16px;
					background-color: var(--bup-navbar-background);
					border-radius: 4px;
					color: var(--bup-navbar-color);
					padding: 0;
					margin-right: -4px;
					margin-bottom: -2px;
					opacity: 0.8;
				}

				@media only screen and (max-width: $sm) {
					display: flex;
					margin-left: -48px;
				}
			}
		}
	}
	@media only screen and (max-width: $md) {
		@include responsive;
	}
	@media only screen and (max-width: $xs) {
		height: calc(#{$navbar-y} * 0.9);

		div.BupTopbar-backdrop {
			background: linear-gradient(180deg, transparent 5%, rgba(255, 255, 255, 0.9) 100%);
			z-index: 0;
			width: 100vw;
			left: 0;
			top: calc(100vh - #{$navbar-y} * 0.9 - 120px);
			height: 120px !important;
			position: absolute;
		}
	}
}
