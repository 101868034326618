div.BupDocument-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;

  div.MuiPaper-root {
    @include paper-box;
    align-items: center;
    background: var(--bup-paper-background);
    border-radius: var(--bup-border-radius);
    display: flex;
    gap: 16px;
    padding: 16px $padding-lg;

    h5.MuiTypography-h5 {
      color: var(--bup-text-primary);
      font-size: 16px;
      font-weight: 500;
    }
    span.MuiTypography-p {
      font-size: 13.5px;
      color: var(--bup-text-secondary);
    }
    i.BupIcon-oval {
      min-width: 54px;
    }
    a.BupLink-href {
      @include row-center;
      cursor: pointer;
      font-size: 13.5px;
      gap: 4px;

      i.BupIcon-root {
        font-size: inherit;
        margin-top: 1px;
      }
    }
    s {
      margin-left: 4px;
      text-decoration: line-through;
      font-size: 12px;
    }
    @media only screen and (max-width: $xs) {
      border-radius: 0 !important;
      padding: 12px;
    }
  }
  @media only screen and (max-width: $sm) {
    grid-template-columns: 1fr;
  }
  @media only screen and (max-width: $xs) {
    border-top: 0.75px solid var(--bup-common-border);
    grid-gap: 0;

    div.MuiPaper-root {
      border-top: none;
      border-width: 0.5px;
    }
  }
}
