div.BupPost {
	&-comments {
		border-top: 1px solid var(--bup-common-border);
		gap: 8px;
		padding: 16px 0 8px;

		div.BupComment {
			&-root {
				display: grid;
				grid-template-columns: 36px 1fr;
				gap: 16px;
				width: 100%;

				div.BupAvatar-root {
					height: 30px;
					width: 30px;
					margin-top: 2px;
					margin-left: 6px;

					@media only screen and (max-width: 900) {
						margin-left: 0px;
					}
				}
				small {
					font-size: 12px;
					margin-left: auto;
					padding-top: 1px;
				}
			}
			&-header {
				align-items: center;
				display: grid;
				grid-template-columns: 1fr 20px 20px;
				grid-gap: 12px;
				width: 100%;

				span.MuiTypography-span b {
					font-size: 14.5px;
					font-weight: 500;
				}
			}
			&-message {
				display: flex;
				flex-direction: column;
				font-size: 14.5px;

				p {
					font-size: 13.5px;
					line-height: 1.4;
				}
			}
			&-likes {
				@include col-center;
				justify-content: flex-start;
				font-size: 12px;

				button.BupButton-icon {
					border-radius: 50%;
					max-height: 18px;
					padding: 0;
					max-width: 18px;

					&.BupButton-liked {
						color: $danger;
					}
				}
			}
		}
	}
	&-answer {
		align-items: center;
		display: grid;
		grid-template-columns: 1fr auto;
		border-top: 1px solid var(--bup-common-border);
		padding: 12px 0 8px;
		gap: 8px;

		button.BupButton-root {
			border-radius: 16px;
			height: 32px;
			width: fit-content;

			&:disabled {
				background: transparent;
			}
		}
		textarea {
			background-color: transparent;
			border-radius: 22px;
			border: 1.5px solid var(--bup-icon-border) !important;
			height: 44px;
			outline: none;
			padding: 12px 16px;
			resize: none;
			transition: border-color 0.2s ease;

			&:hover {
				border-color: var(--bup-input-border) !important;
			}
			&:focus {
				border-color: var(--bup-input-border) !important;
			}
			&::placeholder {
				color: gray;
			}
		}
	}
}
