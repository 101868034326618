.no-ex,
.only-xl,
.only-lg,
.only-md,
.only-sm,
.only-xs,
.max-xl,
.max-lg,
.max-md,
.max-sm,
.max-xs {
  display: none !important;
}

@media only screen and (max-width: $xl) {
  .only-xl,
  .no-lg,
  .no-md,
  .no-sm,
  .no-xs,
  .max-xl {
    display: initial;
  }
  .no-xl,
  .only-lg,
  .only-md,
  .only-sm,
  .only-xs,
  .max-lg,
  .max-md,
  .max-sm,
  .max-xs,
  .min-ex {
    display: none !important;
  }
}
@media only screen and (max-width: $lg) {
  .only-lg,
  .no-ex,
  .no-xl,
  .no-md,
  .no-sm,
  .no-xs,
  .max-lg {
    display: initial;
  }
  .no-lg,
  .only-xl,
  .only-md,
  .only-sm,
  .only-xs,
  .max-md,
  .max-sm,
  .max-xs,
  .min-xl {
    display: none !important;
  }
}
@media only screen and (max-width: $md) {
  .only-md,
  .no-ex,
  .no-xl,
  .no-lg,
  .no-sm,
  .no-xs,
  .max-md,
  .max-lg {
    display: initial;
  }
  .no-md,
  .only-xl,
  .only-lg,
  .only-sm,
  .only-xs,
  .max-sm,
  .max-xs,
  .min-lg {
    display: none !important;
  }
}
@media only screen and (max-width: $sm) {
  .only-sm,
  .no-ex,
  .no-xl,
  .no-lg,
  .no-md,
  .no-xs,
  .max-sm,
  .max-md,
  .max-lg {
    display: initial;
  }
  .no-sm,
  .only-xl,
  .only-lg,
  .only-md,
  .only-xs,
  .max-xs,
  .min-md {
    display: none !important;
  }
}
@media only screen and (max-width: $xs) {
  .only-xs,
  .no-ex,
  .no-xl,
  .no-lg,
  .no-md,
  .no-sm,
  .max-xs,
  .max-sm,
  .max-md,
  .max-lg {
    display: initial;
  }
  .no-xs,
  .only-xl,
  .only-lg,
  .only-mg,
  .only-sm,
  .min-sm,
  .min-md {
    display: none !important;
  }
}
